import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  FiltersBar,
  InlineTooltip,
  LinkButton,
  Page,
  ProjectStatusFilter,
  SearchInput,
  SingleSelect,
} from '~/components';
import { useAuth } from '~/hooks';
import ProjectsListPage from '../projects/ProjectsListPage';

const Filters = ({ query: { params }, onFilter }) => {
  const auth = useAuth();
  const { url } = useRouteMatch();

  const handleChange = ({ target: { name, value } }) => {
    onFilter({ [name]: value });
  };

  return (
    <Page.Filters>
      <FiltersBar>
        <SearchInput
          value={params.q}
          placeholder="Search"
          materialPlaceholder="Project Name"
          materialAlwaysVisible
          onChange={handleChange}
        />

        <ProjectStatusFilter name="statuses" value={params.statuses} onChange={handleChange} />

        <SingleSelect
          name="recordStatusId"
          placeholder="All"
          materialPlaceholder="Project Archived"
          materialAlwaysVisible
          value={params.recordStatusId}
          showEmptyOption
          onChange={handleChange}>
          <option value="active">No</option>
          <option value="archived">Yes</option>
        </SingleSelect>
      </FiltersBar>

      <Page.Actions>
        <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
          Create a Project
          {!auth.projects.create && (
            <InlineTooltip message="Your security role prohibits you from creating projects." />
          )}
        </LinkButton>
      </Page.Actions>
    </Page.Filters>
  );
};

function ClientProjectsTab({ client }) {
  return (
    <ProjectsListPage
      mode="tab"
      clientId={client.id}
      client={client}
      renderFilters={(props) => <Filters {...props} />}
    />
  );
}

export default ClientProjectsTab;
