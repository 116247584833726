import { Button, Buttons, CancelButton, DeleteButton, Drawer, Field, Form, FormMessage } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import CompanyDeleteConfirmation from './CompanyDeleteConfirmation';

function CompanyForm({ onSaved, onDeleted, onClose }) {
  useDocumentTitle('Edit Company');

  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const [{ status, message, isSubmitting }, form] = useForm();

  const { companyId, companyTab } = useParams();
  const history = useHistory();

  const confirmation = useConfirmation();

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).companies(companyId).get();
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, companyId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    if (onClose) {
      onClose(companyTab);
    }
  }

  const company = query.data;
  const salesforceReadOnly = !!company?.salesforceCompany?.salesforceId;

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <CompanyDeleteConfirmation
        company={company}
        onClose={resolve}
        onDelete={() => {
          history.push(`/app/${workspace.key}/pipeline/companies`);
          if (onDeleted) onDeleted(company.id);
          resolve(true);
        }}
      />
    ));
  };

  if (!query.isReady) return null;
  if (!company) return <ErrorPage.NotFound />;

  const initialValues = mergeValues(
    {
      billingAddress: '',
      client: null,
      companySpendTier: null,
      companyType: null,
      currency: workspace.currency,
      description: '',
      facebookUrl: '',
      industry: null,
      isActive: true,
      linkedinUrl: '',
      name: '',
      numberOfEmployees: '',
      owner: null,
      ownershipClass: '',
      practice: null,
      phoneNumber: '',
      revenue: '',
      shippingAddress: '',
      sicCodeId: '',
      tickerSymbol: '',
      timeZoneId: '',
      twitterUrl: '',
      websiteUrl: '',
      yearFounded: '',
    },
    company,
  );

  return (
    <Drawer
      isOpen
      title="Edit Company"
      byline={company.name}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull({
              ..._.omit(values, ['client', 'companySpendTier', 'companyType', 'industry', 'owner', 'practice']),
              clientId: values.client?.id ?? null,
              companySpendTierId: values.companySpendTier?.id ?? null,
              companyTypeId: values.companyType?.id ?? null,
              industryId: values.industry?.id ?? null,
              ownerId: values.owner?.id ?? null,
              practiceId: values.practice?.id ?? null,
            });

            await api.www.workspaces(workspace.id).companies(company?.id).upsert(body);
            await onSaved();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape(
              salesforceReadOnly
                ? {
                    linkedinUrl: Yup.string()
                      .label('LinkedIn URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    practice: Yup.object().label('Practice').nullable(),
                    timeZoneId: Yup.string().label('Time Zone').max(255).nullable(),
                    twitterUrl: Yup.string()
                      .label('Twitter URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    facebookUrl: Yup.string()
                      .label('Facebook URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                  }
                : {
                    billingAddress: Yup.string().label('Billing Address').max(5000).nullable(),
                    companySpendTier: Yup.object().label('Company Spend Tier').nullable(),

                    companyType: Yup.object().label('Company Type').nullable(),
                    currency: Yup.string().label('Currency').required(),
                    description: Yup.string().label('Description').max(5000).nullable(),
                    facebookUrl: Yup.string()
                      .label('Facebook URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    industry: Yup.object().label('Sales Representative').nullable(),
                    linkedinUrl: Yup.string()
                      .label('LinkedIn URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    name: Yup.string().label('Name').max(255).required(),
                    numberOfEmployees: Yup.number().label('Year Founded').min(0).nullable(),
                    owner: Yup.object().label('Owner').nullable(),
                    ownershipClass: Yup.string()
                      .label('Ownership Class')
                      .oneOf(['public', 'private', 'subsidiary', 'other'])
                      .nullable(),
                    practice: Yup.object().label('Practice').nullable(),
                    phoneNumber: Yup.string().label('Phone Number').max(255).nullable(),
                    revenue: Yup.number().label('Revenue').min(0).max(99999999999).nullable(),
                    sicCodeId: Yup.string().label('SIC Code').max(255).nullable(),
                    shippingAddress: Yup.string().label('Shipping Address').max(5000).nullable(),
                    tickerSymbol: Yup.string().label('Ticker Symbol').max(10).nullable(),
                    timeZoneId: Yup.string().label('Time Zone').max(255).nullable(),
                    twitterUrl: Yup.string()
                      .label('Twitter URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    websiteUrl: Yup.string()
                      .label('Website URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024)
                      .nullable(),
                    yearFounded: Yup.number().label('Year Founded').min(1800).max(2100).nullable(),
                  },
            )}>
            {(formik) => {
              const { values, setValues, submitForm } = formik;

              const handleCurrencyChange = ({ target: { value } }) => {
                setValues({
                  ...values,
                  currency: value,
                });
              };

              return (
                <Form>
                  {status && <FormMessage.Error spaceBottom>{message}</FormMessage.Error>}
                  <Form.Section title="Basic Information">
                    <Form.Control>
                      <Field.Text name="name" placeholder="Name" maxLength={255} disabled={salesforceReadOnly} />
                    </Form.Control>

                    <Form.Control>
                      <Field.ClientSelect
                        name="client"
                        placeholder="Client"
                        initialValue={company.client}
                        allowNew
                        withoutCompanyAssigned
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.MemberSelect
                        name="owner"
                        placeholder="Relationship Owner"
                        initialValue={company && company.owner}
                        disabled={salesforceReadOnly}
                        clearable={!salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.IndustrySelect
                        name="industry"
                        placeholder="Industry"
                        allowNew
                        disabled={salesforceReadOnly}
                        clearable={!salesforceReadOnly}
                      />
                    </Form.Control>

                    {features.practices && (
                      <Form.Control>
                        <Field.PracticeSelect name="practice" placeholder="Practice" allowNew />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.CompanyTypeSelect
                        name="companyType"
                        placeholder="Company Type"
                        allowNew
                        disabled={salesforceReadOnly}
                        clearable={!salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.CompanySpendTierSelect name="companySpendTier" placeholder="Company Spend Tier" />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Currency
                        name="revenue"
                        placeholder="Annual Revenue"
                        currency={company.currency}
                        disabled={salesforceReadOnly}
                      />
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        placeholder="Currency"
                        clearable={false}
                        disabled={!features.multicurrency || salesforceReadOnly}
                        onChange={handleCurrencyChange}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect
                        name="ownershipClass"
                        placeholder="Ownership Class"
                        showEmptyOption={!salesforceReadOnly}
                        disabled={salesforceReadOnly}>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="subsidiary">Subsidiary</option>
                        <option value="other">Other</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.Number
                        name="numberOfEmployees"
                        placeholder="Number of Employees"
                        min={0}
                        precision={0}
                        disabled={salesforceReadOnly}
                      />
                      <Field.Number
                        name="yearFounded"
                        placeholder="Year Founded"
                        min={1800}
                        max={2100}
                        precision={0}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="tickerSymbol"
                        placeholder="Ticker Symbol"
                        maxLength={10}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.SicCodeSelect
                        name="sicCodeId"
                        placeholder="SIC Code"
                        disabled={salesforceReadOnly}
                        clearable={!salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="phoneNumber"
                        placeholder="Phone Number"
                        maxLength={255}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="billingAddress"
                        placeholder="Billing Address"
                        maxLength={5000}
                        rows={4}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="shippingAddress"
                        placeholder="Shipping Address"
                        maxLength={5000}
                        rows={4}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="facebookUrl" placeholder="Facebook URL" maxLength={1024} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="twitterUrl" placeholder="Twitter URL" maxLength={1024} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="linkedinUrl" placeholder="LinkedIn URL" maxLength={1024} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="websiteUrl"
                        placeholder="Website URL"
                        maxLength={1024}
                        disabled={salesforceReadOnly}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    {company.id && company.permissions.manage && (
                      <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                    )}

                    <Buttons align="right">
                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                        <Button isLoading={isSubmitting} onClick={submitForm}>
                          Save &amp; Close
                        </Button>
                      </Buttons>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default CompanyForm;
