import SingleSelect from '~/components/SingleSelect';
import React from 'react';

const options = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

export default function PurchaseOrderStatusFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Purchase Order Status"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}
