import {
  Avatar,
  CloudFolderLink,
  CompanyLink,
  Grid,
  HelpMessage,
  Level,
  MemberContactPopover,
  MultilineText,
  Page,
  Pill,
  Pills,
} from '~/components';
import { useWorkspace } from '~/contexts';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import EffectiveRateWidget from './widgets/ClientEffectiveRateWidget';
import ClientExpensesWidget from './widgets/ClientExpensesWidget';
import ClientHoursWidget from './widgets/ClientHoursWidget';
import ClientMarginWidget from './widgets/ClientMarginWidget';
import ClientRealizationRateWidget from './widgets/ClientRealizationRateWidget';
import ClientRevenueWidget from './widgets/ClientRevenueWidget';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
  }
`;

const Panel = styled.div`
  position: absolute;
  width: calc(20% + 2.5rem);
  background: ${colors.grey5};
  right: -2rem;
  top: calc(-2.5rem - 1px);
  min-height: calc(100% + 4.5rem + 1px);
  padding: 2.75rem 2rem;
`;

const Info = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
`;

const PillLink = styled(Link)`
  &:hover ${Pill} {
    transition: all 100ms ease-in-out;
    background: ${colors.accent};
  }
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}
function ClientDetailsPage({ client }) {
  const { workspace } = useWorkspace();

  const kpi = {
    realizationRate: !client.isInternal && client.permissions.viewTimeAndExpenses,
    effectiveRate: !client.isInternal && client.permissions.viewRates,
    servicesMargin: !client.isInternal && client.permissions.viewMargin,
    hours: client.permissions.viewTimeAndExpenses,
    expenses: client.permissions.viewTimeAndExpenses,
    revenue: !client.isInternal && client.permissions.viewRevenue,
    get any() {
      return (
        this.realizationRate || this.effectiveRate || this.servicesMargin || this.hours || this.expenses || this.revenue
      );
    },
  };

  return (
    <Page.Section style={{ position: 'relative', flex: 1 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="75%">
            {!client.permissions.viewTimeAndExpenses && (
              <HelpMessage>
                Your visibility into this client is limited because you do not have permission to view the time and
                expenses for every project within this client.
              </HelpMessage>
            )}

            {kpi.any && (
              <>
                <Section>
                  <Level style={{ marginBottom: '2rem' }}>
                    <Level.Item>
                      <h3>Key Performance Indicators</h3>
                    </Level.Item>
                  </Level>

                  <Grid>
                    <Grid>
                      <Grid.Row>
                        {kpi.realizationRate && (
                          <Grid.Column size={4}>
                            <ClientRealizationRateWidget client={client} />
                          </Grid.Column>
                        )}

                        {kpi.effectiveRate && (
                          <Grid.Column size={4}>
                            <EffectiveRateWidget client={client} />
                          </Grid.Column>
                        )}

                        {kpi.servicesMargin && (
                          <Grid.Column size={4}>
                            <ClientMarginWidget client={client} />
                          </Grid.Column>
                        )}

                        {kpi.hours && (
                          <Grid.Column size={4}>
                            <ClientHoursWidget client={client} />
                          </Grid.Column>
                        )}

                        {kpi.expenses && (
                          <Grid.Column size={4}>
                            <ClientExpensesWidget client={client} />
                          </Grid.Column>
                        )}

                        {kpi.revenue && (
                          <Grid.Column size={4}>
                            <ClientRevenueWidget client={client} />
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Grid>
                </Section>
              </>
            )}
          </Grid.Column>
          <Grid.Column width="2rem" />
        </Grid.Row>
      </Grid>

      <>
        <Panel>
          <Section>
            <h3>Client Details</h3>
            {client && (
              <>
                {client.owner && (
                  <InfoBlock title="Relationship Owner">
                    <Owner>
                      <MemberContactPopover member={client.owner}>
                        <Avatar value={client.owner} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>

                      <OwnerInfo>{client.owner.name}</OwnerInfo>
                    </Owner>
                  </InfoBlock>
                )}

                {client.businessUnit && <InfoBlock title="Business Unit">{client.businessUnit.name}</InfoBlock>}

                {client.salesRepresentative && (
                  <InfoBlock title="Sales Representative">
                    <Owner>
                      <MemberContactPopover member={client.salesRepresentative}>
                        <Avatar value={client.salesRepresentative} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>

                      <OwnerInfo>{client.salesRepresentative.name}</OwnerInfo>
                    </Owner>
                  </InfoBlock>
                )}

                {client.company && (
                  <InfoBlock title={'Company'}>
                    <CompanyLink company={client.company} />
                  </InfoBlock>
                )}

                <InfoBlock title="Currency">
                  {client.currencyName} ({client.currency})
                </InfoBlock>

                {client.industry && <InfoBlock title="Industry">{client.industry.name}</InfoBlock>}

                {client.practice && <InfoBlock title="Practice">{client.practice.name}</InfoBlock>}

                {client.code && <InfoBlock title="Client Code">{client.code}</InfoBlock>}

                {client.tags?.length > 0 && (
                  <InfoBlock title="Tags">
                    <Pills>
                      {client.tags.map((tag) => (
                        <PillLink key={tag.id} to={`/app/${workspace.key}/clients?clientTag=${tag.id}`}>
                          <Pill variant="grey10">{tag.name}</Pill>
                        </PillLink>
                      ))}
                    </Pills>
                  </InfoBlock>
                )}

                {client.location && <InfoBlock title="Location">{client.location.name}</InfoBlock>}

                {client.cloudFolderUrl && (
                  <InfoBlock title="Cloud Folder">
                    <CloudFolderLink url={client.cloudFolderUrl} />
                  </InfoBlock>
                )}

                {client.notes && (
                  <InfoBlock title="Notes">
                    <MultilineText value={client.notes} chars={280} buttonProps={{ isAnchor: true }} />
                  </InfoBlock>
                )}
              </>
            )}
          </Section>
        </Panel>
      </>
    </Page.Section>
  );
}
export default ClientDetailsPage;
export { ClientDetailsPage };
