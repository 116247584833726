import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { alignToJustify } from './TableColumn';
import { useTable } from './TableContext';

const Cell = styled.div`
  flex: ${({ column }) => (column?.width ? `0 0 ${column?.width}` : '1')};
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2rem;
  justify-content: ${({ column }) => alignToJustify(column.align)};
  min-width: ${({ column }) => column.minWidth ?? column.width ?? 'auto'};
  padding: ${({ column, padding }) => (padding ? padding : column?.padding ? column.padding : '0.5rem 1rem')};
  text-align: ${({ column }) => column.align};
  font-size: ${({ column }) => column.fontSize ?? '0.875rem'};

  ${({ column }) =>
    !!column?.sticky &&
    css`
      position: sticky;
      left: -2rem;
      z-index: 1;
      background: linear-gradient(to right, ${colors.white} 90%, transparent 100%);
    `}
`;

function TableCell({ className, children, index, ...props }) {
  const table = useTable();
  const column = table.columns[index];

  if (!column?.isVisible) return null;
  return (
    <Cell
      {...props}
      column={column}
      className={classNames(className, 'table-cell', {
        [`cell-${column?.name}`]: column?.name,
      })}>
      {children}
    </Cell>
  );
}

export default styled(TableCell)``;
