import {
  BackLink,
  Buttons,
  ClientLink,
  Currency,
  DateTime,
  DeleteButton,
  FieldControl,
  Form,
  Grid,
  Icon,
  InlineTooltip,
  MultilineText,
  Page,
  Percent,
  RouteLink,
  SplitButton,
  Table,
  Tooltip,
  TooltipButton,
} from '~/components';
import { useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useNumberFormat } from '~/hooks';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import IssueCreditNoteDrawer from '../..//billing/credit-notes/dialogs/IssueCreditNoteDrawer';
import ProjectTags from '../components/ProjectTags';
import QBOIndicator from '../components/QBOIndicator';
import XeroInvoiceIndicator from '../components/XeroInvoiceIndicator';
import InvoiceWebLinkModal from '../invoice-actions/InvoiceWebLinkModal';
import LoadFromQuickBooksDialog from '../invoice-dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../invoice-dialogs/LoadFromXeroDialog';
import SaveToQuickBooksDialog from '../invoice-dialogs/SaveToQuickBooksDialog';
import SaveToXeroDialog from '../invoice-dialogs/SaveToXeroDialog';
import UnpublishInvoiceDialog from '../invoice-dialogs/UnpublishInvoiceDialog';
import ClientPaymentForm from '../payments/ClientPaymentForm';
import ClientPaymentView from '../payments/ClientPaymentView';
import InvoiceHistoryDrawer from './InvoiceHistoryDrawer';
import LineAmountOffTooltip from './LineAmountOffTooltip';
import LineTypeTooltip from './LineTypeTooltip';
import {
  ActionsContainer,
  BilledTo,
  EditButton,
  Footer,
  Header,
  HeaderEnd,
  InfoContainer,
  InvoiceFor,
  InvoiceName,
  InvoiceNotes,
  InvoiceNotesLabel,
  LineItemsSection,
  PreviewButton,
  SaveButton,
  Separator,
  SummaryDetails,
  SummaryDetailsRow,
  SummaryGrid,
  SummarySection,
  SummaryTotal,
  Tag,
  Tags,
  TotalLabel,
  TotalRow,
  TotalValue,
  Totals,
  TotalsSeparator,
} from './StyledComponents';

const P = styled.p`
  white-space: pre-wrap;
`;

function HiddenColumnTooltip() {
  return (
    <Tooltip
      style={{ marginLeft: '0.5rem' }}
      message="This column will not be shown on the published version of this invoice.">
      <Icon icon="info-circle" />
    </Tooltip>
  );
}

function PublishedInvoiceView({ invoice, onChange, onDelete, onPreview, onReset, onSend }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const integrations = useIntegrations();
  const [dialog, setDialog] = useState(null);

  const documentTitle = useDocumentTitle(invoice ? `Invoice #${invoice.number}` : undefined);

  const confirmation = useConfirmation();

  const handleGetWebLink = () => {
    setDialog('webLink');
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
    documentTitle.set(invoice ? `Invoice #${invoice.number}` : undefined);
  };

  const handleUnpublish = async () => {
    await confirmation.prompt((resolve) => (
      <UnpublishInvoiceDialog
        invoice={invoice}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleSend = () => {
    onSend();
  };

  const handleReceivePayment = () => {
    setDialog('payment');
  };

  const handleIssueCreditNote = () => {
    setDialog('issueCreditNote');
  };

  const history = useHistory();
  const handleCreditNoteIssued = async (creditNote) => {
    history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`);
  };

  const handleSaveToQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleSaveToXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToXeroDialog
        invoice={invoice}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        invoice={invoice}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const { nonTaxableSubtotal, taxableSubtotal, tax, total } = invoice;

  const quantityFormat = useNumberFormat({ minimumFractionDigits: 0 });

  const currency = invoice.currency;

  const receivePayment = !invoice.permissions.receivePayment
    ? {
        disabled: true,
        tooltip: 'Insufficient permissions to receive a payment on this invoice.',
      }
    : !features.multicurrency && invoice.currency !== workspace.currency
      ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
      : { disabled: false, tooltip: undefined };

  const issueCreditNote = !invoice.permissions.applyCredit
    ? {
        disabled: true,
        tooltip: 'Insufficient permissions to issue a credit note for this invoice.',
      }
    : !features.multicurrency && invoice.currency !== workspace.currency
      ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
      : { disabled: false, tooltip: undefined };

  const column = (name) => invoice.displayColumns.includes(name);

  return (
    <Page>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/billing/invoices`} />
        <InfoContainer>
          <InvoiceName>Invoice #{invoice.number}</InvoiceName>
          <Tags>
            <Tag>
              <ClientLink client={invoice.client} />
            </Tag>

            {!_.isEmpty(invoice.projects) && (
              <Tag>
                <ProjectTags client={invoice.client} projects={invoice.projects} />
              </Tag>
            )}

            {features.multicurrency && <Tag>{currency}</Tag>}

            <Tag color={{ open: colors.primary, paid: colors.success }[invoice.status.id]}>
              {invoice.status.name}

              {invoice.qboInvoiceId && <QBOIndicator />}
              {invoice.xeroInvoiceId && <XeroInvoiceIndicator xeroInvoiceId={invoice.xeroInvoiceId} />}
            </Tag>
            {invoice.sentAt && (
              <Tag>
                Sent on&nbsp;
                <DateTime value={invoice.sentAt} />
              </Tag>
            )}
            {invoice.status.id === 'open' && (
              <Tag>
                Open Balance of <Currency value={invoice.balance} currency={currency} />
              </Tag>
            )}

            {invoice.isLate && (
              <Tag color={colors.danger}>
                {invoice.daysLate} {invoice.daysLate === 1 ? 'Day' : 'Days'} Late
              </Tag>
            )}
          </Tags>
        </InfoContainer>

        <HeaderEnd>
          <Buttons>
            <EditButton onClick={handleViewHistory} type="button">
              <Icon icon="history" />
            </EditButton>
          </Buttons>
        </HeaderEnd>
      </Header>

      <SummarySection>
        <SummaryGrid>
          <SummaryDetailsRow equalHeight>
            <Grid.Column>
              <BilledTo>
                <dt>Bill To</dt>
                <dl>
                  <MultilineText value={invoice.billTo} />
                </dl>
              </BilledTo>
            </Grid.Column>
            <Grid.Column>
              <SummaryDetails>
                {invoice.poNumber && (
                  <>
                    <dt>PO #</dt>
                    <dd>{invoice.poNumber}</dd>
                  </>
                )}

                {invoice.servicesThrough && (
                  <>
                    <dt>Through</dt>
                    <dd>
                      <DateTime value={invoice.servicesThrough} />
                    </dd>
                  </>
                )}

                <dt>Issued</dt>
                <dd>
                  <DateTime value={invoice.issuedOn} />
                </dd>
                <dt>Due</dt>
                <dd>
                  {invoice.paymentTermsId === 'due_on_receipt' ? 'On Receipt' : <DateTime value={invoice.dueOn} />}
                </dd>
              </SummaryDetails>
            </Grid.Column>

            <Grid.Column>
              <SummaryTotal>
                <FieldControl>
                  <strong>
                    <Currency value={total} currency={currency} />
                  </strong>
                </FieldControl>
                <small>Total Invoiced</small>
              </SummaryTotal>
            </Grid.Column>
          </SummaryDetailsRow>
          <Grid.Row>
            <Grid.Column>
              <InvoiceFor>
                <dt>Subject</dt>
                <dd>{invoice.invoiceFor}</dd>
              </InvoiceFor>
            </Grid.Column>
          </Grid.Row>
        </SummaryGrid>
      </SummarySection>

      <LineItemsSection>
        <Table style={{ fontSize: '.875rem' }}>
          <Table.BoxHeader>
            <Table.Column width="15rem">Item {!column('item') && <HiddenColumnTooltip />}</Table.Column>
            <Table.Column>Details {!column('details') && <HiddenColumnTooltip />}</Table.Column>
            <Table.Column align="right" width="7rem">
              QTY {!column('quantity') && <HiddenColumnTooltip />}
            </Table.Column>
            <Table.Column align="right" width="11rem">
              Rate {!column('rate') && <HiddenColumnTooltip />}
            </Table.Column>
            <Table.Column align="right" width="10rem">
              Amount
            </Table.Column>
            <Table.Column align="center" width="4rem">
              Tax {!column('tax') && <HiddenColumnTooltip />}
            </Table.Column>
            <Table.Column align="center" width="3.5rem">
              <Icon icon="link" />
            </Table.Column>
          </Table.BoxHeader>

          {invoice.lines.map((line) => (
            <Table.BoxRow key={line.id}>
              <Table.Cell>{line.item}</Table.Cell>

              <Table.Cell>
                <div>
                  <P>{line.description}</P>
                </div>
              </Table.Cell>

              <Table.Cell align="right">{line.quantity ? quantityFormat.format(line.quantity) : ''}</Table.Cell>

              <Table.Cell align="right">
                <Currency value={line.rate} useDash={false} maximumFractionDigits={7} currency={currency} />
              </Table.Cell>

              <Table.Cell align="right">
                <Currency value={line.amount} useDash={false} currency={currency} />
                {line.transactionType && line.transactionAmount !== line.amount && (
                  <LineAmountOffTooltip line={line} currency={currency} />
                )}
              </Table.Cell>

              <Table.Cell>{line.taxable && <Icon icon="check" color={colors.success} />}</Table.Cell>

              <Table.Cell>
                <LineTypeTooltip line={line} />
              </Table.Cell>
            </Table.BoxRow>
          ))}
        </Table>

        <Separator />
      </LineItemsSection>

      <Footer>
        {invoice.notes && (
          <InvoiceNotes>
            <InvoiceNotesLabel>Note to Client</InvoiceNotesLabel>
            <P>{invoice.notes}</P>
          </InvoiceNotes>
        )}

        <Totals>
          {tax > 0 && (
            <TotalRow>
              <TotalLabel>Subtotal</TotalLabel>
              <TotalValue>
                <Currency value={invoice.linesSubtotal} currency={currency} />
              </TotalValue>
            </TotalRow>
          )}

          {invoice.discountAmount > 0 && (
            <>
              <TotalRow>
                <TotalLabel>Discount</TotalLabel>
                <TotalValue>
                  <Currency value={invoice.discountAmount * -1} currency={currency} />
                </TotalValue>
              </TotalRow>

              {tax === 0 && <TotalsSeparator />}
            </>
          )}

          {tax > 0 && (
            <>
              <TotalsSeparator />

              <TotalRow>
                <TotalLabel>Non-Taxable Subtotal</TotalLabel>
                <TotalValue>
                  <Currency value={nonTaxableSubtotal} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalRow>
                <TotalLabel>Taxable Subtotal</TotalLabel>
                <TotalValue>
                  <Currency value={taxableSubtotal} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalRow>
                <TotalLabel>
                  Tax{' '}
                  {invoice.taxRate !== null && (
                    <Percent value={invoice.taxRate / 100} minimumFractionDigits={0} maximumFractionDigits={7} />
                  )}
                </TotalLabel>
                <TotalValue>
                  <Currency value={tax} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalsSeparator />
            </>
          )}

          <TotalRow style={{ fontWeight: weights.bold }}>
            <TotalLabel>Total Invoiced</TotalLabel>
            <TotalValue>
              <Currency value={total} currency={currency} />
            </TotalValue>
          </TotalRow>
          {invoice.creditNotes?.length > 0 &&
            invoice.creditNotes.map(({ amount, creditNoteId }) => {
              return (
                <TotalRow key={creditNoteId} data-testid={creditNoteId}>
                  <TotalLabel>Applied Credit</TotalLabel>
                  <TotalValue>
                    <RouteLink to={`/app/${workspace.key}/billing/credit-notes/${creditNoteId}`}>
                      <Currency value={amount} currency={currency} />
                    </RouteLink>
                  </TotalValue>
                </TotalRow>
              );
            })}
          {invoice.payments?.length > 0 &&
            invoice.payments.map(({ amount, clientPaymentId }) => {
              function handleViewPayment() {
                setDialog({ type: 'viewPayment', clientPaymentId });
              }
              return (
                <TotalRow key={clientPaymentId} data-testid={clientPaymentId}>
                  <TotalLabel>Payment Received</TotalLabel>
                  <TotalValue clickable onClick={handleViewPayment}>
                    <Currency value={amount} currency={currency} />
                  </TotalValue>
                </TotalRow>
              );
            })}
          <TotalRow style={{ fontWeight: weights.bold }}>
            <TotalLabel>Balance Due</TotalLabel>
            <TotalValue>
              <Currency value={invoice.balance} currency={currency} />
            </TotalValue>
          </TotalRow>
        </Totals>
      </Footer>

      <Form.Actions>
        <TooltipButton
          component={DeleteButton}
          disabled={!invoice.permissions.manage || invoice.hasPayments || invoice.hasCreditNotes}
          tooltip={
            !invoice.permissions.manage
              ? 'Insufficient permissions to delete this invoice'
              : invoice.hasPayments
                ? 'An invoice with received payments cannot be deleted.'
                : invoice.hasCreditNotes
                  ? 'An invoice with applied credit notes cannot be deleted.'
                  : undefined
          }
          onClick={onDelete}>
          Delete
        </TooltipButton>

        <ActionsContainer>
          <Buttons>
            <PreviewButton onClick={onPreview}>Preview</PreviewButton>

            <SplitButton>
              {
                {
                  open: invoice.sentAt ? (
                    <SaveButton disabled={receivePayment.disabled} onClick={handleReceivePayment}>
                      Receive Payment
                      {receivePayment.tooltip && <InlineTooltip message={receivePayment.tooltip} />}
                    </SaveButton>
                  ) : (
                    <SaveButton disabled={!invoice.permissions.manage} onClick={handleSend}>
                      Send
                      {!invoice.permissions.manage && (
                        <InlineTooltip message="Insufficient permissions to send this invoice." />
                      )}
                    </SaveButton>
                  ),

                  paid: (
                    <SaveButton onClick={handleSend} disabled={!invoice.permissions.manage}>
                      Send
                      {!invoice.permissions.manage && (
                        <InlineTooltip message="Insufficient permissions to send this invoice." />
                      )}
                    </SaveButton>
                  ),
                }[invoice.status.id]
              }

              <SplitButton.Menu position="top" data-testid="invoice_actions">
                {({ setIsOpen }) => {
                  const handleAction = async (action) => {
                    setIsOpen(false);
                    await action();
                  };

                  return {
                    open: (
                      <>
                        <SplitButton.Item
                          onClick={handleSend}
                          disabled={!invoice.permissions.manage}
                          tooltip={
                            !invoice.permissions.manage ? 'Insufficient permissions to send this invoice.' : undefined
                          }>
                          Send
                        </SplitButton.Item>

                        <SplitButton.Item
                          onClick={handleReceivePayment}
                          disabled={receivePayment.disabled}
                          tooltip={receivePayment.tooltip}>
                          Receive Payment
                        </SplitButton.Item>

                        <SplitButton.Item
                          onClick={handleIssueCreditNote}
                          disabled={issueCreditNote.disabled}
                          tooltip={issueCreditNote.tooltip}>
                          Issue Credit Note
                        </SplitButton.Item>

                        <SplitButton.Item
                          disabled={invoice.hasPayments || invoice.hasCreditNotes || !invoice.permissions.manage}
                          tooltip={
                            !invoice.permissions.manage
                              ? 'Insufficient permissions to unpublish this invoice.'
                              : invoice.hasPayments
                                ? 'An invoice with received payments cannot be unpublished.'
                                : invoice.hasCreditNotes
                                  ? 'An invoice with applied credit notes cannot be unpublished.'
                                  : undefined
                          }
                          onClick={() => handleAction(handleUnpublish)}>
                          Unpublish
                        </SplitButton.Item>

                        <SplitButton.Item onClick={handleGetWebLink}>Get Web Link</SplitButton.Item>

                        {integrations.qbo &&
                          (invoice.qboInvoiceId ? (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                              Reload from QuickBooks
                            </SplitButton.Item>
                          ) : (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                              Save to QuickBooks
                            </SplitButton.Item>
                          ))}

                        {!!integrations.xero &&
                          (invoice.xeroInvoiceId ? (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to reload this invoice from Xero.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                              Reload from Xero
                            </SplitButton.Item>
                          ) : (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to save this invoice to Xero.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                              Save to Xero
                            </SplitButton.Item>
                          ))}
                      </>
                    ),

                    paid: (
                      <>
                        <SplitButton.Item
                          onClick={handleSend}
                          disabled={!invoice.permissions.manage}
                          tooltip={
                            !invoice.permissions.manage ? 'Insufficient permissions to send this invoice.' : undefined
                          }>
                          Send
                        </SplitButton.Item>

                        <SplitButton.Item
                          disabled={invoice.hasPayments || invoice.hasCreditNotes || !invoice.permissions.manage}
                          tooltip={
                            !invoice.permissions.manage
                              ? 'Insufficient permissions to unpublish this invoice.'
                              : invoice.hasPayments
                                ? 'An invoice with received payments cannot be unpublished.'
                                : invoice.hasCreditNotes
                                  ? 'An invoice with applied credit notes cannot be unpublished.'
                                  : undefined
                          }
                          onClick={() => handleAction(handleUnpublish)}>
                          Unpublish
                        </SplitButton.Item>

                        <SplitButton.Item onClick={handleGetWebLink}>Get Web Link</SplitButton.Item>

                        {integrations.qbo &&
                          (invoice.qboInvoiceId ? (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                              Reload from QuickBooks
                            </SplitButton.Item>
                          ) : (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                              Save to QuickBooks
                            </SplitButton.Item>
                          ))}

                        {!!integrations.xero &&
                          (invoice.xeroInvoiceId ? (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to reload this invoice from Xero.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                              Reload from Xero
                            </SplitButton.Item>
                          ) : (
                            <SplitButton.Item
                              disabled={!invoice.permissions.manage}
                              tooltip={
                                !invoice.permissions.manage
                                  ? 'Insufficient permissions to save this invoice to Xero.'
                                  : undefined
                              }
                              onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                              Save to Xero
                            </SplitButton.Item>
                          ))}
                      </>
                    ),
                  }[invoice.status.id];
                }}
              </SplitButton.Menu>
            </SplitButton>
          </Buttons>
        </ActionsContainer>
      </Form.Actions>

      {
        {
          webLink: <InvoiceWebLinkModal invoice={invoice} onClose={handleCloseDialog} />,
          payment: (
            <ClientPaymentForm
              invoiceId={invoice.id}
              onSaved={onReset}
              onClose={handleCloseDialog}
              onDeleted={handleCloseDialog}
            />
          ),
          viewPayment: <ClientPaymentView paymentId={dialog?.clientPaymentId} onClose={handleCloseDialog} />,
          issueCreditNote: (
            <IssueCreditNoteDrawer
              invoiceId={invoice.id}
              onSaved={handleCreditNoteIssued}
              onClose={handleCloseDialog}
            />
          ),
          history: <InvoiceHistoryDrawer invoiceId={invoice.id} onClose={handleCloseDialog} />,
        }[dialog?.type ?? dialog]
      }
    </Page>
  );
}

export default PublishedInvoiceView;
