import _ from 'lodash';
import React from 'react';
import {
  BusinessUnitFilter,
  MemberFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectHealthFilter,
  ProjectRecordStatusFilter,
  ProjectRoleFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
} from '~/components';

function ProjectFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.projects) && (
        <ProjectFilter
          value={filters.projects}
          onChange={({ value }) => {
            const result = { projects: value };

            if (_.has(filters, 'projectRoles')) result.projectRoles = [];

            if (_.has(filters, 'projectTasks')) result.projectTasks = [];

            onChange(result);
          }}
        />
      )}

      {!_.isEmpty(filters.projectRoles) && (
        <ProjectRoleFilter value={filters.projectRoles} onChange={({ value }) => onChange({ projectRoles: value })} />
      )}

      {!_.isEmpty(filters.projectBudgetHealth) && (
        <ProjectHealthFilter
          materialPlaceholder="Budget Health"
          value={filters.projectBudgetHealth}
          onChange={({ value }) => onChange({ projectBudgetHealth: value })}
        />
      )}

      {!_.isEmpty(filters.projectScheduleHealth) && (
        <ProjectHealthFilter
          materialPlaceholder="Schedule Health"
          value={filters.projectScheduleHealth}
          onChange={({ value }) => onChange({ projectScheduleHealth: value })}
        />
      )}

      {!_.isEmpty(filters.projectClientSatisfaction) && (
        <ProjectHealthFilter
          materialPlaceholder="Client Satisfaction"
          value={filters.projectClientSatisfaction}
          onChange={({ value }) => onChange({ projectClientSatisfaction: value })}
        />
      )}

      {!_.isEmpty(filters.projectTeamSatisfaction) && (
        <ProjectHealthFilter
          materialPlaceholder="Team Satisfaction"
          value={filters.projectTeamSatisfaction}
          onChange={({ value }) => onChange({ projectTeamSatisfaction: value })}
        />
      )}

      {!_.isEmpty(filters.projectBillingTypes) && (
        <ProjectBillingTypeFilter
          value={filters.projectBillingTypes}
          onChange={({ value }) => onChange({ projectBillingTypes: value })}
        />
      )}

      {!_.isEmpty(filters.projectStatuses) && (
        <ProjectStatusFilter
          value={filters.projectStatuses}
          onChange={({ value }) => onChange({ projectStatuses: value })}
        />
      )}

      {!_.isEmpty(filters.projectRecordStatusId) && (
        <ProjectRecordStatusFilter
          value={filters.projectRecordStatusId}
          onChange={({ value }) => onChange({ projectRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.projectTags) && (
        <ProjectTagFilter value={filters.projectTags} onChange={({ value }) => onChange({ projectTags: value })} />
      )}

      {!_.isEmpty(filters.projectPractices) && (
        <PracticeFilter
          materialPlaceholder="Project Practice"
          value={filters.projectPractices}
          onChange={({ value }) => onChange({ projectPractices: value })}
        />
      )}

      {!_.isEmpty(filters.projectBusinessUnits) && (
        <BusinessUnitFilter
          materialPlaceholder="Project Business Unit"
          value={filters.projectBusinessUnits}
          onChange={({ value }) => onChange({ projectBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(filters.projectTypes) && (
        <ProjectTypeFilter value={filters.projectTypes} onChange={({ value }) => onChange({ projectTypes: value })} />
      )}

      {!_.isEmpty(filters.projectAdmins) && (
        <MemberFilter
          materialPlaceholder="Project Admin"
          value={filters.projectAdmins}
          onChange={({ value }) => onChange({ projectAdmins: value })}
        />
      )}

      {!_.isEmpty(filters.projectSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Sales Representative"
          value={filters.projectSalesRepresentatives}
          onChange={({ value }) => onChange({ projectSalesRepresentatives: value })}
        />
      )}
    </>
  );
}

export default ProjectFiltersBar;
