import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  AllocationBillableTypeFilter,
  BusinessUnitFilter,
  ClientFilter,
  ClientTagFilter,
  DisciplineFilter,
  EmploymentTypeFilter,
  IndustryFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberFilter,
  MemberTagFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  SingleSelect,
  SkillFilter,
  YesNoFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import FiltersBarContainer from '../../components/FiltersBar';
import RecordStatusFilter from '../../project-schedule/filters/RecordStatusFilter';
import ResourceStatusFilter from './ResourceStatusFilter';
import ResourceTypeFilter from './ResourceTypeFilter';

export default function FiltersBar({ params: filters, onFilterChange, onMetricChange }) {
  const features = useFeatures();

  return (
    <FiltersBarContainer>
      <MetricFilter value={filters.metric} onChange={({ target: { value } }) => onMetricChange(value)} />

      <YesNoFilter
        materialPlaceholder="Only show resources with allocations"
        value={filters.onlyAllocatedResources === true ? 'yes' : 'no'}
        onChange={({ value }) => onFilterChange({ onlyAllocatedResources: value === 'yes' })}
      />

      {!_.isEmpty(filters.client) && (
        <ClientFilter
          value={filters.client}
          onChange={({ target: { value } }) => onFilterChange({ client: value, project: [] })}
        />
      )}

      {!_.isEmpty(filters.clientOwner) && (
        <MemberFilter
          value={filters.clientOwner}
          materialPlaceholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onFilterChange({ clientOwner: value })}
        />
      )}

      {!_.isEmpty(filters.clientTags) && (
        <ClientTagFilter
          value={filters.clientTags}
          onChange={({ target: { value } }) => onFilterChange({ clientTags: value })}
        />
      )}

      {!_.isEmpty(filters.clientLocations) && (
        <LocationFilter
          value={filters.clientLocations}
          materialPlaceholder="Client Location"
          onChange={({ target: { value } }) => onFilterChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(filters.clientIndustries) && (
        <IndustryFilter
          value={filters.clientIndustries}
          materialPlaceholder="Client Industry"
          onChange={({ target: { value } }) => onFilterChange({ clientIndustries: value })}
        />
      )}

      {features.businessUnits && !_.isEmpty(filters.clientBusinessUnits) && (
        <BusinessUnitFilter
          value={filters.clientBusinessUnits}
          materialPlaceholder="Client Business Unit"
          onChange={({ target: { value } }) => onFilterChange({ clientBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(filters.project) && (
        <ProjectFilter
          clients={filters.client}
          value={filters.project}
          onChange={({ target: { value } }) => onFilterChange({ project: value })}
        />
      )}

      {!_.isEmpty(filters.projectAdmin) && (
        <MemberFilter
          value={filters.projectAdmin}
          materialPlaceholder="Project Admin"
          onChange={({ target: { value } }) => onFilterChange({ projectAdmin: value })}
        />
      )}

      {!_.isEmpty(filters.projectBillingType) && (
        <ProjectBillingTypeFilter
          value={filters.projectBillingType}
          onChange={({ target: { value } }) => onFilterChange({ projectBillingType: value })}
        />
      )}

      {!_.isEmpty(filters.projectStatus) && (
        <ProjectStatusFilter
          value={filters.projectStatus}
          onChange={({ target: { value } }) => onFilterChange({ projectStatus: value })}
        />
      )}

      {!_.isEmpty(filters.projectRecordStatusId) && (
        <RecordStatusFilter
          value={filters.projectRecordStatusId}
          onChange={({ target: { value } }) => onFilterChange({ projectRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.projectTags) && (
        <ProjectTagFilter
          value={filters.projectTags}
          onChange={({ target: { value } }) => onFilterChange({ projectTags: value })}
        />
      )}

      {features.practices && !_.isEmpty(filters.projectPractice) && (
        <PracticeFilter
          materialPlaceholder="Project Practice"
          value={filters.projectPractice}
          onChange={({ target: { value } }) => onFilterChange({ projectPractice: value })}
        />
      )}

      {features.businessUnits && !_.isEmpty(filters.projectBusinessUnits) && (
        <BusinessUnitFilter
          materialPlaceholder="Project Business Unit"
          value={filters.projectBusinessUnits}
          onChange={({ target: { value } }) => onFilterChange({ projectBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(filters.projectTypes) && (
        <ProjectTypeFilter
          value={filters.projectTypes}
          onChange={({ target: { value } }) => onFilterChange({ projectTypes: value })}
        />
      )}

      {!_.isEmpty(filters.member) && (
        <MemberFilter
          value={filters.member}
          materialPlaceholder="Member"
          onChange={({ target: { value } }) => onFilterChange({ member: value })}
        />
      )}

      {!_.isEmpty(filters.employmentType) && (
        <EmploymentTypeFilter
          value={filters.employmentType}
          onChange={({ target: { value } }) => onFilterChange({ employmentType: value })}
        />
      )}

      {!_.isEmpty(filters.memberBillableTypeId) && (
        <MemberBillableTypeFilter
          value={filters.memberBillableTypeId}
          onChange={({ target: { value } }) => onFilterChange({ memberBillableTypeId: value })}
        />
      )}

      {!_.isEmpty(filters.jobTitles) && (
        <JobTitleFilter
          value={filters.jobTitles}
          onChange={({ target: { value } }) => onFilterChange({ jobTitles: value })}
        />
      )}

      {!_.isEmpty(filters.memberTags) && (
        <MemberTagFilter
          value={filters.memberTags}
          onChange={({ target: { value } }) => onFilterChange({ memberTags: value })}
        />
      )}

      {!_.isEmpty(filters.memberLocations) && (
        <LocationFilter
          value={filters.memberLocations}
          materialPlaceholder="Member Location"
          onChange={({ target: { value } }) => onFilterChange({ memberLocations: value })}
        />
      )}

      {features.practices && !_.isEmpty(filters.resourcePractice) && (
        <PracticeFilter
          materialPlaceholder="Resource Practice"
          value={filters.resourcePractice}
          onChange={({ value }) => onFilterChange({ resourcePractice: value })}
        />
      )}

      {features.disciplines && !_.isEmpty(filters.discipline) && (
        <DisciplineFilter
          materialPlaceholder="Resource Discipline"
          value={filters.discipline}
          onChange={({ value }) => onFilterChange({ discipline: value })}
        />
      )}

      {!_.isEmpty(filters.resourceLocation) && (
        <LocationFilter
          materialPlaceholder="Resource Location"
          value={filters.resourceLocation}
          onChange={({ target: { value } }) => onFilterChange({ resourceLocation: value })}
        />
      )}

      {!_.isEmpty(filters.resourceSkill) && (
        <SkillFilter
          materialPlaceholder="Resource Skills"
          value={filters.resourceSkill}
          onChange={({ target: { value } }) => onFilterChange({ resourceSkill: value })}
        />
      )}

      {!_.isEmpty(filters.resourceStatusId) && (
        <ResourceStatusFilter
          value={filters.resourceStatusId}
          onChange={({ target: { value } }) => onFilterChange({ resourceStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.resourceTypeId) && (
        <ResourceTypeFilter
          value={filters.resourceTypeId}
          onChange={({ target: { value } }) => onFilterChange({ resourceTypeId: value })}
        />
      )}

      {!_.isEmpty(filters.allocationBillableTypes) && (
        <AllocationBillableTypeFilter
          value={filters.allocationBillableTypes}
          onChange={({ target: { value } }) => onFilterChange({ allocationBillableTypes: value })}
        />
      )}
    </FiltersBarContainer>
  );
}

const metrics = [
  { id: 'all_hours', name: 'All Hours' },
  { id: 'project_hours', name: 'Project Hours' },
  { id: 'billable_hours', name: 'Billable Hours' },
  { id: 'productive_hours', name: 'Productive Hours' },
  { id: 'available_hours', name: 'Available Hours' },
  { id: 'billable_utilization', name: 'Billable Utilization' },
  { id: 'productive_utilization', name: 'Productive Utilization' },
  { id: 'target_attainment', name: 'Target Attainment' },
];

function MetricFilter({ value, onChange }) {
  const features = useFeatures();

  // Remove the utilization metrics from the dropdown options if the feature is not enabled.
  const options = useMemo(() => {
    return features.utilization
      ? metrics
      : Object.values(
          _.omitBy(metrics, ({ id }) =>
            ['billable_utilization', 'productive_utilization', 'target_attainment'].includes(id),
          ),
        );
  }, [features.utilization]);

  return (
    <SingleSelect materialPlaceholder="Metric" materialAlwaysVisible value={value} onChange={onChange}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}
