import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebookSquare,
  faLinkedinIn,
  faLinkedin,
  faSalesforce,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCalendarAlt as farCalendarAlt,
  faEnvelope as farEnvelope,
  faIdCard as farIdCard,
  faQuestionCircle as farQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChartSimple,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faAt,
  faBars,
  faBuilding,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faClock,
  faCloudUploadAlt,
  faCog,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFax,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilterCircleDollar,
  faFlask,
  faFolder,
  faGripLinesVertical,
  faGripVertical,
  faHistory,
  faHome,
  faHourglass,
  faImage,
  faInfoCircle,
  faKey,
  faLightbulb,
  faLink,
  faLock,
  faMinus,
  faMobile,
  faPause,
  faPenAlt,
  faPencilAlt,
  faPercent,
  faPlay,
  faPlus,
  faPrint,
  faReceipt,
  faRocket,
  faScrewdriverWrench,
  faSearch,
  faShareAlt,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUser,
  faUserCircle,
  faUserGear,
  faUsers,
  faUserSecret,
  faWrench,
  faVideo,
  faCaretRight,
  faCaretLeft,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBadgeCheck,
  faCalendarStar,
  faChevronsUp,
  faChevronsDown,
  faFileCsv,
  faHandshake,
  faImageSlash,
  faInboxIn,
  faInboxOut,
  faLockOpen,
  faMessageSms,
  faNote,
  faPhone,
  faPhoneOffice,
  faRectanglePro,
  faRepeat,
  faShield,
  faShieldCheck,
  faShieldExclamation,
  faShoePrints,
  faGrid2,
  faTableRows,
  faFileZip,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBarsProgress as farBarsProgress,
  faBook,
  faBuildings,
  faCalendar as farCalendar,
  faClipboardCheck as farClipboardCheck,
  faCommentLines,
  faFileAlt as farFileAlt,
  faGlobe as farGlobe,
  faHourglass as farHourglass,
  faPaperclipVertical as farPaperclipVertical,
  faPenAlt as farPenAlt,
  faStopwatch as farStopwatch,
  faTrianglePersonDigging,
  faUserHardHat as farUserHardHat,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faAt,
  faBadgeCheck,
  faBars,
  farBarsProgress,
  faBook,
  farGlobe,
  faBuilding,
  faBuildings,
  faCalendarStar,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faChartLine,
  faChartSimple,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronsUp,
  faChevronsDown,
  faCircle,
  faCloudUploadAlt,
  faClock,
  faClipboard,
  faCog,
  faCommentLines,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFacebookSquare,
  faFax,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilterCircleDollar,
  faFlask,
  faFolder,
  faGripLinesVertical,
  faGripVertical,
  faHandshake,
  faHistory,
  faHome,
  faHourglass,
  faImage,
  faImageSlash,
  faInboxIn,
  faInboxOut,
  faInfoCircle,
  faKey,
  faLink,
  faLightbulb,
  faLinkedin,
  faLinkedinIn,
  faLock,
  faLockOpen,
  faMessageSms,
  faMinus,
  faMobile,
  faNote,
  farPaperclipVertical,
  faPause,
  faPenAlt,
  faPencilAlt,
  faPercent,
  faPhone,
  faPhoneOffice,
  faPlay,
  faPlus,
  faPrint,
  farCalendar,
  farCalendarAlt,
  farClipboardCheck,
  farHourglass,
  faGrid2,
  farPenAlt,
  faReceipt,
  faRectanglePro,
  farEnvelope,
  farUserHardHat,
  faRepeat,
  farFileAlt,
  farIdCard,
  faRocket,
  farQuestionCircle,
  farStopwatch,
  faSalesforce,
  faScrewdriverWrench,
  faSearch,
  faShareAlt,
  faShield,
  faShieldCheck,
  faShieldExclamation,
  faShoePrints,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faTableRows,
  faFileZip,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrianglePersonDigging,
  faTwitterSquare,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserGear,
  faUsers,
  faUserSecret,
  faVideo,
  faWrench,
);
