import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiltersBar, InvoiceStatusSelect, Page, SearchInput, SingleSelect } from '~/components';
import { useWorkspace } from '~/contexts';
import intervals from '../invoices/invoices-list/intervals';
import InvoicesListPage from '../invoices/invoices-list/InvoicesListPage';

function Filters({ query, handleFilter }) {
  return (
    <Page.Filters>
      <FiltersBar>
        <SearchInput
          value={query.q}
          placeholder="Search"
          materialPlaceholder="Invoice #"
          materialAlwaysVisible
          onChange={({ target: { value } }) => handleFilter({ q: value })}
        />

        <InvoiceStatusSelect
          value={query.statusIds}
          onChange={({ target: { value } }) => handleFilter({ statusIds: value })}
        />

        <SingleSelect
          placeholder="All"
          materialPlaceholder="Issue Date"
          materialAlwaysVisible
          showEmptyOption
          value={query.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}>
          {_.map(intervals, ({ label }, key) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </SingleSelect>
      </FiltersBar>
    </Page.Filters>
  );
}

function ProjectInvoicesTab({ project }) {
  const { workspace } = useWorkspace();
  const history = useHistory();

  return (
    <InvoicesListPage
      mode="tab"
      showProjectColumn={false}
      projectId={project.id}
      renderFilters={Filters}
      onRowClick={(invoice) => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
    />
  );
}

export default ProjectInvoicesTab;
