import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '~/components';
import { colors } from '~/styles';

const StyledButton = styled(Button)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: none;
  padding: 0;

  &:hover {
    background-color: ${colors.grey10};
  }

  transition: all 100ms ease-in-out;
`;

function IconButton({ icon, ...props }) {
  return (
    <StyledButton isOutline {...props}>
      <Icon icon={icon} />
    </StyledButton>
  );
}

export default IconButton;
