import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post } from './agent';
import creditNoteFiles from './creditNoteFiles';

const creditNotes =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'credit-notes', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      create: (body) => post(url, body),
      issue: {
        get: (invoiceId) => get(url.concat(`/issue/${invoiceId}`)),
        post: (body) => post(url.concat('/issue'), body),
      },
      update: (body) => patch(url, body),
      delete: () => del(url),
      publish: (body) => post(url.concat('/publish'), body),
      unpublish: (body) => post(url.concat('/unpublish'), body),
      sendTestEmail: (body) => post(url.concat('/send-email?test=true'), body),
      sendEmail: (body) => post(url.concat('/send-email'), body),
      files: creditNoteFiles(url.concat('/files')),
      validateTransactionNumber: (body) => post(url.concat('/validate-transaction-number'), body),
      history: () => get(url.concat('/history')),
      projects: (query = {}) => get(url.concat('/projects').concat(new QueryString(query).toString(true))),
      purchaseOrders: (query = {}) => get(url.concat('/purchase-orders').concat(new QueryString(query).toString(true))),
      client: () => get(url.concat(`/client`)),
      findOpenInvoices: (query = {}) => get(url.concat('/open-invoices').concat(new QueryString(query).toString(true))),
      saveToQuickBooks: () => post(url.concat('/save-to-quickbooks')),
      loadFromQuickBooks: () => post(url.concat('/load-from-quickbooks')),
      removeFromQuickBooks: () => post(url.concat('/remove-from-quickbooks')),
      saveToXero: () => post(url.concat('/save-to-xero')),
      loadFromXero: () => post(url.concat('/load-from-xero')),
      removeFromXero: () => post(url.concat('/remove-from-xero')),
    };
  };

export default creditNotes;
