import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { DeleteConfirmation, ExportDialog } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, mimeTypes } from '~/utils';
import ActionBar from '../../resources/allocations/components/action-bar/ActionBar';
import Loader from '../../resources/allocations/components/Loader';
import {
  Allocations,
  Grid,
  Schedule,
  Sidebar,
  useGroups,
  useSchedule,
} from '../../resources/allocations/components/schedule';
import AllocationDetails from '../../resources/allocations/dialogs/AllocationDetails';
import AllocationForm from '../../resources/allocations/dialogs/AllocationForm';
import Cells from '../../resources/allocations/project-schedule/cells/Cells';
import Empty from '../../resources/allocations/project-schedule/sidebar/Empty';
import Group from '../../resources/allocations/project-schedule/sidebar/Group';
import Row from '../../resources/allocations/project-schedule/sidebar/Row';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  // Styles
  margin: 0 -2rem;
`;

const periods = {
  day: 3,
  week: 7,
  month: 11,
};

const merge = (source, target, key) => {
  const sourceObject = _.keyBy(source, key);
  const targetObject = _.keyBy(target, key);
  const merged = _.merge(sourceObject, targetObject);
  const result = _.values(merged);
  return result;
};

export default function ProjectAllocationsTab({ project }) {
  const documentTitle = useDocumentTitle(project.name);
  const readOnly = !project.permissions.manageAllocations;
  const route = useRouteMatch();
  const history = useHistory();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const auth = useAuth();

  const [query, setQuery] = useState({
    status: 'loading',
    action: 'load',
    fetching: false,
    projects: [],
    resources: [],
    allocations: [],
    metrics: [],
  });

  const location = useLocation();

  const [params, setParams] = useState(() => ({
    date: moment().format(dateFormats.isoDate),
    unit: 'week',
  }));

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => (moment(value).isValid() ? moment(value).format(dateFormats.isoDate) : null),
        },
        unit: { default: 'week', valid: ['day', 'week', 'month'] },
      }),
      [],
    ),
    sessionKey: 'project_dashboard_allocations',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const [allocationFormInitialValues, setAllocationFormInitialValues] = useState(null);

  const { start, end } = useMemo(() => {
    let start;
    let end;

    switch (params.unit) {
      case 'day':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(params.date).startOf('month').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate);
        break;
    }

    return { start, end };
  }, [params.date, params.unit]);

  const data = useMemo(() => {
    const allocations = query.allocations.filter(
      (allocation) => moment(allocation.start).isSameOrBefore(end) && moment(allocation.end).isSameOrAfter(start),
    );

    const resourcesById = _.keyBy(query.resources, 'id');
    const allocationsByProject = _.groupBy(allocations, 'projectId');

    let projects = query.projects;

    const rows = [];

    const metricGroups = _.groupBy(query.metrics, (metric) => `${metric.id}_${metric.start}`);

    const periodCount = moment(end).diff(start, params.unit) + 1;

    for (const project of projects) {
      const group = {
        id: project.id,
        type: 'group',
        component: (props) => <Group {...props} />,
        projectId: project.id,
        project,
        hasAllocations: allocationsByProject[project.id]?.length > 0,
        cells: [],
      };
      rows.push(group);

      for (let index = 0; index < periodCount; index++) {
        const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

        const key = `${group.id}_${date}`;

        const metric = metricGroups[key] ? metricGroups[key][0] : {};

        group.cells.push({
          key: `${group.id}_${date}`,
          mode: 'standard',
          type: 'group',
          group,
          manage: project.permissions.manageAllocations,
          date,
          allocated: metric.allocated ?? 0,
        });
      }

      const projectResources = {};

      // Order the allocations by resource type and name
      const projectAllocations = _.orderBy(allocationsByProject[project.id] || [], [
        'resourceTypeId',
        (allocation) => resourcesById[allocation.resourceId].name,
      ]);

      for (const allocation of projectAllocations) {
        if (!projectResources[allocation.resourceId]) {
          projectResources[allocation.resourceId] = {
            id: `${project.id}_${allocation.resourceId}`,
            resourceId: allocation.resourceId,
            type: 'row',
            projectId: project.id,
            parentId: group.id,
            resource: resourcesById[allocation.resourceId],
            allocations: [],
            cells: [],
          };
        }

        projectResources[allocation.resourceId].allocations.push(allocation);
      }

      for (const projectMember of project.members) {
        if (!projectResources[projectMember.memberId]) {
          projectResources[projectMember.memberId] = {
            id: `${project.id}_${projectMember.memberId}`,
            resourceId: projectMember.memberId,
            type: 'row',
            projectId: project.id,
            parentId: group.id,
            resource: resourcesById[projectMember.memberId],
            allocations: [],
            cells: [],
          };
        }
      }

      _.orderBy(projectResources, (pr) => pr.resource.name).forEach((value) => {
        // Add cells for each assignment row
        for (let index = 0; index < periodCount; index++) {
          const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

          value.cells.push({
            key: `${value.id}_${date}`,
            mode: 'row',
            type: 'row',
            group,
            manage: project.permissions.manageAllocations,
            row: value,
            date,
          });
        }

        rows.push(value);
      });
    }

    return { rows };
  }, [query.allocations, query.projects, query.resources, query.metrics, start, end, params.unit]);

  const updateParams = useCallback(
    (params) => {
      setParams((state) => ({ ...state, ...params }));
      searchParams.set(params);
    },
    [searchParams],
  );

  const handleGroupsToggle = useCallback(
    (status) => {
      updateParams({ groups: status });
    },
    [updateParams],
  );

  const { groups, hasExpandedGroups, toggleGroup, toggleGroups } = useGroups({
    rows: data?.rows,
    defaultGroupStatus: 'expanded',
    onGroupsToggle: handleGroupsToggle,
  });

  const canvasRef = useRef();
  const bodyRef = useRef();

  const fetchAllocations = useCallback(async () => {
    setQuery((state) => ({ ...state, fetching: true, action: 'fetch-allocations' }));

    const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().allocations({
      start,
      end,
      unit: params.unit,
    });

    setQuery((state) => ({
      ...state,
      fetching: false,
      action: null,
      allocations: data.allocations,
      projects: data.projects,
      resources: data.resources,
      metrics: data.metrics,
    }));

    return data;
  }, [start, end, params, api, workspace.id, project.id]);

  const fetchMoreAllocations = useCallback(
    async ({ start, end }) => {
      setQuery((state) => ({ ...state, fetching: true }));

      const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().allocations({
        start,
        end,
        unit: params.unit,
      });

      setQuery((state) => ({
        ...state,
        status: 'ready',
        action: 'fetch-more',
        fetching: false,
        allocations: merge(state.allocations, data.allocations, 'id'),
        projects: merge(state.projects, data.projects, 'id'),
        resources: merge(state.resources, data.resources, 'id'),
        metrics: merge(state.metrics, data.metrics, (obj) => `${obj.id}_${obj.start}`),
      }));

      return data;
    },
    [params, api, workspace.id, project.id],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'ready' || !['load', 'refetch'].includes(query.action)) return;

    (async () => {
      await fetchAllocations();
      setQuery((state) => ({ ...state, fetching: false, action: null, status: 'ready' }));
    })();
  }, [searchParamsStatus, query.action, fetchAllocations]);

  const refetch = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch',
      metrics: [],
    }));
  };

  useEffect(() => {
    refetch();
  }, [project]);

  const handleDateChange = (date) => {
    updateParams({ date });
    refetch();
  };

  const handleDateNavPrevious = () => {
    const date = {
      day: moment(params.date).subtract(1, 'week'),
      week: moment(params.date).subtract(1, 'week'),
      month: moment(params.date).subtract(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).startOf('month').format(dateFormats.isoDate),
        end: moment(date).endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleDateNavNext = () => {
    const date = {
      day: moment(params.date).add(1, 'week'),
      week: moment(params.date).add(1, 'week'),
      month: moment(params.date).add(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).add(periods.day, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).add(periods.week, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).add(periods.month, 'months').startOf('month').format(dateFormats.isoDate),
        end: moment(date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleFormClose = () => {
    setAllocationFormInitialValues(null);
    history.push({ pathname: route.url, search: location.search });
    documentTitle.set('Resource Allocations');
  };

  const handleCreateAllocation = () => {
    const unit = { day: 'isoWeek', week: 'isoWeek', month: 'month' }[params.unit];

    setAllocationFormInitialValues({
      start: moment(params.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(params.date).endOf(unit).format(dateFormats.isoDate),
      assignmentTypeId: 'project',
      projectId: project.id,
    });

    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleView = (allocation) => {
    history.push({ pathname: `${route.url}/view/${allocation.id}`, search: location.search });
  };

  const handleEdit = (allocation) => {
    history.push({ pathname: `${route.url}/edit/${allocation.id}`, search: location.search });
  };

  const handleClone = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).clone();
    await fetchAllocations();
    await toast.success('Allocation successfully cloned.');
  };

  const handleSplit = async (allocation, date) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).split({ date });
    await fetchAllocations();
    toast.success('Allocation successfully split.');
  };

  const handleSplitByDay = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByDay();
    await fetchAllocations();
    toast.success('Allocation successfully split by day.');
  };

  const handleSplitByWeek = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByWeek();
    await fetchAllocations();
    toast.success('Allocation successfully split by week.');
  };

  const handleSplitByMonth = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByMonth();
    await fetchAllocations();
    toast.success('Allocation successfully split by month.');
  };

  const handleDelete = async (allocation) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this allocation?</DeleteConfirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).allocations(allocation.id).delete();

    await fetchAllocations();
  };

  const handleSelectCellsEnd = (cells) => {
    const unit = { day: 'day', week: 'isoWeek', month: 'month' }[params.unit];

    const cell = _.first(cells);
    const lastCell = _.last(cells);

    const initialValues = {
      start: moment(cell.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(lastCell.date).endOf(unit).format(dateFormats.isoDate),
      assignmentTypeId: 'project',
      projectId: project.id,
    };

    if (cell.row) {
      const resource = query.resources.find((a) => a.id === cell.row.resource.id);

      if (resource) {
        const resourceType = { member: 'member', placeholder: 'placeholder' }[resource.resourceType];

        initialValues.resourceTypeId = resource.resourceType;
        initialValues[resourceType] = resource;
      }
    }

    setAllocationFormInitialValues(initialValues);
    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleUpdateAllocationDates = useCallback(
    async (allocation) => {
      try {
        const body = _.pick(allocation, [
          'unit',
          'start',
          'end',
          'hoursPerDay',
          'hoursPerWeek',
          'hoursPerMonth',
          'hoursPerAllocation',
          'hoursRatioOfCapacity',
        ]);

        // Optimistic update
        setQuery((state) => ({
          ...state,
          allocations: state.allocations.map((a) => (a.id === allocation.id ? { ...a, ...body } : a)),
        }));

        await api.www.workspaces(workspace.id).allocations(allocation.id).upsert(body);
      } catch (error) {
        toast.error(error.message);
      } finally {
        await fetchAllocations();
      }
    },
    [api, fetchAllocations, toast, workspace.id],
  );

  const handleUnitChange = (unit) => {
    if (unit !== params.unit) {
      setQuery((state) => ({ ...state, status: 'grid_loading' }));
      updateParams({ unit });
      refetch();
    }
  };

  const handleAllocationSaved = async () => {
    await fetchAllocations();
  };

  const components = useMemo(
    () => ({
      sidebar: {
        Empty,
        Group,
        Row,
      },
    }),
    [],
  );

  const {
    virtualRows: rows,
    allocations,
    cells,
    styles,
  } = useSchedule({ data, start, end, unit: params.unit, groups, parentRef: bodyRef });

  const confirmation = useConfirmation();

  const handleExport = async (mimeType) => {
    let filename =
      mimeType === mimeTypes.xlsx
        ? `project_allocations_by_${params.unit}.xlsx`
        : `project_allocations_by_${params.unit}.csv`;

    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .allocations()
          .projectSchedule.export(
            {
              start,
              end,
              unit: params.unit,
              projectId: project.id,
              includeProjectMembers: true,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <Container>
      <ActionBar
        unit={params.unit}
        date={params.date}
        start={start}
        end={end}
        filters={false}
        create={!readOnly}
        disabled={query.fetching}
        onCreateAllocation={handleCreateAllocation}
        onDateChange={handleDateChange}
        onDateNavNext={handleDateNavNext}
        onDateNavPrevious={handleDateNavPrevious}
        onUnitChange={handleUnitChange}
        onExport={handleExport}
      />

      <Schedule>
        {query.fetching && <Loader />}

        <Grid
          canvasRef={canvasRef}
          bodyRef={bodyRef}
          start={start}
          end={end}
          unit={params.unit}
          rows={rows}
          styles={styles}
          loading={query.status !== 'ready'}
          navigation={false}
          Sidebar={() => (
            <Sidebar
              groups={groups}
              toggleGroup={toggleGroup}
              toggleGroups={toggleGroups}
              hasExpandedGroups={hasExpandedGroups}
              styles={styles}
              rows={rows}
              components={components}
              parentRef={bodyRef}
            />
          )}
          onDateChange={handleDateChange}>
          {query.status === 'ready' && rows.length > 0 && (
            <>
              <Cells
                bodyRef={bodyRef}
                styles={styles}
                metric={params.metric}
                cells={cells}
                rows={rows}
                start={start}
                end={end}
                unit={params.unit}
                onSelectEnd={handleSelectCellsEnd}
              />

              <Allocations
                allocations={allocations}
                rows={rows}
                styles={styles}
                canvasRef={canvasRef}
                parentRef={bodyRef}
                onView={handleView}
                onEdit={handleEdit}
                onClone={handleClone}
                onSplit={handleSplit}
                onSplitByDay={handleSplitByDay}
                onSplitByWeek={handleSplitByWeek}
                onSplitByMonth={handleSplitByMonth}
                onDelete={handleDelete}
                onAllocationDatesChange={handleUpdateAllocationDates}
              />
            </>
          )}
        </Grid>
      </Schedule>

      <Switch>
        {auth.allocations.manage && (
          <Route path={[route.path.concat('/new'), route.path.concat('/edit/:allocationId')]}>
            <AllocationForm
              initialValues={allocationFormInitialValues}
              onSaved={handleAllocationSaved}
              onDeleted={fetchAllocations}
              onClose={handleFormClose}
            />
          </Route>
        )}

        <Route path={route.path.concat('/view/:allocationId')}>
          <AllocationDetails onClose={handleFormClose} />
        </Route>

        <Redirect to={route.url.concat(location.search)} />
      </Switch>
    </Container>
  );
}
