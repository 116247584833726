import { Currency, DateTime, Icon, Level, MultilineText, Percent, Table } from '~/components';
import { useDateTimeFormat, useNumberFormat } from '~/hooks';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import DraftStamp from '../assets/invoice-draft-stamp.svg?react';
import PaidStamp from '../assets/invoice-paid-stamp.svg?react';

const Page = styled.div`
  background-color: white;
  padding: 1.875rem 3.125rem;
  border: 1px solid ${colors.grey10};
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};

  @page {
    size: letter;
    margin-top: 1cm 0;
  }

  @media print {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
    border: none;

    .table-box-body {
      display: block;
    }

    .table-box-row {
      page-break-inside: avoid;
    }
  }
`;

const Stamp = styled.div`
  position: absolute;
`;

const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  height: 8.25rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${colors.grey10};
  position: relative;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid ${colors.grey10};
  padding-right: 3.125rem;

  img {
    max-height: 5rem;
  }
`;

const Address = styled.div`
  &:not(:first-child) {
    padding-left: 3.125rem;
  }
`;

const Number = styled.div`
  margin-left: auto;
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.875rem 0;
  border-bottom: 1px solid ${colors.grey10};

  dl {
    display: flex;
    flex: 1;
    margin-top: 0.875rem;

    dt {
      color: ${colors.grey40};
      text-transform: uppercase;
      font-weight: ${weights.black};
      font-size: 0.75rem;
      width: 8rem;
    }

    dd {
      font-size: 0.875rem;
    }
  }
`;

const InfoRight = styled.div`
  text-align: right;

  dd {
    width: 8rem;
  }
`;

const LineItemsSection = styled.section`
  margin: 2.75rem 0;

  h2 {
    font-weight: ${weights.light};
    font-size: 1.375rem;
    margin-bottom: 1.125rem;
  }

  @media print {
    margin-bottom: 0;
  }
`;

const Footer = styled.div`
  display: flex;

  @media print {
    page-break-inside: avoid;
  }
`;

const Totals = styled.div`
  flex: 1;
  justify-content: flex-end;
  padding-right: 1rem;
`;

const Note = styled.div`
  padding: 2rem 2.5rem;
  flex: 1;

  dl {
    display: flex;

    dt {
      color: ${colors.grey40};
      text-transform: uppercase;
      font-weight: ${weights.black};
      font-size: 0.75rem;
      text-align: right;
      width: 2.5rem;
      margin-right: 2.5rem;
    }

    dd {
      font-size: 0.875rem;
    }
  }
`;

const PaymentsSection = styled.div`
  background-color: white;
  padding: 1.875rem 3.125rem;
  border: 1px solid ${colors.grey10};
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  margin-top: 2rem;

  @media print {
    display: none;
  }
`;

const TotalRow = styled(Level)`
  border: none;
  height: 3rem;
`;

const TotalLabel = styled(Level.Item)`
  text-align: right;
`;

const TotalValue = styled(Level.Item)`
  text-align: right;
  flex: 0;
  flex-basis: 8rem;
`;

const Separator = styled.hr`
  width: 20rem;
  margin-left: auto;
`;

const P = styled.p`
  white-space: pre-wrap;
`;

function InvoicePreview({ invoice }) {
  const {
    client,
    currency,
    billTo,
    invoiceFor,
    number,
    issuedOn,
    servicesThrough,
    paymentTermsId,
    dueOn,
    poNumber,
    discountAmount,
    notes,
    workspace,
    companyName,
    companyStreetAddress,
    payments,
    paymentsTotal,
    creditNotes,
    creditNotesTotal,
    lines,
    taxRate,
    nonTaxableSubtotal,
    taxableSubtotal,
    tax,
    total,
    balance,
    displayColumns,
  } = invoice;

  const isDraft = invoice.status.id === 'draft';
  const locale = client.locale ?? workspace.locale;

  const dateTimeFormat = useDateTimeFormat({ locale });
  const quantityFormat = useNumberFormat({ locale, minimumFractionDigits: 0 });

  const columns = useMemo(() => {
    const column = (name) => displayColumns.includes(name);

    return {
      item: {
        isVisible: column('item'),
        width: !column('details') ? undefined : '15rem',
      },
      details: { isVisible: column('details'), width: undefined },
      tax: {
        isVisible: column('tax'),
        width: !(column('details') || column('item')) ? undefined : '3rem',
      },
      quantity: {
        isVisible: column('quantity'),
        width: !(column('details') || column('item') || column('tax')) ? undefined : '5.5rem',
      },
      rate: {
        isVisible: column('rate'),
        width: !(column('details') || column('item') || column('tax') || column('quantity')) ? undefined : '8.5rem',
      },
      amount: { width: displayColumns.length === 0 ? undefined : '8rem' },
    };
  }, [displayColumns]);

  return (
    <>
      <Page>
        <InvoiceHeader>
          {workspace.includeInvoiceLogo &&
            (() => {
              if (invoice.client.invoiceDetailsSource === 'business_unit') {
                if (invoice.client.invoiceImageUrl) {
                  return (
                    <Logo>
                      <img src={invoice.client.invoiceImageUrl} alt="logo" />
                    </Logo>
                  );
                }
              } else if (workspace.invoiceImageUrl || workspace.imageUrl) {
                return (
                  <Logo>
                    <img src={workspace.invoiceImageUrl ?? workspace.imageUrl} alt="logo" />
                  </Logo>
                );
              }
            })()}
          <Address>
            {companyName}
            <br />
            <MultilineText value={companyStreetAddress} align="left" />
          </Address>
          <Number>Invoice # {number}</Number>
          {
            {
              draft: (
                <Stamp style={{ bottom: '-3.5rem', left: 'calc(50% - 177.03px / 2)' }}>
                  <DraftStamp />
                </Stamp>
              ),
              paid: (
                <Stamp style={{ bottom: '-2.25rem', left: 'calc(50% - 121.388px / 2)' }}>
                  <PaidStamp />
                </Stamp>
              ),
            }[invoice.status.id]
          }
        </InvoiceHeader>
        <InfoSection>
          <div>
            <dl>
              <dt>Bill to</dt>
              <dd>
                <MultilineText value={billTo} />
              </dd>
            </dl>
            {invoiceFor && (
              <dl>
                <dt>Subject</dt>
                <dd>{invoiceFor}</dd>
              </dl>
            )}
          </div>
          <InfoRight>
            <dl>
              <dt>Total Invoiced</dt>
              <dd>
                <Currency value={total} currency={currency} locale={locale} />
              </dd>
            </dl>
            <dl>
              <dt>Issued On</dt>
              <dd>
                <DateTime value={issuedOn} locale={locale} />
              </dd>
            </dl>
            {servicesThrough && (
              <>
                <dl>
                  <dt>Services Through</dt>
                  <dd>
                    <DateTime value={servicesThrough} locale={locale} />
                  </dd>
                </dl>
              </>
            )}
            <dl>
              <dt>Payment Due By</dt>
              <dd>{paymentTermsId === 'due_on_receipt' ? 'On Receipt' : <DateTime value={dueOn} locale={locale} />}</dd>
            </dl>
            {poNumber && (
              <dl>
                <dt>Purchase Order #</dt>
                <dd>{poNumber}</dd>
              </dl>
            )}
          </InfoRight>
        </InfoSection>

        <LineItemsSection>
          <Table>
            <Table.BoxHeader style={{ background: colors.black, color: colors.white }}>
              <Table.Column isVisible={columns.item.isVisible} width={columns.item.width}>
                Item
              </Table.Column>
              <Table.Column isVisible={columns.details.isVisible} width={columns.details.width}>
                Details
              </Table.Column>
              <Table.Column isVisible={columns.tax.isVisible} align={columns.tax.align} width={columns.tax.width}>
                TAX
              </Table.Column>
              <Table.Column isVisible={columns.quantity.isVisible} align="right" width={columns.quantity.width}>
                QTY
              </Table.Column>
              <Table.Column isVisible={columns.rate.isVisible} align="right" width={columns.rate.width}>
                Rate
              </Table.Column>
              <Table.Column align="right" width={columns.amount.width}>
                Amount
              </Table.Column>
            </Table.BoxHeader>
            <Table.Body className="table-box-body">
              {lines.map((line) => {
                const item = isDraft ? line.invoiceItem?.name : line.item;

                return (
                  <Table.BoxRow key={line.id} className="table-box-row">
                    <Table.Cell>{item}</Table.Cell>

                    <Table.Cell>
                      <div>
                        <P>{line.description}</P>
                      </div>
                    </Table.Cell>

                    <Table.Cell align="center">{line.taxable && <Icon icon="check" />}</Table.Cell>

                    <Table.Cell align="right">{line.quantity ? quantityFormat.format(line.quantity) : ''}</Table.Cell>

                    <Table.Cell align="right">
                      <Currency
                        value={line.rate}
                        useDash={false}
                        maximumFractionDigits={7}
                        currency={currency}
                        locale={locale}
                      />
                    </Table.Cell>

                    <Table.Cell align="right">
                      <Currency value={line.amount} useDash={false} currency={currency} locale={locale} />
                    </Table.Cell>
                  </Table.BoxRow>
                );
              })}
            </Table.Body>
          </Table>

          <Footer>
            {notes && (
              <Note>
                <dl>
                  <dt>Note</dt>
                  <dd>
                    <P>{notes}</P>
                  </dd>
                </dl>
              </Note>
            )}

            <Totals>
              {tax > 0 && (
                <TotalRow>
                  <TotalLabel>Subtotal</TotalLabel>
                  <TotalValue>
                    <Currency value={_.sumBy(lines, 'amount')} currency={currency} locale={locale} />
                  </TotalValue>
                </TotalRow>
              )}

              {discountAmount > 0 && (
                <TotalRow>
                  <TotalLabel>Discount</TotalLabel>
                  <TotalValue>
                    <Currency value={discountAmount * -1} currency={currency} locale={locale} />
                  </TotalValue>
                </TotalRow>
              )}

              {tax > 0 && (
                <>
                  <Separator />

                  <TotalRow>
                    <TotalLabel>Non-Taxable Subtotal</TotalLabel>
                    <TotalValue>
                      <Currency value={nonTaxableSubtotal} currency={currency} locale={locale} />
                    </TotalValue>
                  </TotalRow>

                  <TotalRow>
                    <TotalLabel>Taxable Subtotal</TotalLabel>
                    <TotalValue>
                      <Currency value={taxableSubtotal} currency={currency} locale={locale} />
                    </TotalValue>
                  </TotalRow>

                  <TotalRow>
                    <TotalLabel>
                      Tax{' '}
                      {taxRate !== null && (
                        <Percent
                          value={invoice.taxRate / 100}
                          locale={locale}
                          minimumFractionDigits={0}
                          maximumFractionDigits={7}
                        />
                      )}
                    </TotalLabel>
                    <TotalValue>
                      <Currency value={tax} currency={currency} locale={locale} />
                    </TotalValue>
                  </TotalRow>

                  <Separator />
                </>
              )}

              <TotalRow style={{ fontWeight: weights.bold }}>
                <TotalLabel>Total Invoiced</TotalLabel>
                <TotalValue>
                  <Currency value={total} currency={currency} locale={locale} />
                </TotalValue>
              </TotalRow>
              {creditNotesTotal > 0 && (
                <TotalRow style={{ fontWeight: weights.bold }}>
                  <TotalLabel>Amount Credited</TotalLabel>
                  <TotalValue>
                    <Currency value={creditNotesTotal} currency={currency} locale={locale} />
                  </TotalValue>
                </TotalRow>
              )}
              {paymentsTotal > 0 && (
                <TotalRow style={{ fontWeight: weights.bold }}>
                  <TotalLabel>Amount Received</TotalLabel>
                  <TotalValue>
                    <Currency value={paymentsTotal} currency={currency} locale={locale} />
                  </TotalValue>
                </TotalRow>
              )}
              <TotalRow style={{ fontWeight: weights.bold }}>
                <TotalLabel>Balance Due</TotalLabel>
                <TotalValue>
                  <Currency value={balance} currency={currency} locale={locale} />
                </TotalValue>
              </TotalRow>
            </Totals>
          </Footer>
        </LineItemsSection>
      </Page>

      {creditNotes?.length > 0 && (
        <PaymentsSection>
          <LineItemsSection>
            <h2>Credit Notes</h2>
            <Table>
              <Table.BoxHeader style={{ background: colors.black, color: colors.white }}>
                <Table.Column>Details</Table.Column>
                <Table.Column align="right" width="10rem">
                  Amount
                </Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {creditNotes.map((cni, index) => {
                  const details = _.compact([
                    dateTimeFormat.format(cni.creditNote.issuedOn),
                    `#${cni.creditNote.number}`,
                  ]).join(' - ');

                  return (
                    <Table.BoxRow key={index}>
                      <Table.Cell>{details}</Table.Cell>
                      <Table.Cell>
                        <Currency value={cni.amount} currency={currency} locale={locale} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
                <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
                  <Table.Cell>Total</Table.Cell>
                  <Table.Cell>
                    <Currency value={_.sumBy(creditNotes, 'amount')} currency={currency} locale={locale} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </LineItemsSection>
        </PaymentsSection>
      )}

      {payments?.length > 0 && (
        <PaymentsSection>
          <LineItemsSection>
            <h2>Payments</h2>
            <Table>
              <Table.BoxHeader style={{ background: colors.black, color: colors.white }}>
                <Table.Column>Details</Table.Column>
                <Table.Column align="right" width="10rem">
                  Amount
                </Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {payments.map((pi, index) => {
                  const details = _.compact([
                    dateTimeFormat.format(pi.payment.receivedOn),
                    pi.payment.paymentMethod?.name,
                    pi.payment.referenceNumber ? `#${pi.payment.referenceNumber}` : null,
                  ]).join(' - ');

                  return (
                    <Table.BoxRow key={index}>
                      <Table.Cell>{details}</Table.Cell>
                      <Table.Cell>
                        <Currency value={pi.amount} currency={currency} locale={locale} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
                <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
                  <Table.Cell>Total</Table.Cell>
                  <Table.Cell>
                    <Currency value={_.sumBy(payments, 'amount')} currency={currency} locale={locale} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </LineItemsSection>
        </PaymentsSection>
      )}
    </>
  );
}

export default InvoicePreview;
