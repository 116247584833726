import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, DateTime, Hours, Icon, Percent, PeriodFilter, SingleSelect, Tooltip } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import MemberFiltersBar from '../components/MemberFiltersBar';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import projectStatuses from '~/lookups/project-statuses.js';
import Report from '../components/Report';
import Table from '../components/table';
import { intervals, intervalsByScope } from './intervals';

export default function UtilizationPlanByMemberPractice() {
  useDocumentTitle('Utilization Plan by Member Practice');
  const { workspace } = useWorkspace();
  const api = useApi();

  const searchParamsConfig = useSearchParamsConfig();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
    projectStatuses: {
      ...searchParamsConfig.projectStatuses,
      default: [projectStatuses.not_started, projectStatuses.in_progress],
    },
  }));
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    unit: '',

    // Member Filters
    memberPractices: [],
    memberDisciplines: [],
    memberStatusId: '',

    metric: 'billable',

    // Member Filters
    ...memberFilters.filters,

    // Client Filters
    ...clientFilters.filters,

    // Project filters
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervals.next_6_months,
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['day', 'week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'day':
                  return intervals.next_30_days;

                case 'week':
                  return intervals.next_12_weeks;

                case 'month':
                  return intervals.next_6_months;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },

        // Member Filters
        memberPractices: searchParamsConfig.practices,
        memberDisciplines: searchParamsConfig.disciplines,
        memberStatusId: { ...searchParamsConfig.memberStatus, default: 'active' },

        // Member Filters
        ...memberFilters.searchParamsConfig,

        // Client Filters
        ...clientFilters.searchParamsConfig,

        // Project Filters
        ...projectFilters.searchParamsConfig,

        metric: { default: 'billable', valid: ['billable', 'client', 'productive', 'total'] },
      }),
      [searchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,

      // Member Filters
      ...memberFilters.mapUrlSearchParams(params),

      // Client Filters
      ...clientFilters.mapUrlSearchParams(params),

      // Project Filters
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().utilizationPlanByMemberPractice(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const handleMetricChange = (metric) => {
    setParams({ ...params, metric });
    searchParams.set({ metric });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .utilizationPlanByMemberPractice(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/plan-and-forecast`} />

        <Report.Info>
          <Report.Eyebrow>Plan & Forecast Reports</Report.Eyebrow>
          <Report.Title>Utilization Plan by Member Practice</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter
          value={params.period}
          intervals={intervalsByScope[params.unit]}
          onChange={(period) => handleApplyFilters({ period })}
        />

        <SingleSelect
          materialPlaceholder="Unit"
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>

        <MetricFilter value={params.metric} onChange={({ value }) => handleMetricChange(value)} />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

const NoMemberPractice = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey25};
`;

function Data({ query, params }) {
  const report = query.data;

  const { memberPractices, records, periods } = useMemo(() => {
    const memberPractices = _.orderBy(report.memberPractices, (r) => r.name?.toLowerCase());

    const records = report.records.reduce(
      (a, v) => {
        a.total.capacity += v.capacity || 0;
        a.total.billableHours += v.billableHours || 0;
        a.total.clientHours += v.clientHours || 0;
        a.total.productiveHours += v.productiveHours || 0;
        a.total.totalHours += v.totalHours || 0;

        // Members
        a.memberPractices[v.practiceId] = a.memberPractices[v.practiceId] || {
          total: {
            capacity: 0,
            billableHours: 0,
            clientHours: 0,
            productiveHours: 0,
            totalHours: 0,
            get billableUtilization() {
              if (!this.capacity) return null;
              return this.billableHours / this.capacity;
            },
            get clientUtilization() {
              if (!this.capacity) return null;
              return this.clientHours / this.capacity;
            },
            get productiveUtilization() {
              if (!this.capacity) return null;
              return this.productiveHours / this.capacity;
            },
            get totalUtilization() {
              if (!this.capacity) return null;
              return this.totalHours / this.capacity;
            },
          },
          periods: {},
        };
        a.memberPractices[v.practiceId].total.capacity += v.capacity || 0;
        a.memberPractices[v.practiceId].total.billableHours += v.billableHours || 0;
        a.memberPractices[v.practiceId].total.clientHours += v.clientHours || 0;
        a.memberPractices[v.practiceId].total.productiveHours += v.productiveHours || 0;
        a.memberPractices[v.practiceId].total.totalHours += v.totalHours || 0;
        if (!a.memberPractices[v.practiceId].periods[v.start])
          a.memberPractices[v.practiceId].periods[v.start] = {
            capacity: 0,
            billableHours: 0,
            clientHours: 0,
            productiveHours: 0,
            totalHours: 0,
            get billableUtilization() {
              if (!this.capacity) return null;
              return this.billableHours / this.capacity;
            },
            get clientUtilization() {
              if (!this.capacity) return null;
              return this.clientHours / this.capacity;
            },
            get productiveUtilization() {
              if (!this.capacity) return null;
              return this.productiveHours / this.capacity;
            },
            get totalUtilization() {
              if (!this.capacity) return null;
              return this.totalHours / this.capacity;
            },
          };
        a.memberPractices[v.practiceId].periods[v.start].capacity += v.capacity || 0;
        a.memberPractices[v.practiceId].periods[v.start].billableHours += v.billableHours || 0;
        a.memberPractices[v.practiceId].periods[v.start].clientHours += v.clientHours || 0;
        a.memberPractices[v.practiceId].periods[v.start].productiveHours += v.productiveHours || 0;
        a.memberPractices[v.practiceId].periods[v.start].totalHours += v.totalHours || 0;

        // Periods
        if (!a.periods[v.start])
          a.periods[v.start] = {
            capacity: 0,
            billableHours: 0,
            clientHours: 0,
            productiveHours: 0,
            totalHours: 0,
            get billableUtilization() {
              if (!this.capacity) return null;
              return this.billableHours / this.capacity;
            },
            get clientUtilization() {
              if (!this.capacity) return null;
              return this.clientHours / this.capacity;
            },
            get productiveUtilization() {
              if (!this.capacity) return null;
              return this.productiveHours / this.capacity;
            },
            get totalUtilization() {
              if (!this.capacity) return null;
              return this.totalHours / this.capacity;
            },
          };
        a.periods[v.start].capacity += v.capacity || 0;
        a.periods[v.start].billableHours += v.billableHours || 0;
        a.periods[v.start].clientHours += v.clientHours || 0;
        a.periods[v.start].productiveHours += v.productiveHours || 0;
        a.periods[v.start].totalHours += v.totalHours || 0;

        return a;
      },
      {
        memberPractices: {},
        periods: {},
        total: {
          capacity: 0,
          billableHours: 0,
          clientHours: 0,
          productiveHours: 0,
          totalHours: 0,
          get billableUtilization() {
            if (!this.capacity) return null;
            return this.billableHours / this.capacity;
          },
          get clientUtilization() {
            if (!this.capacity) return null;
            return this.clientHours / this.capacity;
          },
          get productiveUtilization() {
            if (!this.capacity) return null;
            return this.productiveHours / this.capacity;
          },
          get totalUtilization() {
            if (!this.capacity) return null;
            return this.totalHours / this.capacity;
          },
        },
      },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { memberPractices, records, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  const { metric } = params;

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Member Practice" value={memberPractices.length} />
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem">
            Member Practice
          </Table.Column>

          {periods.map((p) => (
            <Table.Column key={p} align="right" width="7rem">
              <DateTime value={p} />
            </Table.Column>
          ))}

          <Table.Column align="right" width="7rem">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body>
          {memberPractices.map((practice) => {
            return (
              <Table.Row key={practice.id || 'no_member_practice'}>
                <Table.Cell>
                  {practice.id ? practice.name : <NoMemberPractice>No Member Practice</NoMemberPractice>}
                </Table.Cell>

                {periods.map((period) => {
                  const value = records.memberPractices[practice.id]?.periods[period];

                  return (
                    <Table.Cell key={period}>
                      <CellValue value={value} metric={metric} />
                    </Table.Cell>
                  );
                })}

                <Table.Cell>
                  <CellValue value={records.memberPractices[practice.id]?.total} metric={metric} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            {periods.map((period) => {
              const value = records.periods[period];

              return (
                <Table.Cell key={period}>
                  <CellValue value={value} metric={metric} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <CellValue value={records.total} metric={metric} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const TooltipContainer = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const TooltipLabel = styled.div`
  width: 11.5rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const TooltipValue = styled.div`
  white-space: wrap;
`;

function TooltipHours({ value }) {
  return (
    <>
      <Hours value={value} /> {_.isNumber(value) && pluralize('hours', value)}
    </>
  );
}

function CellValue({ value, metric }) {
  if (!value) return <Percent value={null} />;

  return (
    <Tooltip
      maxWidth="18rem"
      message={
        <TooltipContainer>
          <TooltipInfo>
            <TooltipLabel>Capacity</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.capacity} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Billable Hours</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.billableHours} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Billable Utilization</TooltipLabel>
            <TooltipValue>
              <Percent value={value.billableUtilization} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Client Hours</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.clientHours} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Client Utilization</TooltipLabel>
            <TooltipValue>
              <Percent value={value.clientUtilization} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Productive Hours</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.productiveHours} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Productive Utilization</TooltipLabel>
            <TooltipValue>
              <Percent value={value.productiveUtilization} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Total Hours</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.totalHours} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Total Utilization</TooltipLabel>
            <TooltipValue>
              <Percent value={value.totalUtilization} />
            </TooltipValue>
          </TooltipInfo>
        </TooltipContainer>
      }>
      <Percent
        value={
          {
            billable: value.billableUtilization,
            client: value.clientUtilization,
            productive: value.productiveUtilization,
            total: value.totalUtilization,
          }[metric || 'billable']
        }
      />
    </Tooltip>
  );
}

function MetricFilter({ value, onChange }) {
  const options = [
    { value: 'billable', label: 'Billable Utilization' },
    { value: 'client', label: 'Client Utilization' },
    { value: 'productive', label: 'Productive Utilization' },
    { value: 'total', label: 'Total Utilization' },
  ];

  return (
    <SingleSelect materialPlaceholder="Metric" materialAlwaysVisible value={value} onChange={onChange}>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </SingleSelect>
  );
}
