import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '~/components';
import { colors, weights } from '~/styles';
import { useListView } from './ListViewContext';

const alignToJustify = (align) => {
  switch (align) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

const Column = styled.div`
  flex: ${({ width }) => (width ? `0 0 ${width}` : '1')};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => alignToJustify(align)};
  min-width: ${({ minWidth, width }) => minWidth ?? width ?? 'auto'};
  padding: ${({ padding }) => (padding ? padding : '0.9375rem 1rem')};
  text-align: ${({ align }) => align};

  ${({ sticky }) =>
    !!sticky &&
    css`
      position: sticky;
      left: -2rem;
      background: linear-gradient(to right, ${colors.grey5} 90%, transparent 100%);
    `}
`;

const SortButton = styled.div`
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${({ isAnchor }) =>
    !!isAnchor &&
    css`
      cursor: pointer;

      &:hover {
        color: ${colors.grey55};
      }
    `}

  .icon {
    margin-left: 0.25rem;
  }
`;

function ListViewColumn(props) {
  const { setColumn } = useListView();
  let {
    name,
    width,
    height,
    fontSize,
    align = 'left',
    isVisible = true,
    onSort,
    sort,
    padding,
    index,
    children,
    sticky,
    actions,
    style,
    minWidth,
    ...elementProps
  } = props;

  if (actions) width = 'calc(3.25rem + 1px)';

  useEffect(() => {
    setColumn({ index, name, width, height, fontSize, align, padding, isVisible, sticky, actions, style, minWidth });
  }, [setColumn, index, name, width, height, fontSize, align, padding, isVisible, sticky, actions, style, minWidth]);

  if (!isVisible) return null;
  return (
    <Column
      {...elementProps}
      padding={padding}
      width={width}
      align={align}
      sticky={sticky}
      actions={actions}
      minWidth={minWidth}
      style={style}>
      {onSort ? (
        <SortButton isAnchor={true} onClick={() => onSort({ column: name, sort })}>
          {children}
          {sort?.column === name
            ? {
                asc: <Icon icon="caret-up" />,
                desc: <Icon icon="caret-down" />,
              }[sort?.direction || 'asc']
            : null}
        </SortButton>
      ) : sort ? (
        <SortButton>
          {children}
          {sort.column === name
            ? {
                asc: <Icon icon="caret-up" />,
                desc: <Icon icon="caret-down" />,
              }[sort.direction || 'asc']
            : null}
        </SortButton>
      ) : (
        children
      )}
    </Column>
  );
}

export { alignToJustify };
export default ListViewColumn;
