import { useApi, useWorkspace } from '~/contexts';
import _ from 'lodash';
import allocationBillableTypes from '~/lookups/allocation-billable-types';
import approvalStatuses from '~/lookups/approval-statuses';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import employmentTypes from '~/lookups/employment-types';
import projectBillingTypes from '~/lookups/project-billing-types';
import projectStatuses from '~/lookups/project-statuses';
import projectTaskStatuses from '~/lookups/project-task-statuses';
import timeBillableTypes from '~/lookups/time-billable-types';
import { memberInvitationStatuses, projectHealthRatings } from '~/lookups';
import { useMemo } from 'react';
import { dateFormats, QuerySort } from '~/utils';
import useFeatures from './useFeatures';
import moment from 'moment';

export default function useSearchParamsConfig() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const config = useMemo(
    () => ({
      allocationBillableType: {
        valid: ['billable', 'non_billable', 'internal', 'time_off'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      allocationBillableTypes: {
        multi: true,
        key: 'allocationBillableType',
        valid: ['all', ..._.map(allocationBillableTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && !value.length ? 'all' : value?.map((v) => v.id);
        },
        deserialize: (ids) =>
          ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => allocationBillableTypes[id]),
      },

      approvalsPeriod: {
        default: {
          // Set start to the first day of the month two months prior
          start: moment().subtract(2, 'months').startOf('month').format(dateFormats.isoDate),

          // Set end to the last day of the next month
          end: moment().add(1, 'months').endOf('month').format(dateFormats.isoDate),
        },
        keys: ['start', 'end'],
        serialize: (value) => ({ start: value.start ?? '', end: value.end ?? '' }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('start');
          let end = urlSearchParams.get('end');

          let startDate = start && moment(start, 'YYYY-MM-DD').isValid() ? moment(start, 'YYYY-MM-DD') : null;
          let endDate = end && moment(end, 'YYYY-MM-DD').isValid() ? moment(end, 'YYYY-MM-DD') : null;

          if (startDate && (!endDate || endDate.isBefore(startDate) || endDate.diff(startDate, 'days') > 180)) {
            endDate = startDate.clone().add(180, 'days');
          }

          if (!startDate && endDate) {
            startDate = endDate.clone().subtract(180, 'days');
          }

          // If both start and end dates are invalid or null, apply default logic
          if (!startDate && !endDate) {
            return {
              start: moment().subtract(2, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(1, 'months').endOf('month').format(dateFormats.isoDate),
            };
          }

          return {
            start: startDate ? startDate.format('YYYY-MM-DD') : null,
            end: endDate ? endDate.format('YYYY-MM-DD') : null,
          };
        },
      },

      approvalStatus: { valid: _.map(approvalStatuses, (v) => v.id) },

      approvalStatuses: {
        multi: true,
        key: 'status',
        valid: _.map(approvalStatuses, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => approvalStatuses[id]),
      },

      businessUnits: {
        multi: true,
        valid: !features.businessUnits ? [] : undefined,
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) =>
          ids.length === 1 && ids[0] === 'all'
            ? []
            : api.www
                .workspaces(workspace.id)
                .businessUnits()
                .get({ ids })
                .then(({ data }) => data),
      },

      boolean: {
        default: false,
        serialize: (value) => (value === true ? 'true' : 'false'),
        deserialize: (value) => value === 'true',
      },

      company: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .companies()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      client: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .clients()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      clients: {
        multi: true,
        key: 'client',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .clients()
            .get({ ids })
            .then(({ data }) => data),
      },

      clientApprovalStatuses: {
        multi: true,
        key: 'clientStatus',
        valid: features.clientApprovals ? _.map(clientApprovalStatuses, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => clientApprovalStatuses[id]),
      },

      clientTags: {
        multi: true,
        key: 'clientTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .clientTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      currency: {
        default: workspace.currency,
        deserialize: (code) =>
          api
            .currencies()
            .get({ code })
            .then(({ data }) => data[0]?.code),
      },

      disciplines: {
        multi: true,
        valid: !features.disciplines ? [] : undefined,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .disciplines()
            .get({ ids })
            .then(({ data }) => data),
      },

      employmentType: { valid: _.map(employmentTypes, (v) => v.id) },

      employmentTypes: {
        multi: true,
        key: 'employmentType',
        valid: ['all', ..._.map(employmentTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => employmentTypes[id])),
      },

      expenseCategories: {
        multi: true,
        key: 'expenseCategory',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .expenseCategories()
            .get({ ids })
            .then(({ data }) => data),
      },

      expenseType: { valid: ['member', 'project'] },

      expenseBillableType: { valid: ['billable', 'non_billable'] },

      expenseReimbursableType: { valid: ['reimbursable', 'non_reimbursable'] },

      industries: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .industries()
            .get({ ids })
            .then(({ data }) => data),
      },

      invoiced: { valid: ['yes', 'no'] },

      isReimbursed: { valid: ['yes', 'no'] },

      jobTitles: {
        multi: true,
        key: 'jobTitle',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .jobTitles()
            .get({ ids })
            .then(({ data }) => data),
      },

      locations: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .locations()
            .get({ ids })
            .then(({ data }) => data),
      },

      member: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .members()
                .options({ ids })
                .then(({ data }) => data[0]),
      },

      members: {
        multi: true,
        key: 'member',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .members()
            .options({ ids })
            .then(({ data }) => data),
      },

      excludeMembers: {
        multi: true,
        key: 'x_member',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .members()
            .options({ ids })
            .then(({ data }) => data),
      },

      memberStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      projectTeamStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      memberInvitationStatus: { valid: _.map(memberInvitationStatuses, (v) => v.id) },

      memberInvitationStatuses: {
        multi: true,
        key: 'memberInvitationStatus',
        valid: _.map(memberInvitationStatuses, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => memberInvitationStatuses[id]),
      },

      memberBillableType: { valid: ['billable', 'non_billable'] },

      memberCostMethod: { valid: ['fixed', 'hourly'] },

      skills: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .skills()
            .get({ ids })
            .then(({ data }) => data),
      },

      memberTags: {
        multi: true,
        key: 'memberTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .memberTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      opportunityStages: {
        multi: true,
        key: 'opportunityStage',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => {
          return api.www
            .workspaces(workspace.id)
            .opportunityStages()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      opportunityType: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .opportunityTypes()
            .get({ opportunityTypeId: ids })
            .then(({ data }) => data[0]),
      },

      opportunityTags: {
        multi: true,
        key: 'opportunityTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .opportunityTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      paymentMethod: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .paymentMethods()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      paymentMethods: {
        multi: true,
        key: 'paymentMethods',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .paymentMethods()
            .get({ ids })
            .then(({ data }) => data),
      },

      practice: {
        valid: !features.practices ? [] : undefined,
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .practices()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      practices: {
        multi: true,
        valid: !features.practices ? [] : undefined,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .practices()
            .get({ ids })
            .then(({ data }) => data),
      },

      project: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .projects()
                .selectOptions({ ids, activeOnly: false })
                .then(({ data }) => data[0]),
      },

      projects: {
        multi: true,
        key: 'project',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projects()
            .selectOptions({ ids, activeOnly: false })
            .then(({ data }) => data),
      },

      projectBillingType: {
        valid: ['all', 'tm', 'fixed', 'fixed_recurring', 'non_billable'],
        serialize: (value) => value || 'all',
        deserialize: (value) => (value === 'all' ? null : value),
      },

      projectBillingTypes: {
        multi: true,
        key: 'projectBillingType',
        valid: ['all', ..._.map(projectBillingTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectBillingTypes[id])),
      },

      projectBudgetHealth: {
        multi: true,
        key: 'projectBudgetHealth',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectScheduleHealth: {
        multi: true,
        key: 'projectScheduleHealth',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectClientSatisfaction: {
        multi: true,
        key: 'projectClientSatisfaction',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectTeamSatisfaction: {
        multi: true,
        key: 'projectTeamSatisfaction',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectRoles: {
        multi: true,
        key: 'projectRole',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids, urlSearchParams) => {
          const projectId = urlSearchParams.get('project');

          if (!projectId) {
            urlSearchParams.delete('projectRole');
            return null;
          }

          return api.www
            .workspaces(workspace.id)
            .projects(projectId)
            .roles()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      projectTasks: {
        multi: true,
        key: 'projectTask',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids, urlSearchParams) => {
          const projectId = urlSearchParams.get('project');

          if (!projectId) {
            urlSearchParams.delete('projectTask');
            return null;
          }

          return api.www
            .workspaces(workspace.id)
            .projects(projectId)
            .tasks()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      projectStatus: { valid: _.map(projectStatuses, (v) => v.id) },

      projectStatuses: {
        multi: true,
        key: 'projectStatus',
        valid: ['all', ..._.map(projectStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectStatuses[id])),
      },

      projectTaskStatuses: {
        multi: true,
        key: 'projectTaskStatus',
        valid: ['all', ..._.map(projectTaskStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectTaskStatuses[id])),
      },

      projectTypes: {
        multi: true,
        key: 'projectType',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTypes()
            .get({ ids })
            .then(({ data }) => data),
      },

      recordStatusId: {
        valid: ['all', 'active', 'archived'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      resourceStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      securityRole: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .securityRoles()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      securityRoles: {
        multi: true,
        key: 'securityRole',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .securityRoles()
            .get({ ids })
            .then(({ data }) => data),
      },

      sort: {
        deserialize: (value) => {
          const parts = value.split(':');
          const column = parts.slice(0, parts.length - 1).join(':');
          const direction = parts[parts.length - 1];

          return new QuerySort(column, direction);
        },
      },

      projectTags: {
        multi: true,
        key: 'projectTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      projectTaskTags: {
        multi: true,
        key: 'projectTaskTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTaskTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      timeBillableType: { valid: ['billable', 'non_billable', 'internal', 'time_off'] },

      timeBillableTypes: {
        multi: true,
        key: 'billableType',
        valid: ['all', ..._.map(timeBillableTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => timeBillableTypes[id])),
      },

      timeOffTypes: {
        multi: true,
        key: 'timeOffType',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => {
          return api.www
            .workspaces(workspace.id)
            .timeOffTypes()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      varianceBillableTypes: {
        multi: true,
        key: 'varianceBillableType',
        valid: _.map(timeBillableTypes, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => timeBillableTypes[id]),
      },
    }),
    [api, workspace.id, features, workspace.currency],
  );

  return config;
}
