import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionButton,
  ButtonBadge,
  ClientProjectSelect,
  FiltersBar,
  Level,
  MemberSelect,
  Page,
  PracticeSelect,
  SingleSelect,
  SplitButton,
  YesNoFilter,
} from '~/components';
import ActionsBanner from '~/components/ActionsBanner.jsx';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import {
  useActions,
  useAuth,
  useDocumentTitle,
  useFeatures,
  useForm,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import EditTimeEntry from '../edit-time-entry';
import ViewTimeEntry from '../view-time-entry';
import ApprovalsPeriodFilter from './ApprovalsPeriodFilter.jsx';
import RejectTimeDialog from './RejectTimeDialog';
import SetToApprovedConfirmation from './SetToApprovedConfirmation';
import SetToRejectedConfirmation from './SetToRejectedConfirmation';
import TimeApprovalResults from './TimeApprovalResults';

const handlers = {
  load: () => ({ action: 'load' }),
  refetch: () => ({ action: 'refetch' }),
  ready: ({ data }) => ({
    isReady: true,
    action: null,
    data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params },
    searchParamsStatus: 'ready',
  }),
  updateItems: (items, { data, query }) => ({
    data: data
      .map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        item = _.pick(
          item,
          'date',
          'isActuallyBillable',
          'minutes',
          'notes',
          'project',
          'role',
          'statusId',
          'task',
          'timeOffType',
          'timerStartedAt',
        );

        return item ? { ...result, ...item } : result;
      })

      // Remove items from the queue if the status doesn't match the filter. This is mostly to
      // clear the queue when an approval action is taken.
      // This may eventually require a refetch, to exclude items based on other properties
      // (which may have changed when using the time entry drawer).
      .filter((result) => !query.statusId || result.statusId === query.statusId),
  }),
  removeItem: (id, { data }) => ({
    data: data.filter((i) => i.id !== id),
  }),
};

function TimeApprovalsPage() {
  const documentTitle = useDocumentTitle('Time Approvals');

  const { workspace } = useWorkspace();
  const features = useFeatures();
  const auth = useAuth();

  const initialState = useMemo(
    () => ({
      isReady: false,
      searchParamsStatus: 'pending',
      data: null,
      query: {
        period: {
          start: null,
          end: null,
        },
        project: null,
        billableTypeId: null,
        member: null,
        memberPractice: null,
        approver: workspace.member,
        includeLockedItems: 'no',
      },
      action: 'load',
    }),
    [workspace.member],
  );

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [selection, setSelection] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [{ isSubmitting, saved }, form] = useForm();
  const [drawer, setDrawer] = useState(null);
  const api = useApi();
  const toast = useToast();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: searchParamsConfig.approvalsPeriod,
        billableTypeId: searchParamsConfig.timeBillableType,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        project: searchParamsConfig.project,
        member: searchParamsConfig.member,
        memberPractice: searchParamsConfig.practice,
        approver: {
          default: initialState.query.approver,
          serialize: (value) => value?.id ?? 'all',
          deserialize: auth.workspace.manage
            ? searchParamsConfig.member.deserialize
            : (value) => searchParamsConfig.member.deserialize(value) ?? initialState.query.approver,
        },
        includeLockedItems: { default: initialState.query.includeLockedItems, valid: ['yes', 'no'] },
      }),
      [initialState.query, searchParamsConfig, auth.workspace.manage],
    ),
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = query.period || {};

      const params = {
        ..._.omit(query, ['project', 'member', 'memberPractice', 'approver', 'period', 'includeLockedItems']),
        billableTypeId: query.billableTypeId ?? undefined,
        projectId: query.project?.id,
        memberId: query.member?.id,
        memberPracticeId: query.memberPractice?.id,
        approverId: query.approver?.id,
        start: start ?? undefined,
        end: end ?? undefined,
        includeLockedItems: query.includeLockedItems ?? undefined,
      };

      const { data } = await api.www.workspaces(workspace.id).timeAdmin().getApprovals(params);

      actions.ready({ data });
      return data;
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query, api]);

  const refetchData = async () => {
    actions.refetch();
    const data = await fetchData();
    const ids = data.map(({ id }) => id);
    setSelection(selection.filter((s) => ids.includes(s)));
  };

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = ({ target }) => {
    actions.setParams({ [target.name]: target.value });
    setSelection([]);
    searchParams.set({ [target.name]: target.value });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleEntryStatusChange = async (entry, statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectTimeDialog count={1} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit(entry.id);

      const { data } = await api.www
        .workspaces(workspace.id)
        .timeAdmin()
        .batchUpdateStatus({ ids: [entry.id], statusId, notes });

      actions.updateItems(data);
      refetchData();

      notify(useSubscription.keys.refresh_time_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleBatchStatusChange = async (statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectTimeDialog count={selection.length} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit('batch');

      const { data } = await api.www
        .workspaces(workspace.id)
        .timeAdmin()
        .batchUpdateStatus({ ids: selection, statusId, notes });

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} time entries.`);
      setSelection([]);
      form.save();
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleGroupAction = async (group, statusId) => {
    const ids = group.entries.map((entry) => entry.id);

    try {
      let groupActions;

      switch (statusId) {
        case 'rejected': {
          let notes;
          if (statusId === 'rejected') {
            notes = await confirmation.prompt((resolve) => (
              <RejectTimeDialog count={ids.length} onResolve={(notes) => resolve(notes)} />
            ));
            if (!notes) return;
          }

          groupActions = { ids: group.entries.map((entry) => entry.id), statusId, notes };
          break;
        }

        default:
          groupActions = { ids: group.entries.map((entry) => entry.id), statusId };
      }

      form.submit({ action: 'group', group });

      const { data } = await api.www.workspaces(workspace.id).timeAdmin().batchUpdateStatus(groupActions);

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} time entries.`);
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleSetToApproved = async () => {
    await confirmation.prompt((resolve) => (
      <SetToApprovedConfirmation
        count={selection.length}
        onResolve={async (result) => {
          if (!result) {
            resolve(false);
            return;
          }

          try {
            const { data } = await api.www
              .workspaces(workspace.id)
              .timeAdmin()
              .setStatus({ ids: selection, statusId: 'approved' });

            await refetchData();

            toast.success(`Set ${data.length} time ${pluralize('entry', data.length)} to Approved.`);
            setSelection([]);
            notify(useSubscription.keys.refresh_time_approval_count);
            resolve(true);
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        }}
      />
    ));
  };

  const handleSetToRejected = async () => {
    await confirmation.prompt((resolve) => (
      <SetToRejectedConfirmation
        count={selection.length}
        onResolve={async (notes) => {
          if (!notes) {
            resolve(false);
            return;
          }

          try {
            const { data } = await api.www
              .workspaces(workspace.id)
              .timeAdmin()
              .setStatus({ ids: selection, statusId: 'rejected', notes });

            await refetchData();

            toast.success(`Set ${data.length} time ${pluralize('entry', data.length)} to Rejected .`);
            setSelection([]);
            notify(useSubscription.keys.refresh_time_approval_count);
            resolve(true);
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        }}
      />
    ));
  };

  const handleResultClick = (entry, mode) => {
    setDrawer({ entry, mode });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set('Time Approvals');
  };

  const handleEntrySaved = (entry) => {
    actions.updateItems([entry]);
    refetchData();
    notify(useSubscription.keys.refresh_timer);
  };

  const handleEntryDeleted = (entry) => {
    actions.removeItem(entry.id);
    refetchData();
    handleSelectionChange(selection.filter((s) => s !== entry.id));
    notify(useSubscription.keys.refresh_timer);
  };

  const handleTimerChange = (entry) => {
    // Stop all timers for the member
    const runningTimers = data
      .filter((e) => !!e.timerStartedAt && e.member.id === entry.member.id)
      .map((e) => ({
        ...e,
        minutes: moment.duration(e.minutes, 'minutes').add(moment().diff(e.timerStartedAt)).asMinutes(),
        timerStartedAt: null,
      }));

    actions.updateItems([...runningTimers, entry]);
    if (entry.timerStartedAt) setSelection(selection.filter((e) => e !== entry.id));
    notify(useSubscription.keys_refresh_timer);
  };

  // Actions are only enabled if it's an approver's queue.
  const isApproverQueue = !!query.approver;
  const isAdminQueue = !query.approver && auth.workspace.manage;

  if (!isReady) return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Time</Page.Eyebrow>
            <Page.Title>Time Approvals</Page.Title>
          </Page.Info>
        </Page.Header>

        <Page.Filters>
          <FiltersBar>
            <ApprovalsPeriodFilter name="period" maxDays={180} value={query.period} onChange={handleFilterChange} />

            <ClientProjectSelect
              name="project"
              placeholder="All"
              materialPlaceholder="Project"
              materialAlwaysVisible
              assignedOnly={false}
              value={query.project}
              onChange={handleFilterChange}
            />

            <MemberSelect
              name="approver"
              placeholder="All"
              materialPlaceholder="Approver"
              materialAlwaysVisible
              value={query.approver}
              clearable={auth.workspace.manage}
              onChange={handleFilterChange}
            />

            <SingleSelect
              placeholder="All"
              materialPlaceholder="Time Type"
              materialAlwaysVisible
              showEmptyOption
              name="billableTypeId"
              value={query.billableTypeId}
              onChange={handleFilterChange}>
              <option value="billable">Client Billable</option>
              <option value="non_billable">Client Non-Billable</option>
              <option value="internal">Internal</option>
              <option value="time_off">Time Off</option>
            </SingleSelect>

            <MemberSelect
              name="member"
              placeholder="All"
              materialPlaceholder="Member"
              materialAlwaysVisible
              value={query.member}
              onChange={handleFilterChange}
            />

            {features.practices && (
              <PracticeSelect
                name="memberPractice"
                placeholder="All"
                materialPlaceholder="Member Practice"
                materialAlwaysVisible
                value={query.memberPractice}
                onChange={handleFilterChange}
              />
            )}
            <SingleSelect
              name="includeLockedItems"
              materialPlaceholder="Include Locked Items"
              materialAlwaysVisible
              value={query.includeLockedItems}
              onChange={handleFilterChange}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </SingleSelect>

            <YesNoFilter
              materialPlaceholder="Show Notes"
              value={showNotes ? 'yes' : 'no'}
              onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
            />
          </FiltersBar>
        </Page.Filters>

        <TimeApprovalResults
          results={data}
          selection={selection}
          showNotes={showNotes}
          isSubmitting={isSubmitting}
          isApproverQueue={isApproverQueue}
          isAdminQueue={isAdminQueue}
          onResultClick={handleResultClick}
          onSelectionChange={handleSelectionChange}
          onStatusChange={handleEntryStatusChange}
          onGroupAction={handleGroupAction}
          onChange={refetchData}
          action={action}
        />

        {selection.length > 0 && (
          <ActionsBanner>
            {isApproverQueue && (
              <Level right>
                <Level.Item>
                  <SplitButton>
                    <ActionButton
                      isLoading={isSubmitting === 'batch'}
                      ok={saved}
                      onClick={() => handleBatchStatusChange('approved')}>
                      Approve <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                    </ActionButton>

                    <SplitButton.Menu position="top" disabled={selection.length === 0}>
                      {({ setIsOpen }) => (
                        <SplitButton.Item onClick={() => setIsOpen(false) || handleBatchStatusChange('rejected')}>
                          Reject
                        </SplitButton.Item>
                      )}
                    </SplitButton.Menu>
                  </SplitButton>
                </Level.Item>
              </Level>
            )}

            {isAdminQueue && (
              <Level right>
                <Level.Item>
                  <SplitButton data-testid="admin-actions">
                    <ActionButton isLoading={isSubmitting === 'batch'} ok={saved} onClick={() => handleSetToApproved()}>
                      Set to Approved{' '}
                      <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                    </ActionButton>

                    <SplitButton.Menu position="top" disabled={selection.length === 0}>
                      {({ setIsOpen }) => (
                        <SplitButton.Item onClick={() => setIsOpen(false) || handleSetToRejected()}>
                          Set to Rejected
                        </SplitButton.Item>
                      )}
                    </SplitButton.Menu>
                  </SplitButton>
                </Level.Item>
              </Level>
            )}
          </ActionsBanner>
        )}
      </Page>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={drawer.entry.id}
              memberId={drawer.entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(drawer.entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={handleEntrySaved}
              onDeleted={handleEntryDeleted}
              onClose={handleCloseDrawer}
              onTimerChange={handleTimerChange}
            />
          ),
          view: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} />,
          viewAttachments: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} tab="attachments" />,
        }[drawer.mode]()}
    </>
  );
}

export default TimeApprovalsPage;
