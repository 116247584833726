import _ from 'lodash';
import React from 'react';
import { FiltersBar, InvoiceStatusSelect, Page, SearchInput, SingleSelect } from '~/components';
import { useWorkspace } from '~/contexts';
import intervals from '../invoices/invoices-list/intervals';
import InvoicesListPage from '../invoices/invoices-list/InvoicesListPage';

const Filters = ({ query, handleFilter }) => (
  <Page.Filters>
    <FiltersBar>
      <SearchInput
        value={query.q}
        placeholder="Search"
        materialPlaceholder="Invoice #"
        materialAlwaysVisible
        onChange={({ target: { value } }) => handleFilter({ q: value })}
      />

      <InvoiceStatusSelect
        value={query.statusIds}
        onChange={({ target: { value } }) => handleFilter({ statusIds: value })}
      />

      <SingleSelect
        placeholder="All"
        materialPlaceholder="Issue Date"
        materialAlwaysVisible
        showEmptyOption
        value={query.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}>
        {_.map(intervals, ({ label }, key) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </SingleSelect>
    </FiltersBar>
  </Page.Filters>
);

function ClientInvoicesTab({ client }) {
  const { workspace } = useWorkspace();

  return (
    <InvoicesListPage
      mode="tab"
      clientId={client.id}
      renderFilters={Filters}
      onRowClick={(invoice) => window.open(`/${workspace.key}/invoices/${invoice.id}?preview=true`)}
    />
  );
}

export default ClientInvoicesTab;
