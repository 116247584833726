import {
  ActionButton,
  ApprovalWorkflowEditor,
  Button,
  Buttons,
  CancelButton,
  Confirmation,
  CurrencyMultiSelect,
  DeleteButton,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Hours,
  Page,
  PromptNavigation,
  Radio,
  RegionalFormatExamples,
  Stack,
} from '~/components';
import { SectionTitle } from '~/components/Form';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import revenueAttributionMethods from '~/lookups/revenue-attribution-methods';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, getFileBytes, mergeValues } from '~/utils';
import { slugValidator } from '~/utils/validators';
import * as Yup from 'yup';
import BusinessDays from './BusinessDays';
import ProjectCodeModal from './ProjectCodeModal';
import ClientCodeModal from './ClientCodeModal';
import TaskCodeModal from './TaskCodeModal';
import WorkspaceDeleteConfirmation from './WorkspaceDeleteConfirmation';

const SettingsPage = styled.div`
  ${SectionTitle} {
    min-width: 17rem;
    flex: 0.6;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContainerLabel = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ContainerControl = styled.span`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

const WorkspaceImage = styled.div`
  .avatar .icon {
    font-size: 3rem;
    color: ${colors.grey10};
  }
`;

const ModalLink = styled.div`
  margin-left: 2rem;
  color: ${colors.primary};
  font-size: 0.75rem;
  cursor: pointer;

  &:hover {
    color: ${colors.accent};
  }
`;
const LabelTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const WarningButton = styled(Button)`
  color: ${colors.warning};
  border-color: ${colors.warning};

  &:hover:not(:disabled) {
    color: ${colors.white};
    background-color: ${colors.warning};
    border-color: ${colors.warning};
  }
`;

const CenteredRadio = styled.div`
  label {
    display: flex;
    align-items: center;
  }
`;

const initialState = { isSubmitting: false, saved: false, status: null, message: null };
const handlers = {
  showDeleteConfirmation: () => ({ dialog: 'deleteConfirmation' }),
  showProjectCodeSettings: () => ({ dialog: 'projectCode' }),
  showClientCodeSettings: () => ({ dialog: 'clientCode' }),
  showTaskCodeSettings: () => ({ dialog: 'taskCode' }),
  closeDialog: () => ({ dialog: null }),
};

function WorkspaceSettingsPage() {
  useDocumentTitle('Settings');

  const api = useApi();
  const { workspace, updateWorkspace } = useWorkspace();
  const [{ isSubmitting, saved, status, message }, form] = useForm();
  const [{ dialog }, actions] = useActions(handlers, initialState);

  const history = useHistory();

  const confirmation = useConfirmation();

  const features = useFeatures();

  const initialValues = mergeValues(
    {
      businessDays: [true, true, true, true, true, false, false],
      companyName: '',
      currencies: [],
      currency: null,
      defaultAllocationUnit: 'day',
      defaultAllocateOnTimeOffDays: false,
      defaultAdjustForTimeOff: false,
      showAllocationsOnTimeCalendar: true,
      image: null,
      imageUrl: '',
      key: '',
      locale: null,
      lockTimeAndExpenses: true,
      lockTimeAndExpensesAfterDays: 90,
      lockTimeAndExpensesAfterWeekEnds: false,
      lockTimeAndExpensesAfterWeekEndsDays: 3,
      lockTimeAndExpensesAfterMonthEnds: false,
      lockTimeAndExpensesAfterMonthEndsDays: 5,
      timeAndExpensesWithinProjectDates: false,
      name: '',
      revenueRecognitionMethod: 'invoiced',
      revenueAttributionMethod: 'hours',
      autoRevRecInterval: 'week',
      autoRevRecWeekDay: 3,
      autoRevRecMonthDay: 5,
      roundTimeEntriesToMinutes: null,
      timeZoneId: '',
      useDecimalTimeEntry: false,
      useTwelveHourClock: true,
      timeDailyReminders: false,
      timeWeeklyReminders: false,
      timeMonthlyReminders: false,
      unsubmittedTimeDailyReminders: false,
      unsubmittedTimeWeeklyReminders: false,
      unsubmittedTimeMonthlyReminders: false,
      unsubmittedTimesheetReminders: false,
      unsubmittedTimesheetRemindersDay: 5,
      unsubmittedTimesheetRemindersHour: 17,
      unsubmittedTimesheetRemindersCcManager: false,
      managerWeeklyTimeNotification: false,
      createHolidayTime: true,
      approvalNotifications: false,
      approvalReminders: false,
      approvalRemindersAfterDays: 3,
      requireExpenseProject: false,
      generateProjectCode: false,
      uniqueProjectCode: false,
      projectDefaultUseHealthReports: true,
      projectDefaultUseRoles: false,
      projectDefaultTrackBudget: false,
      projectDefaultTimeRequireNotes: false,
      projectDefaultTimeRequireTask: false,
      projectDefaultTimeAssignedRole: false,
      projectDefaultIsTimeApprovalRequired: true,
      projectDefaultIsExpensesApprovalRequired: false,
      projectDefaultEnableClientApprovals: false,
      projectDefaultAssignmentNotifications: false,
      generateClientCode: false,
      uniqueClientCode: false,
      generateTaskCode: false,
      uniqueTaskCode: false,
      autoSubmitTimesheetsAfterWeekEnds: false,
      autoSubmitTimesheetsAfterWeekEndsDays: 3,
      autoSubmitTimesheetsAfterMonthEnds: false,
      autoSubmitTimesheetsAfterMonthEndsDays: 5,
      lockTimesheetsManuallyApproved: false,
      lockTimesheetsInvoice: false,
      lockTimesheets: false,
      lockTimesheetsAfterDays: 3,
      lockTimesheetsAfterMonthEnds: false,
      lockTimesheetsAfterMonthEndsDays: 5,
      enableCopyLastWeekTimeEntries: false,
      paymentTermsId: '',
      workingHoursPerDay: 8.0,
      manageMemberProfile: false,
      notificationsReplyTo: '',
      allowTimesheetSplitAtEndOfMonth: true,
      allowTimesheetSplitAtEndOfSemiMonth: false,
    },
    workspace,
  );

  async function handleSubmit(values) {
    try {
      form.submit();

      if (values.image || values.imageUrl !== initialValues.imageUrl) {
        let file = null;
        if (values.image) {
          const bytes = await getFileBytes(values.image);
          const type = values.image.type;
          file = { bytes, type };
        }
        await api.www.workspaces(workspace.id).setImage(file);
      }

      if (!features.clientApprovals) {
        delete values.projectDefaultEnableClientApprovals;
      }

      const body = emptyStringToNull(_.omit(values, ['image', 'imageUrl']));

      const { data } = await api.www.workspaces(workspace.id).patch(body);

      updateWorkspace(data);

      form.save();

      if (data.key !== workspace.key) {
        history.replace(`/app/${data.key}/settings/general`);
      }
    } catch (error) {
      form.error(error);
    }
  }

  const toast = useToast();

  const [timeApproval, setTimeApproval] = useState();

  const handleTimeApprovalEdit = async () => {
    const { data: timeApproval } = await api.www.workspaces(workspace.id).timeApproval.get();
    setTimeApproval(timeApproval || {});
  };

  const handleTimeApprovalSubmit = async (value) => {
    try {
      await api.www.workspaces(workspace.id).timeApproval.update(value);
      toast.success('The time approval workflow has been successfully updated.');
      setTimeApproval(null);
    } catch (error) {
      toast.error('An error has occurred updating the time approval workflow.');
    }
  };

  const [expenseApproval, setExpenseApproval] = useState();

  const handleExpenseApprovalEdit = async () => {
    const { data: expenseApproval } = await api.www.workspaces(workspace.id).expenseApproval.get();
    setExpenseApproval(expenseApproval || {});
  };

  const handleExpenseApprovalSubmit = async (value) => {
    try {
      await api.www.workspaces(workspace.id).expenseApproval.update(value);
      toast.success('The expense approval workflow has been successfully updated.');
      setExpenseApproval(null);
    } catch (error) {
      toast.error('An error has occurred updating the expense approval workflow.');
    }
  };

  const handleRemoveSampleData = async () => {
    await confirmation.prompt((resolve) => (
      <Confirmation
        title="Remove Sample Data"
        resolve={async (result) => {
          if (!result) {
            resolve(false);
            return;
          }

          try {
            await api.www.workspaces(workspace.id).removeSampleData();
            updateWorkspace({ sampleData: null });
            toast.success('Sample data has been removed.');
          } catch (error) {
            toast.error('An error occurred removing sample data.');
          } finally {
            resolve(true);
          }
        }}>
        This will remove all sample data. This action cannot be undone. Are you sure?
      </Confirmation>
    ));
  };

  const formatOrdinal = (n) => {
    const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
    const suffixes = new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ]);

    return `${n}${suffixes.get(pr.select(n))}`;
  };

  return (
    <>
      <Page>
        <SettingsPage>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              businessDays: Yup.array().min(7).max(7),
              companyName: Yup.string().label('Company Name').max(255).required(),
              key: Yup.string()
                .label('Workspace URL ID')
                .matches(slugValidator.expression, { message: slugValidator.message })
                .max(255)
                .required(),
              paymentTermsId: Yup.string().label('Default Payment Terms').required(),
              name: Yup.string().label('Workspace Name').max(255).required(),
              workingHoursPerDay: Yup.number().label('Working Hours Per Day').min(0).max(24),
              notificationsReplyTo: Yup.string().label('Notifications Reply To').email().max(255),
            })}>
            {({ resetForm, submitForm, dirty, values, setFieldValue, setValues }) => {
              const handleCancel = async () => {
                const confirm = await confirmation.prompt((resolve) => (
                  <Confirmation resolve={resolve}>This will discard all changes. Are you sure?</Confirmation>
                ));
                if (!confirm) return;

                resetForm();
              };

              const handleProjectDefaultUseRolesChange = (event) => {
                const projectDefaultUseRoles = event.target.value !== 'true';
                setFieldValue('projectDefaultUseRoles', projectDefaultUseRoles);
                if (!projectDefaultUseRoles) {
                  setFieldValue('projectDefaultTimeAssignedRole', false);
                }
              };

              return (
                <Stack>
                  {workspace.sampleData && (
                    <Form.Section
                      title="Sample Data"
                      subtitle={
                        <>
                          This workspace has been pre-loaded with sample data. Click the "Remove Sample Data" button to
                          permanently remove it.
                        </>
                      }>
                      <Form.Control style={{ width: '50%' }}>
                        <WarningButton isOutline onClick={handleRemoveSampleData}>
                          Remove Sample Data
                        </WarningButton>
                      </Form.Control>
                    </Form.Section>
                  )}
                  <Form.Section
                    title="Workspace Identification"
                    subtitle="The workspace logo is used on invoices and email communications. It is recommended that you do not change your Workspace ID once your workspace has been in use because bookmarks and other links to the workspace will no longer be valid.">
                    <Form.Control>
                      <Field.Text name="name" placeholder="Workspace Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <WorkspaceImage>
                        <Field.ImageFileInput name="image" urlFieldName="imageUrl" label="Logo" />
                      </WorkspaceImage>
                    </Form.Control>
                    <Form.Control help="The unique workspace identifier used in URLs. Changing this will break existing links to this workspace.">
                      <Field.Text name="key" placeholder="Workspace URL ID" maxLength={255} />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Company Information"
                    subtitle="The company name is usually the same as the workspace name defined in the previous section.">
                    <Form.Control>
                      <Field.Text name="companyName" placeholder="Company Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control
                      help={
                        <p>
                          The reply-to email address for notifications sent to workspace members. If left blank, replies
                          will go to <a href="">hello@ruddr.io</a>.
                        </p>
                      }>
                      <Field.Text
                        name="notificationsReplyTo"
                        placeholder="Notifications Reply To"
                        type="email"
                        maxLength={255}
                      />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Member Settings"
                    subtitle="Allow members to edit their profile information, define standard working days for your company and the default number of working hours per day.">
                    <Form.Control>
                      <ContainerControl style={{ marginTop: '0.25rem' }}>
                        <Field.Checkbox
                          name="manageMemberProfile"
                          label="Allow members to edit their name and profile image"
                        />
                      </ContainerControl>
                    </Form.Control>
                    <Form.Control>
                      <BusinessDays name="businessDays" />
                    </Form.Control>
                    <Form.Control style={{ width: '10rem' }}>
                      <Field.Number
                        min={0}
                        max={24}
                        precision={2}
                        materialAlwaysVisible
                        materialPlaceholder="Capacity Hours Per Day"
                        name="workingHoursPerDay"
                      />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Regional Settings"
                    subtitle="These settings allow you to control the time zone, currency, and regional format.">
                    <Form.Control>
                      <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                    </Form.Control>

                    <Form.Control>
                      <Field.CurrencySelect name="currency" clearable={false} placeholder="Home Currency" />
                    </Form.Control>

                    {features.multicurrency && (
                      <Form.Control>
                        <CurrencyMultiSelect
                          placeholder="Other Available Currencies"
                          name="currencies"
                          value={values.currencies}
                          onChange={({ target: { value } }) => setFieldValue('currencies', value)}
                        />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <div>
                        <Field.RegionalFormatSelect name="locale" clearable={false} />
                        <RegionalFormatExamples currency={values.currency} locale={values.locale} />
                      </div>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Default Time Format"
                    subtitle="The default time format will be used for new members that are invited into the workspace. Each member can override this setting within their user profile settings.">
                    <Form.Control>
                      <Field.RadioGroup name="useDecimalTimeEntry">
                        <Radio value={false} label="Clock format (1:30)" />
                        <Radio
                          value={true}
                          label={
                            <>
                              Decimal format <Hours value={1.5} locale={values.locale} />
                            </>
                          }
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Time and Expense Settings"
                    subtitle="These settings allow you to configure time and expense locking, automatic creation of holiday time off entries, and related items.">
                    <Form.Control>
                      <ContainerControl style={{ marginTop: '0.25rem' }}>
                        <Field.Checkbox
                          name="timeAndExpensesWithinProjectDates"
                          label="Time and expenses must be created within the project start and end dates"
                        />
                      </ContainerControl>
                    </Form.Control>
                    <Form.Control>
                      <ContainerControl style={{ marginTop: '0' }}>
                        <Field.Checkbox
                          name="createHolidayTime"
                          label="Automatically create holiday time off entries"
                        />
                        <HelpTooltip
                          style={{ marginLeft: '.5rem' }}
                          message="Entries will be created weekly before the start of the week (Monday)."
                        />
                      </ContainerControl>
                    </Form.Control>

                    {features.expenseReporting && (
                      <Form.Control>
                        <ContainerControl style={{ marginTop: '0' }}>
                          <Field.Checkbox
                            style={{ width: '5.5rem', margin: '0 0.5rem' }}
                            name="requireExpenseProject"
                            label="Expense items must have an associated project"
                          />
                        </ContainerControl>
                      </Form.Control>
                    )}

                    <Form.Control>
                      <ContainerControl style={{ marginTop: 0 }}>
                        <Field.Checkbox
                          name="enableCopyLastWeekTimeEntries"
                          label="Allow time entries to be copied from the previous week"
                        />
                        <HelpTooltip
                          style={{ marginLeft: '.5rem' }}
                          message={`Enable a "Copy Last Week's Time Entries" button on the Week view of the Time Entries screen.`}
                        />
                      </ContainerControl>
                    </Form.Control>

                    <Container style={{ marginTop: '1rem' }}>
                      <ContainerLabel>
                        Lock time entries and expense items:
                        <HelpTooltip
                          message="An item will not lock for members who have member-based administrative rights to the item."
                          style={{ marginLeft: '.5rem' }}
                        />
                      </ContainerLabel>

                      <ContainerControl style={{ marginTop: '0' }}>
                        <Field.Checkbox name="lockTimeAndExpenses" label="Dated more than" />
                        <Field.SingleSelect
                          name="lockTimeAndExpensesAfterDays"
                          materialPlaceholder={false}
                          style={{ width: '6rem', margin: '0 0.5rem', textAlign: 'right' }}
                          disabled={!values.lockTimeAndExpenses}>
                          <option value={7}>7</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                          <option value={60}>60</option>
                          <option value={90}>90</option>
                          <option value={120}>120</option>
                          <option value={180}>180</option>
                          <option value={360}>360</option>
                        </Field.SingleSelect>
                        days ago
                      </ContainerControl>

                      <ContainerControl>
                        <Field.Checkbox name="lockTimeAndExpensesAfterWeekEnds" />
                        After
                        <Field.SingleSelect
                          name="lockTimeAndExpensesAfterWeekEndsDays"
                          materialPlaceholder={false}
                          style={{ width: '9.5rem', margin: '0 0.5rem' }}
                          disabled={!values.lockTimeAndExpensesAfterWeekEnds}>
                          {[
                            { label: 'Monday', value: 1 },
                            { label: 'Tuesday', value: 2 },
                            { label: 'Wednesday', value: 3 },
                            { label: 'Thursday', value: 4 },
                            { label: 'Friday', value: 5 },
                            { label: 'Saturday', value: 6 },
                          ].map(({ label, value }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Field.SingleSelect>
                        of the next week
                      </ContainerControl>

                      <ContainerControl>
                        <Field.Checkbox name="lockTimeAndExpensesAfterMonthEnds" />
                        After the
                        <Field.SingleSelect
                          name="lockTimeAndExpensesAfterMonthEndsDays"
                          materialPlaceholder={false}
                          style={{ width: '6.5rem', margin: '0 0.5rem', textAlign: 'right' }}
                          disabled={!values.lockTimeAndExpensesAfterMonthEnds}>
                          {[...Array(20).keys()].map((_, idx) => (
                            <option key={idx} value={idx + 1}>
                              {idx + 1}
                              {{
                                1: 'st',
                                2: 'nd',
                                3: 'rd',
                              }[idx + 1] || 'th'}
                            </option>
                          ))}
                        </Field.SingleSelect>
                        day of the next month
                      </ContainerControl>
                    </Container>
                  </Form.Section>

                  <Form.Section
                    title="Time and Expense Notifications and Reminders"
                    subtitle="These settings allow you to configure notifications related to time and expense approval, missing time, and unsubmitted time.">
                    <Form.Control style={{ marginTop: 0 }}>
                      <Container>
                        <ContainerLabel>
                          Send missing time reminders:
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="Automatically send workspace members a reminder email when they have not entered enough hours for the period."
                          />
                        </ContainerLabel>
                        <ContainerControl>
                          <Field.Checkbox name="timeDailyReminders" label="Daily" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="The daily reminder will be sent at 9 PM in the user's time zone."
                          />
                        </ContainerControl>
                        <ContainerControl>
                          <Field.Checkbox name="timeWeeklyReminders" label="Weekly" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="The weekly reminder will be sent at 3 AM in the user's time zone."
                          />
                        </ContainerControl>
                        <ContainerControl>
                          <Field.Checkbox name="timeMonthlyReminders" label="Monthly" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="The monthly reminder will be sent at 3 AM in the user's time zone."
                          />
                        </ContainerControl>
                      </Container>
                    </Form.Control>

                    {features.timesheets ? (
                      <Form.Control>
                        <Container>
                          <ContainerLabel>
                            Send timesheet submission reminders:
                            <HelpTooltip
                              style={{ marginLeft: '.5rem' }}
                              message="Automatically send workspace members a reminder email when they have when they have one or more unsubmitted timesheets in the past 5 weeks."
                            />
                          </ContainerLabel>
                          <ContainerControl>
                            <Field.Checkbox
                              name="unsubmittedTimesheetReminders"
                              onChange={({ target: { checked } }) => {
                                const newValues = { ...values, unsubmittedTimesheetReminders: checked };
                                if (!checked) newValues.unsubmittedTimesheetRemindersCcManager = false;
                                setValues(newValues);
                              }}
                            />
                            <span>Weekly on</span>
                            <Field.SingleSelect
                              name="unsubmittedTimesheetRemindersDay"
                              placement="top"
                              materialPlaceholder={false}
                              style={{ width: '9.5rem', margin: '0 0.5rem' }}
                              disabled={!values.unsubmittedTimesheetReminders}>
                              {[
                                { label: 'Friday', value: 5 },
                                { label: 'Saturday', value: 6 },
                                { label: 'Sunday', value: 7 },
                              ].map(({ label, value }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </Field.SingleSelect>
                            <span>at</span>
                            <Field.SingleSelect
                              name="unsubmittedTimesheetRemindersHour"
                              placement="top"
                              materialPlaceholder={false}
                              style={{ width: '7.5rem', margin: '0 0.5rem', textAlign: 'right' }}
                              disabled={!values.unsubmittedTimesheetReminders}>
                              {[...Array(24).keys()].map((_, idx) => (
                                <option key={idx} value={idx}>
                                  {Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(idx)}:00
                                </option>
                              ))}
                            </Field.SingleSelect>
                          </ContainerControl>
                          <ContainerControl>
                            <Field.Checkbox
                              name="unsubmittedTimesheetRemindersCcManager"
                              label="Cc the member's manager"
                              disabled={!values.unsubmittedTimesheetReminders}
                            />
                          </ContainerControl>
                        </Container>
                      </Form.Control>
                    ) : (
                      <Form.Control>
                        <Container>
                          <ContainerLabel>
                            Send unsubmitted time reminders:
                            <HelpTooltip
                              style={{ marginLeft: '.5rem' }}
                              message="Automatically send workspace members a reminder email when they have unsubmitted hours for the period."
                            />
                          </ContainerLabel>
                          <ContainerControl>
                            <Field.Checkbox name="unsubmittedTimeDailyReminders" label="Daily" />
                            <HelpTooltip
                              style={{ marginLeft: '.5rem' }}
                              message="The daily reminder will be sent at 9 PM in the user's time zone."
                            />
                          </ContainerControl>
                          <ContainerControl>
                            <Field.Checkbox name="unsubmittedTimeWeeklyReminders" label="Weekly" />
                            <HelpTooltip
                              style={{ marginLeft: '.5rem' }}
                              message="The weekly reminder will be sent at 3 AM in the user's time zone."
                            />
                          </ContainerControl>
                          <ContainerControl>
                            <Field.Checkbox name="unsubmittedTimeMonthlyReminders" label="Monthly" />
                            <HelpTooltip
                              style={{ marginLeft: '.5rem' }}
                              message="The monthly reminder will be sent at 3 AM in the user's time zone."
                            />
                          </ContainerControl>
                        </Container>
                      </Form.Control>
                    )}

                    <ContainerControl style={{ marginTop: '1rem' }}>
                      <Field.Checkbox
                        name="managerWeeklyTimeNotification"
                        label="Send a weekly notification to managers if their direct reports have missing or unsubmitted time"
                      />
                      <HelpTooltip
                        style={{ marginLeft: '.5rem' }}
                        message="A single weekly notification will be sent to the manager if any direct reports have missing or unsubmitted time in the past 30 days."
                      />
                    </ContainerControl>

                    <ContainerControl style={{ marginTop: '1.5rem' }}>
                      <Field.Checkbox
                        name="approvalNotifications"
                        label="Send time and expense approval notifications"
                      />
                      <HelpTooltip
                        style={{ marginLeft: '.5rem' }}
                        message="Notifications will be sent immediately when a time entry or expense item needs approval."
                      />
                    </ContainerControl>

                    <ContainerControl style={{ marginTop: '1rem' }}>
                      <Field.Checkbox
                        name="approvalReminders"
                        label="Send a daily reminder to members who do not approve items within"
                      />
                      <Field.SingleSelect
                        name="approvalRemindersAfterDays"
                        placement="top"
                        materialPlaceholder={false}
                        style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}
                        disabled={!values.approvalReminders}>
                        {[...Array(30).keys()].map((_, idx) => (
                          <option key={idx} value={idx + 1}>
                            {idx + 1}
                          </option>
                        ))}
                      </Field.SingleSelect>
                      <span>days</span>
                      <HelpTooltip
                        style={{ marginLeft: '.5rem' }}
                        message="Time and expense approval reminders will be sent at 9 AM in the user's time zone."
                      />
                    </ContainerControl>
                  </Form.Section>

                  {features.timesheets && (
                    <Form.Section
                      title="Timesheet Settings"
                      subtitle="These settings allow you to configure automatic timesheet submission and timesheet unsubmission rules.">
                      <Container style={{ borderBottom: `solid 1px ${colors.grey20}`, paddingBottom: '1.5rem' }}>
                        <ContainerLabel>
                          Allow a timesheet to be split:
                          <HelpTooltip
                            message="These settings allow a weekly timesheet to be split into two separate timesheets."
                            style={{ marginLeft: '.5rem' }}
                          />
                        </ContainerLabel>

                        <ContainerControl>
                          <Field.Checkbox
                            name="allowTimesheetSplitAtEndOfSemiMonth"
                            label="At the end of the semi-month (on the 15th)"
                          />
                        </ContainerControl>

                        <ContainerControl>
                          <Field.Checkbox name="allowTimesheetSplitAtEndOfMonth" label="At the end of the month" />
                        </ContainerControl>
                      </Container>

                      <ContainerControl style={{ marginTop: '1rem' }}>
                        <Field.Checkbox
                          name="autoSubmitTimesheetsAfterWeekEnds"
                          label="Automatically submit a week's timesheets on"
                        />
                        <Field.SingleSelect
                          name="autoSubmitTimesheetsAfterWeekEndsDays"
                          placement="top"
                          materialPlaceholder={false}
                          style={{ width: '9.5rem', margin: '0 0.5rem' }}
                          disabled={!values.autoSubmitTimesheetsAfterWeekEnds}>
                          {[
                            { label: 'Monday', value: 1 },
                            { label: 'Tuesday', value: 2 },
                            { label: 'Wednesday', value: 3 },
                            { label: 'Thursday', value: 4 },
                            { label: 'Friday', value: 5 },
                            { label: 'Saturday', value: 6 },
                          ].map(({ label, value }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Field.SingleSelect>
                        <span>of the next week</span>
                      </ContainerControl>
                      <ContainerControl style={{ borderBottom: `solid 1px ${colors.grey20}`, paddingBottom: '1rem' }}>
                        <Field.Checkbox
                          name="autoSubmitTimesheetsAfterMonthEnds"
                          label="Automatically submit a month's timesheets on the"
                        />
                        <Field.SingleSelect
                          name="autoSubmitTimesheetsAfterMonthEndsDays"
                          placement="top"
                          materialPlaceholder={false}
                          style={{ width: '5.5rem', margin: '0 0.5rem' }}
                          disabled={!values.autoSubmitTimesheetsAfterMonthEnds}>
                          {[...Array(6).keys()].map((_, idx) => (
                            <option key={idx} value={idx + 1}>
                              {idx + 1}
                              {{
                                1: 'st',
                                2: 'nd',
                                3: 'rd',
                              }[idx + 1] || 'th'}
                            </option>
                          ))}
                        </Field.SingleSelect>
                        <span>day of the next month</span>
                      </ContainerControl>

                      <Form.Control style={{ marginTop: '.5rem' }}>
                        <Container>
                          <ContainerLabel>
                            Allow a timesheet to be unsubmitted until:
                            <HelpTooltip
                              message="A timesheet can always be unsubmitted by members who have member-based administrative rights to the timesheet."
                              style={{ marginLeft: '.5rem' }}
                            />
                          </ContainerLabel>

                          <ContainerControl>
                            <Field.Checkbox
                              name="lockTimesheetsManuallyApproved"
                              label="A time entry on the timesheet has been manually approved"
                            />
                          </ContainerControl>

                          <ContainerControl style={{ marginTop: '1.5rem' }}>
                            <Field.Checkbox
                              name="lockTimesheetsInvoice"
                              label="A time entry on the timesheet is associated with an invoice"
                            />
                          </ContainerControl>

                          <ContainerControl style={{ marginTop: '1rem' }}>
                            <Field.Checkbox name="lockTimesheets" />
                            <Field.SingleSelect
                              name="lockTimesheetsAfterDays"
                              materialPlaceholder={false}
                              style={{ width: '9.5rem', margin: '0 0.5rem' }}
                              disabled={!values.lockTimesheets}>
                              {[
                                { label: 'Monday', value: 1 },
                                { label: 'Tuesday', value: 2 },
                                { label: 'Wednesday', value: 3 },
                                { label: 'Thursday', value: 4 },
                                { label: 'Friday', value: 5 },
                                { label: 'Saturday', value: 6 },
                              ].map(({ label, value }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </Field.SingleSelect>
                            of the next week
                          </ContainerControl>

                          <ContainerControl style={{ marginTop: '.5rem' }}>
                            <Field.Checkbox name="lockTimesheetsAfterMonthEnds" />
                            The
                            <Field.SingleSelect
                              name="lockTimesheetsAfterMonthEndsDays"
                              materialPlaceholder={false}
                              style={{ width: '6.5rem', margin: '0 0.5rem', textAlign: 'right' }}
                              disabled={!values.lockTimesheetsAfterMonthEnds}>
                              {[...Array(20).keys()].map((_, idx) => (
                                <option key={idx} value={idx + 1}>
                                  {idx + 1}
                                  {{
                                    1: 'st',
                                    2: 'nd',
                                    3: 'rd',
                                  }[idx + 1] || 'th'}
                                </option>
                              ))}
                            </Field.SingleSelect>
                            day of the next month
                          </ContainerControl>
                        </Container>
                      </Form.Control>
                    </Form.Section>
                  )}

                  <Form.Section
                    title="Client Settings"
                    subtitle="Ruddr can automatically create a unique Client Code for each new client.">
                    <Form.Control>
                      <Field.PaymentTermsSelect name="paymentTermsId" placeholder="Default Payment Terms" />
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <ContainerControl>
                          <Field.Checkbox name="generateClientCode" label="Automatically generate a Client Code" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="By default, client codes start at 1000 and auto-increment when creating a new client. You can adjust the start number and format by using the link below. If the next code is in use, Ruddr will create the client using the next available code."
                          />
                        </ContainerControl>
                        <ModalLink onClick={actions.showClientCodeSettings}>
                          Change the client code seed number and format
                        </ModalLink>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <Field.Checkbox name="uniqueClientCode" label="Client codes must be unique" />
                      </Container>
                    </Form.Control>
                  </Form.Section>

                  <Form.Section
                    title="Project Settings"
                    subtitle="Project defaults in this section will be used when any new project is created. Also, Ruddr can automatically create a unique Project Code for each new project.">
                    <Form.Control>
                      <Container>
                        <ContainerControl>
                          <Field.Checkbox name="generateProjectCode" label="Automatically generate a Project Code" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="By default, project codes start at 1000 and auto-increment when creating a new project. You can adjust the start number and format by using the link below. If the next code is in use, Ruddr will create the project using the next available code."
                          />
                        </ContainerControl>
                        <ModalLink onClick={actions.showProjectCodeSettings}>
                          Change the project code seed number and format
                        </ModalLink>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <Field.Checkbox name="uniqueProjectCode" label="Project codes must be unique" />
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <ContainerLabel>Default settings:</ContainerLabel>
                        <ContainerControl>
                          <Field.Checkbox
                            name="projectDefaultUseRoles"
                            label="Use roles"
                            onChange={handleProjectDefaultUseRolesChange}
                          />
                        </ContainerControl>
                        <ContainerControl>
                          <Field.Checkbox name="projectDefaultTrackBudget" label="Track overall budget" />
                        </ContainerControl>
                        {features.projectHealth && (
                          <ContainerControl>
                            <Field.Checkbox name="projectDefaultUseHealthReports" label="Track project health" />
                          </ContainerControl>
                        )}
                        {features.clientApprovals && (
                          <ContainerControl>
                            <LabelTooltip>
                              <Field.Checkbox
                                label="Enable client approvals"
                                name="projectDefaultEnableClientApprovals"
                              />
                              <HelpTooltip
                                message="Allows you to send time and expense approvals to the client."
                                style={{ marginLeft: '0.5rem' }}
                              />
                            </LabelTooltip>
                          </ContainerControl>
                        )}
                        <ContainerControl>
                          <Field.Checkbox
                            label="Notify members when they are added to a project team"
                            name="projectDefaultAssignmentNotifications"
                          />
                        </ContainerControl>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <ContainerLabel>Default time tracking rules:</ContainerLabel>
                        <ContainerControl>
                          <Field.Checkbox
                            name="projectDefaultTimeRequireNotes"
                            label="Every time entry must have notes"
                          />
                        </ContainerControl>
                        <ContainerControl>
                          <Field.Checkbox
                            name="projectDefaultTimeRequireTask"
                            label="Every time entry must be associated with a task"
                          />
                        </ContainerControl>
                        <ContainerControl>
                          <Field.Checkbox
                            name="projectDefaultTimeAssignedRole"
                            label="Members can only track time to assigned roles"
                            disabled={!values.projectDefaultUseRoles}
                          />
                        </ContainerControl>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <ContainerLabel>Default revenue recognition method for fixed fee projects:</ContainerLabel>
                        <ContainerControl>
                          <Field.RadioGroup name="revenueRecognitionMethod">
                            {_.map(revenueRecognitionMethods, (option) => (
                              <Radio
                                key={option.id}
                                value={option.id}
                                label={option.name}
                                disabled={!features.manualRevenueRecognition}
                              />
                            ))}
                          </Field.RadioGroup>
                        </ContainerControl>
                      </Container>
                    </Form.Control>

                    <Form.Control>For projects that use automated revenue recognition, run the process:</Form.Control>

                    <Form.Control>
                      <CenteredRadio>
                        <Field.RadioGroup name="autoRevRecInterval" direction="vertical">
                          <Radio
                            value="week"
                            label={
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                On
                                <div onClick={(e) => e.preventDefault()}>
                                  <Field.SingleSelect
                                    name="autoRevRecWeekDay"
                                    materialPlaceholder={false}
                                    style={{ width: '9.5rem', margin: '0 0.5rem' }}
                                    disabled={values.autoRevRecInterval !== 'week'}>
                                    {[
                                      { label: 'Monday', value: 1 },
                                      { label: 'Tuesday', value: 2 },
                                      { label: 'Wednesday', value: 3 },
                                      { label: 'Thursday', value: 4 },
                                      { label: 'Friday', value: 5 },
                                      { label: 'Saturday', value: 6 },
                                    ].map(({ label, value }) => (
                                      <option key={value} value={value}>
                                        {label}
                                      </option>
                                    ))}
                                  </Field.SingleSelect>
                                </div>
                                of each week (for the prior week)
                              </div>
                            }
                          />

                          <Radio
                            value="month"
                            label={
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                On the
                                <div onClick={(e) => e.preventDefault()}>
                                  <Field.SingleSelect
                                    name="autoRevRecMonthDay"
                                    materialPlaceholder={false}
                                    style={{ width: '9.5rem', margin: '0 0.5rem' }}
                                    disabled={values.autoRevRecInterval !== 'month'}>
                                    {[...Array(20).keys()].map((_, idx) => (
                                      <option key={idx} value={idx + 1}>
                                        {formatOrdinal(idx + 1)}
                                      </option>
                                    ))}
                                  </Field.SingleSelect>
                                </div>{' '}
                                day of each month (for the prior month)
                              </div>
                            }
                          />
                        </Field.RadioGroup>
                      </CenteredRadio>
                    </Form.Control>

                    <Form.Control>
                      <Container>
                        <ContainerLabel>
                          Default revenue attribution method for fixed fee projects:
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="How recognized revenue will be attributed to project team members."
                          />
                        </ContainerLabel>
                        <ContainerControl>
                          <Field.RadioGroup name="revenueAttributionMethod">
                            {_.map(revenueAttributionMethods, (option) => (
                              <Radio key={option.id} value={option.id} label={option.name} />
                            ))}
                          </Field.RadioGroup>
                        </ContainerControl>
                      </Container>
                    </Form.Control>

                    <Form.Control>
                      <Container>
                        <ContainerLabel>Default time approval:</ContainerLabel>
                        <ContainerControl>
                          <Field.RadioGroup name="projectDefaultIsTimeApprovalRequired">
                            <Radio value={false} label="Automatic" />
                            <Radio value={true} label="Manual" />
                          </Field.RadioGroup>

                          {features.approvalWorkflow && (
                            <div style={{ marginLeft: '2rem', flex: '.4' }}>
                              <Button
                                isOutline
                                disabled={!values.projectDefaultIsTimeApprovalRequired}
                                onClick={handleTimeApprovalEdit}>
                                Set Default Workflow
                              </Button>
                            </div>
                          )}
                        </ContainerControl>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <ContainerLabel>Default expense approval:</ContainerLabel>
                        <ContainerControl>
                          <Field.RadioGroup name="projectDefaultIsExpensesApprovalRequired">
                            <Radio value={false} label="Automatic" />
                            <Radio value={true} label="Manual" />
                          </Field.RadioGroup>

                          {features.approvalWorkflow && (
                            <div style={{ marginLeft: '2rem', flex: '.4' }}>
                              <Button
                                isOutline
                                disabled={!values.projectDefaultIsExpensesApprovalRequired}
                                onClick={handleExpenseApprovalEdit}>
                                Set Default Workflow
                              </Button>
                            </div>
                          )}
                        </ContainerControl>
                      </Container>
                    </Form.Control>
                  </Form.Section>
                  {features.allocations && (
                    <Form.Section
                      title="Allocation Settings"
                      subtitle="These settings allow you to configure defaults related to resource allocation.">
                      <Form.Control>
                        <Container>
                          <ContainerLabel>Default allocation method:</ContainerLabel>
                          <ContainerControl>
                            <Field.RadioGroup
                              name="defaultAllocationUnit"
                              direction="vertical"
                              onChange={({ target: { value } }) => setFieldValue('defaultAllocationUnit', value)}>
                              <Radio value="day" label="Hours per Day" />
                              <Radio value="week" label="Hours per Week" />
                              <Radio value="month" label="Hours per Month" />
                              <Radio value="allocation" label="Total Hours" />
                              <Radio value="ratio_of_capacity" label="Percentage of the Member's Capacity" />
                            </Field.RadioGroup>
                          </ContainerControl>
                        </Container>
                      </Form.Control>

                      <Form.Control>
                        <ContainerControl>
                          <Field.Checkbox
                            name="defaultAllocateOnTimeOffDays"
                            label="Allocate on days when the member has time off"
                          />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="When checked, Ruddr will allocate time to a day where the member has a full day of time off scheduled."
                          />
                        </ContainerControl>
                      </Form.Control>

                      <Form.Control>
                        <ContainerControl>
                          <Field.Checkbox
                            name="showAllocationsOnTimeCalendar"
                            label="Show allocations on the week view of the time screen"
                          />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="Allow members to see their allocations on the week view of the time screen and easily create time entries from those allocations."
                          />
                        </ContainerControl>
                      </Form.Control>
                    </Form.Section>
                  )}
                  {features.utilization && (
                    <Form.Section
                      title="Utilization Settings"
                      subtitle="These settings allow you to configure defaults related to utilization tracking.">
                      <Form.Control>
                        <Container>
                          <Field.Checkbox
                            name="defaultAdjustForTimeOff"
                            label="Default utilization values to adjust for Time Off"
                          />
                        </Container>
                      </Form.Control>
                    </Form.Section>
                  )}
                  <Form.Section
                    title="Task Settings"
                    subtitle="Ruddr can automatically create a unique Task Code for each new task.">
                    <Form.Control>
                      <Container>
                        <ContainerControl>
                          <Field.Checkbox name="generateTaskCode" label="Automatically generate a Task Code" />
                          <HelpTooltip
                            style={{ marginLeft: '.5rem' }}
                            message="By default, task codes start at 1000 and auto-increment when creating a new task. You can adjust the start number and format by using the link below. If the next code is in use, Ruddr will create the task using the next available code."
                          />
                        </ContainerControl>
                        <ModalLink onClick={actions.showTaskCodeSettings}>
                          Change the task code seed number and format
                        </ModalLink>
                      </Container>
                    </Form.Control>
                    <Form.Control>
                      <Container>
                        <Field.Checkbox name="uniqueTaskCode" label="Task codes must be unique" />
                      </Container>
                    </Form.Control>
                  </Form.Section>

                  <Form.Actions>
                    <DeleteButton onClick={actions.showDeleteConfirmation}>Delete</DeleteButton>
                    <ActionsContainer>
                      <div>{status && <FormMessage.Error>{message}</FormMessage.Error>}</div>
                      <Buttons style={{ marginLeft: '1rem' }}>
                        <CancelButton disabled={!dirty} onClick={handleCancel}>
                          Cancel
                        </CancelButton>
                        <SaveButton isLoading={isSubmitting} ok={saved} onClick={submitForm}>
                          Save
                        </SaveButton>
                      </Buttons>
                    </ActionsContainer>
                  </Form.Actions>
                  <PromptNavigation when={dirty} />
                </Stack>
              );
            }}
          </Formik>
          {
            {
              deleteConfirmation: <WorkspaceDeleteConfirmation onClose={actions.closeDialog} />,
              projectCode: <ProjectCodeModal onClose={actions.closeDialog} />,
              clientCode: <ClientCodeModal onClose={actions.closeDialog} />,
              taskCode: <TaskCodeModal onClose={actions.closeDialog} />,
            }[dialog]
          }
        </SettingsPage>
      </Page>

      {timeApproval && (
        <ApprovalWorkflowEditor
          value={timeApproval}
          onSubmit={handleTimeApprovalSubmit}
          onClose={() => setTimeApproval(false)}
          title="Edit Default Workflow"
        />
      )}

      {expenseApproval && (
        <ApprovalWorkflowEditor
          value={expenseApproval}
          onSubmit={handleExpenseApprovalSubmit}
          onClose={() => setExpenseApproval(false)}
          title="Edit Default Workflow"
        />
      )}
    </>
  );
}

export default WorkspaceSettingsPage;
