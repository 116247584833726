import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  FiltersBar,
  IndustryFilter,
  InlineTooltip,
  LinkButton,
  LocationFilter,
  MemberFilter,
  Page,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectHealthFilter,
  ProjectRecordStatusFilter,
  ProjectRoleFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  SearchInput,
  SingleSelect,
} from '~/components';
import _ from 'lodash';
import { useAuth, useFeatures } from '~/hooks';
import { intervalOptions, mimeTypes } from '~/utils';
import CompletedOnPeriodFilter from '../reports/components/CompletedOnPeriodFilter.jsx';
import CreatedAtPeriodFilter from '../reports/components/CreatedAtPeriodFilter.jsx';
import EndPeriodFilter from '../reports/components/EndPeriodFilter.jsx';
import ExportDropdown from './ExportDropdown';
import Filter from '~/components/filters/Filter.js';
import FilterButton from '../reports/components/FilterButton.jsx';
import FiltersDrawer from '../reports/components/FiltersDrawer.jsx';
import FiltersGroup from '../reports/components/FiltersGroup.jsx';
import intervalsByScope from '~/utils/intervalsByScope.js';
import PeriodNavFilter from '../reports/components/PeriodNavFilter.jsx';
import StartPeriodFilter from '../reports/components/StartPeriodFilter.jsx';

export default function ProjectsListFilters({ onChange, onExport, params }) {
  const auth = useAuth();
  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  return (
    <>
      <Page.Header>
        <Page.Title>Projects</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />

          <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
            Create a Project
            {!auth.projects.create && (
              <InlineTooltip message="Your security role prohibits you from creating projects." />
            )}
          </LinkButton>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          {(params.startedDatePeriod?.start || params.startedDatePeriod?.end) && (
            <PeriodNavFilter
              materialPlaceholder="Start Date Range"
              value={params.startedDatePeriod}
              onChange={(value) => onChange({ startedDatePeriod: value })}
            />
          )}

          {(params.endedDatePeriod?.start || params.endedDatePeriod?.end) && (
            <PeriodNavFilter
              materialPlaceholder="End Date Range"
              value={params.endedDatePeriod}
              onChange={(value) => onChange({ endedDatePeriod: value })}
            />
          )}

          {(params.createdAtPeriod?.start || params.createdAtPeriod?.end) && (
            <PeriodNavFilter
              materialPlaceholder="Create Date Range"
              value={params.createdAtPeriod}
              onChange={(value) => onChange({ createdAtPeriod: value })}
            />
          )}

          {(params.completedOnPeriod?.start || params.completedOnPeriod?.end) && (
            <PeriodNavFilter
              materialPlaceholder="Completed Date Range"
              value={params.completedOnPeriod}
              onChange={(value) => onChange({ completedOnPeriod: value })}
            />
          )}

          <SearchInput
            value={params.q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
          />

          {features.practices && <PracticeFilter name="practices" value={params.practices} onChange={handleChange} />}

          <ProjectTagFilter name="tags" value={params.tags} onChange={handleChange} />

          <MemberFilter
            materialPlaceholder="Project Admin"
            name="administrators"
            value={params.administrators}
            onChange={handleChange}
          />

          <ClientFilter name="clients" value={params.clients} onChange={handleChange} />

          <ProjectStatusFilter name="statuses" value={params.statuses} onChange={handleChange} />

          <ProjectBillingTypeFilter
            name="billingTypeIds"
            materialPlaceholder="Billing Type"
            value={params.billingTypeIds}
            onChange={handleChange}
          />

          <ProjectRecordStatusFilter
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Project Archived"
            materialAlwaysVisible
            value={params.recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </ProjectRecordStatusFilter>

          <ClientFiltersBar filters={params} onChange={onChange} />

          <ProjectFiltersBar filters={params} onChange={onChange} />
        </FiltersBar>
      </Page.Filters>

      <Filters values={params} isOpen={filtersVisible} onApply={onChange} onClose={hideFilters} />
    </>
  );
}

function ClientFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.clientRecordStatusId) && (
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ value }) => onChange({ clientRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.clientPractices) && (
        <PracticeFilter
          materialPlaceholder="Client Practice"
          value={filters.clientPractices}
          onChange={({ value }) => onChange({ clientPractices: value })}
        />
      )}

      {!_.isEmpty(filters.clientSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Client Sales Representative"
          value={filters.clientSalesRepresentatives}
          onChange={({ value }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {!_.isEmpty(filters.clientOwners) && (
        <MemberFilter
          materialPlaceholder="Client Relationship Owner"
          value={filters.clientOwners}
          onChange={({ value }) => onChange({ clientOwners: value })}
        />
      )}

      {!_.isEmpty(filters.clientTags) && (
        <ClientTagFilter value={filters.clientTags} onChange={({ value }) => onChange({ clientTags: value })} />
      )}

      {!_.isEmpty(filters.clientLocations) && (
        <LocationFilter
          materialPlaceholder="Client Location"
          value={filters.clientLocations}
          onChange={({ value }) => onChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(filters.clientIndustries) && (
        <IndustryFilter
          materialPlaceholder="Client Industry"
          value={filters.clientIndustries}
          onChange={({ value }) => onChange({ clientIndustries: value })}
        />
      )}
    </>
  );
}

function ProjectFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.projects) && (
        <ProjectFilter
          value={filters.projects}
          onChange={({ value }) => {
            const result = { projects: value };

            if (_.has(filters, 'projectRoles')) result.projectRoles = [];

            if (_.has(filters, 'projectTasks')) result.projectTasks = [];

            onChange(result);
          }}
        />
      )}

      {!_.isEmpty(filters.projectRoles) && (
        <ProjectRoleFilter value={filters.projectRoles} onChange={({ value }) => onChange({ projectRoles: value })} />
      )}

      {!_.isEmpty(filters.projectBudgetHealth) && (
        <ProjectHealthFilter
          materialPlaceholder="Budget Health"
          value={filters.projectBudgetHealth}
          onChange={({ value }) => onChange({ projectBudgetHealth: value })}
        />
      )}

      {!_.isEmpty(filters.projectScheduleHealth) && (
        <ProjectHealthFilter
          materialPlaceholder="Schedule Health"
          value={filters.projectScheduleHealth}
          onChange={({ value }) => onChange({ projectScheduleHealth: value })}
        />
      )}

      {!_.isEmpty(filters.projectClientSatisfaction) && (
        <ProjectHealthFilter
          materialPlaceholder="Client Satisfaction"
          value={filters.projectClientSatisfaction}
          onChange={({ value }) => onChange({ projectClientSatisfaction: value })}
        />
      )}

      {!_.isEmpty(filters.projectTeamSatisfaction) && (
        <ProjectHealthFilter
          materialPlaceholder="Team Satisfaction"
          value={filters.projectTeamSatisfaction}
          onChange={({ value }) => onChange({ projectTeamSatisfaction: value })}
        />
      )}

      {!_.isEmpty(filters.projectTypes) && (
        <ProjectTypeFilter value={filters.projectTypes} onChange={({ value }) => onChange({ projectTypes: value })} />
      )}

      {!_.isEmpty(filters.projectSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Sales Representative"
          value={filters.projectSalesRepresentatives}
          onChange={({ value }) => onChange({ projectSalesRepresentatives: value })}
        />
      )}
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </FiltersDrawer>
  );
}

function ClientFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Client Filters"
      filters={[
        filters.clients,
        filters.clientRecordStatusId,
        filters.clientPractices,
        filters.clientSalesRepresentatives,
        filters.clientOwners,
        filters.clientTags,
        filters.clientLocations,
        filters.clientIndustries,
      ]}>
      <Filter>
        <ClientFilter
          name="clients"
          value={filters.clients}
          onChange={({ target: { value } }) => onChange({ clients: value })}
        />
      </Filter>

      <Filter>
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ target: { value } }) => onChange({ clientRecordStatusId: value })}
        />
      </Filter>

      {features.practices && (
        <Filter>
          <PracticeFilter
            value={filters.clientPractices}
            materialPlaceholder="Client Practice"
            onChange={({ target: { value } }) => onChange({ clientPractices: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberFilter
          value={filters.clientSalesRepresentatives}
          materialPlaceholder="Client Sales Representative"
          onChange={({ target: { value } }) => onChange({ clientSalesRepresentatives: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.clientOwners}
          materialPlaceholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onChange({ clientOwners: value })}
        />
      </Filter>

      <Filter>
        <ClientTagFilter
          value={filters.clientTags}
          onChange={({ target: { value } }) => onChange({ clientTags: value })}
        />
      </Filter>

      <Filter>
        <LocationFilter
          value={filters.clientLocations}
          materialPlaceholder="Client Location"
          onChange={({ target: { value } }) => onChange({ clientLocations: value })}
        />
      </Filter>

      <Filter>
        <IndustryFilter
          value={filters.clientIndustries}
          materialPlaceholder="Client Industry"
          onChange={({ target: { value } }) => onChange({ clientIndustries: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}

function ProjectFiltersGroup({ filters, onChange }) {
  const auth = useAuth();
  const features = useFeatures();

  let intervals = intervalsByScope['day'];

  intervals = [intervalOptions.all_dates].concat(intervals);

  return (
    <FiltersGroup
      label="Project Filters"
      filters={[
        filters.startedDatePeriod?.start,
        filters.startedDatePeriod?.end,
        filters.endedDatePeriod?.start,
        filters.endedDatePeriod?.end,
        filters.createdAtPeriod?.start,
        filters.createdAtPeriod?.end,
        filters.completedOnPeriod?.start,
        filters.completedOnPeriod?.end,
        filters.projects,
        filters.practices,
        filters.tags,
        filters.administrators,
        filters.statuses,
        filters.billingTypeIds,
        filters.recordStatusId,
        filters.projectRoles,
        filters.projectTypes,
        filters.projectSalesRepresentatives,
        filters.projectBudgetHealth,
        filters.projectScheduleHealth,
        filters.projectClientSatisfaction,
        filters.projectTeamSatisfaction,
      ]}>
      <Filter>
        <StartPeriodFilter
          intervals={intervals}
          placeholder="All"
          materialPlaceholder="Project Start Date"
          materialAlwaysVisible
          value={filters.startedDatePeriod}
          onChange={({ target: { value } }) => onChange({ startedDatePeriod: value })}
        />
      </Filter>

      <Filter>
        <EndPeriodFilter
          intervals={intervals}
          placeholder="All"
          materialPlaceholder="Project End Date"
          materialAlwaysVisible
          value={filters.endedDatePeriod}
          onChange={({ target: { value } }) => onChange({ endedDatePeriod: value })}
        />
      </Filter>

      <Filter>
        <CreatedAtPeriodFilter
          intervals={intervals}
          placeholder="All"
          materialPlaceholder="Project Create Date"
          materialAlwaysVisible
          value={filters.createdAtPeriod}
          onChange={({ target: { value } }) => onChange({ createdAtPeriod: value })}
        />
      </Filter>

      <Filter>
        <CompletedOnPeriodFilter
          intervals={intervals}
          placeholder="All"
          materialPlaceholder="Project Completed Date"
          materialAlwaysVisible
          value={filters.completedOnPeriod}
          onChange={({ target: { value } }) => onChange({ completedOnPeriod: value })}
        />
      </Filter>

      <Filter>
        <ProjectFilter
          clients={filters.clients}
          value={filters.projects}
          onChange={({ target: { value } }) => {
            const result = {
              projects: value,
            };

            if (_.has(filters, 'projectRoles'))
              result.projectRoles =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectRoles;

            if (_.has(filters, 'projectTasks'))
              result.projectTasks =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectTasks;

            onChange(result);
          }}
        />
      </Filter>

      <Filter>
        <PracticeFilter
          name="practices"
          value={filters.practices}
          onChange={({ target: { value } }) => onChange({ practices: value })}
        />
      </Filter>

      <Filter>
        <ProjectTagFilter
          name="tags"
          value={filters.tags}
          onChange={({ target: { value } }) => onChange({ tags: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          materialPlaceholder="Project Admin"
          name="administrators"
          value={filters.administrators}
          onChange={({ target: { value } }) => onChange({ administrators: value })}
        />
      </Filter>

      <Filter>
        <ProjectStatusFilter
          name="statuses"
          value={filters.statuses}
          onChange={({ target: { value } }) => onChange({ statuses: value })}
        />
      </Filter>

      <Filter>
        <ProjectBillingTypeFilter
          name="billingTypeIds"
          materialPlaceholder="Billing Type"
          value={filters.billingTypeIds}
          onChange={({ target: { value } }) => onChange({ billingTypeIds: value })}
        />
      </Filter>

      <Filter>
        <SingleSelect
          name="recordStatusId"
          placeholder="All"
          materialPlaceholder="Project Archived"
          materialAlwaysVisible
          value={filters.recordStatusId}
          showEmptyOption
          onChange={({ target: { value } }) => onChange({ recordStatusId: value })}>
          <option value="active">No</option>
          <option value="archived">Yes</option>
        </SingleSelect>
      </Filter>

      {_.has(filters, 'projectRoles') && filters.projects?.length === 1 && filters.projects[0]?.useRoles && (
        <Filter>
          <ProjectRoleFilter
            project={filters.projects[0]}
            value={filters.projectRoles}
            onChange={({ target: { value } }) => onChange({ projectRoles: value })}
          />
        </Filter>
      )}

      {features.projectHealth && auth.projects.viewHealth && (
        <>
          {_.has(filters, 'projectBudgetHealth') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Budget Health"
                value={filters.projectBudgetHealth}
                onChange={({ target: { value } }) => onChange({ projectBudgetHealth: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectScheduleHealth') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Schedule Health"
                value={filters.projectScheduleHealth}
                onChange={({ target: { value } }) => onChange({ projectScheduleHealth: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectClientSatisfaction') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Client Satisfaction"
                value={filters.projectClientSatisfaction}
                onChange={({ target: { value } }) => onChange({ projectClientSatisfaction: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectTeamSatisfaction') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Team Satisfaction"
                value={filters.projectTeamSatisfaction}
                onChange={({ target: { value } }) => onChange({ projectTeamSatisfaction: value })}
              />
            </Filter>
          )}
        </>
      )}

      <Filter>
        <ProjectTypeFilter
          value={filters.projectTypes}
          onChange={({ target: { value } }) => onChange({ projectTypes: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.projectSalesRepresentatives}
          materialPlaceholder="Project Sales Representative"
          onChange={({ target: { value } }) => onChange({ projectSalesRepresentatives: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}
