import _ from 'lodash';
import { del, get, patch, post } from './agent';

const qbo = {
  public: {
    postRedirectUri: ({ uri }) => post('/xero/redirect-uri', { uri }),
    signup: {
      consentUri: () => get('/xero/signup/consent-uri'),
      user: ({ uri }) => post(`/xero/signup/user`, { uri }),
    },
  },

  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'xero']).compact().join('/');

    return {
      getStatus: () => get(url.concat('/status')),
      getIntegration: () => get(url.concat('/integration')),
      remove: () => del(url.concat('/integration')),
      getConnection: () => get(url.concat('/connection')),
      getConfiguration: () => get(url.concat('/configuration')),
      updateConfiguration: (body) => patch(url.concat('/configuration'), body),
      setupConfiguration: () => post(url.concat('/setup/configuration')),
      getOrganisation: () => get(url.concat('/organisation')),
      getClients: () => get(url.concat('/clients')),
      getContacts: () => get(url.concat('/contacts')),
      getClientMappings: () => get(url.concat('/mapping/clients')),
      updateClientMappings: (body) => post(url.concat('/mapping/clients'), body),
      setupClients: () => post(url.concat('/setup/clients')),
      getInvoiceItems: () => get(url.concat('/invoice-items')),
      getItems: () => get(url.concat('/items')),
      getAccounts: () => get(url.concat('/accounts')),
      getInvoiceItemMappings: () => get(url.concat('/mapping/invoice-items')),
      updateInvoiceItemMappings: (body) => post(url.concat('/mapping/invoice-items'), body),
      setupInvoiceItems: () => post(url.concat('/setup/invoice-items')),
      getTaxRates: () => get(url.concat('/tax-rates')),
      getXeroTaxRates: () => get(url.concat('/xero-tax-rates')),
      getTaxRateMappings: () => get(url.concat('/mapping/tax-rates')),
      updateTaxRateMappings: (body) => post(url.concat('/mapping/tax-rates'), body),
      setupTaxRates: () => post(url.concat('/setup/tax-rates')),
      getPaymentAccounts: () => get(url.concat('/payment-accounts')),
    };
  },
};

export default qbo;
