import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { DeleteConfirmation, ExportDialog } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, mimeTypes } from '~/utils';
import ActionBar from '../components/action-bar/ActionBar';
import Loader from '../components/Loader';
import { Allocations, Grid, Schedule, Sidebar, useGroups, useSchedule } from '../components/schedule';
import AllocationDetails from '../dialogs/AllocationDetails';
import AllocationForm from '../dialogs/AllocationForm';
import Cells from './cells/Cells';
import Filters from './filters/Filters';
import FiltersBar from './filters/FiltersBar';
import useClientMetrics from './hooks/useClientMetrics';
import useHeaderMetrics from './hooks/useHeaderMetrics';
import useProjectMetrics from './hooks/useProjectMetrics';
import Empty from './sidebar/Empty';
import Group from './sidebar/Group';
import HeaderLabel from './sidebar/HeaderLabel';
import Row from './sidebar/Row';

const periods = {
  day: 3,
  week: 7,
  month: 11,
};

const merge = (source, target, key) => {
  const sourceObject = _.keyBy(source, key);
  const targetObject = _.keyBy(target, key);
  const merged = _.merge(sourceObject, targetObject);
  const result = _.values(merged);
  return result;
};

export default function ProjectSchedule() {
  const documentTitle = useDocumentTitle('Resource Allocations');
  const route = useRouteMatch();
  const history = useHistory();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const auth = useAuth();

  const [query, setQuery] = useState({
    status: 'loading',
    action: 'load',
    fetching: false,
    projects: [],
    resources: [],
    allocations: [],
    metrics: {
      total: [],
      clients: [],
      projects: [],
    },
  });

  const location = useLocation();

  const [params, setParams] = useState(() => ({
    date: moment().format(dateFormats.isoDate),
    unit: 'week',
    allocationBillableTypes: [],
    projectAdmin: [],
    projectPractice: [],
    projectBusinessUnits: [],
    recordStatusId: 'active',
    client: [],
    clientOwner: [],
    clientTags: [],
    clientLocations: [],
    clientIndustries: [],
    clientBusinessUnits: [],
    billingType: [],
    status: [],
    projectTags: [],
    projectTypes: [],
    project: [],
    onlyAllocatedProjects: false,
    groups: null,

    // Resource filters
    resourcePractice: [],
    resourceLocation: [],
    resourceStatusId: 'active',
    resourceTypeId: null,
    resourceDiscipline: [],
    resourceSkill: [],
    memberBillableTypeId: null,
    memberEmploymentType: [],
    jobTitles: [],
    memberTags: [],
    memberLocations: [],
    member: [],
  }));

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => (moment(value).isValid() ? moment(value).format(dateFormats.isoDate) : null),
        },
        unit: { default: 'week', valid: ['day', 'week', 'month'] },
        allocationBillableTypes: searchParamsConfig.allocationBillableTypes,
        projectAdmin: { ...searchParamsConfig.members, key: 'projectAdmin' },
        projectPractice: searchParamsConfig.practices,
        projectBusinessUnits: searchParamsConfig.businessUnits,
        recordStatusId: { default: 'active', ...searchParamsConfig.recordStatusId },
        client: searchParamsConfig.clients,
        clientOwner: { ...searchParamsConfig.members, key: 'clientOwner' },
        clientTags: searchParamsConfig.clientTags,
        clientLocations: { ...searchParamsConfig.locations, key: 'clientLocation' },
        clientIndustries: { ...searchParamsConfig.industries, key: 'clientIndustry' },
        clientBusinessUnits: searchParamsConfig.businessUnits,
        project: searchParamsConfig.projects,
        billingType: searchParamsConfig.projectBillingTypes,
        status: searchParamsConfig.projectStatuses,
        projectTags: searchParamsConfig.projectTags,
        projectTypes: searchParamsConfig.projectTypes,
        onlyAllocatedProjects: searchParamsConfig.boolean,
        groups: { valid: ['expanded', 'collapsed'] },

        // Resource filters
        resourcePractice: searchParamsConfig.practices,
        resourceLocation: searchParamsConfig.locations,
        resourceDiscipline: searchParamsConfig.disciplines,
        resourceSkill: searchParamsConfig.skills,
        resourceStatusId: { valid: ['active', 'inactive'] },
        resourceTypeId: { valid: ['member', 'placeholder'] },
        member: searchParamsConfig.members,
        memberBillableTypeId: { valid: ['billable', 'non_billable'] },
        memberEmploymentType: searchParamsConfig.employmentTypes,
        jobTitles: searchParamsConfig.jobTitles,
        memberTags: searchParamsConfig.memberTags,
        memberLocations: { ...searchParamsConfig.locations, key: 'memberLocation' },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'project_allocations',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const [allocationFormInitialValues, setAllocationFormInitialValues] = useState(null);

  const { start, end } = useMemo(() => {
    let start;
    let end;

    switch (params.unit) {
      case 'day':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(params.date).startOf('month').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate);
        break;
    }

    return { start, end };
  }, [params.date, params.unit]);

  const [projectMembers, setProjectMembers] = useState({});

  const handleShowTeam = useCallback(
    async (projectId) => {
      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .allocations()
          .projectSchedule.projectTeam({
            projectId,
            resourcePracticeId: params.resourcePractice?.map((v) => v.id),
            resourceLocationId: params.resourceLocation?.map((v) => v.id),
            resourceDisciplineId: params.resourceDiscipline?.map((v) => v.id),
            resourceSkillId: params.resourceSkill?.map((v) => v.id),
            resourceStatusId: params.resourceStatusId ?? undefined,
            resourceTypeId: params.resourceTypeId ?? undefined,

            memberId: params.member?.map((v) => v.id),
            memberBillableTypeId: params.memberBillableTypeId ?? undefined,
            memberEmploymentTypeId: params.memberEmploymentType?.map((v) => v.id),
            jobTitleId: params.jobTitles?.map((v) => v.id),
            memberTagId: params.memberTags?.map((v) => v.id),
            memberLocationId: params.memberLocations?.map((v) => v.id),
          });

        setProjectMembers((state) => ({ ...state, [projectId]: data }));
        return data;
      } catch (error) {
        toast.error('An error has occurred. Please try again.');
      }
    },
    [
      toast,
      api,
      workspace.id,
      params.resourceStatusId,
      params.resourceTypeId,
      params.resourceDiscipline,
      params.resourceSkill,
      params.resourcePractice,
      params.resourceLocation,
      params.memberBillableTypeId,
      params.memberEmploymentType,
      params.jobTitles,
      params.memberTags,
      params.memberLocations,
      params.member,
    ],
  );

  const data = useMemo(() => {
    const allocations = query.allocations.filter(
      (allocation) => moment(allocation.start).isSameOrBefore(end) && moment(allocation.end).isSameOrAfter(start),
    );

    const resourcesById = _.keyBy(query.resources, 'id');
    const allocationsByProject = _.groupBy(allocations, 'projectId');

    let projects = query.projects;

    if (params.onlyAllocatedProjects) {
      projects = projects.filter((p) => allocations.some((a) => a.projectId === p.id));
    }

    const rows = [];

    const totalRow = {
      id: 'total',
      type: 'header',
      label: 'Total',
      showExpandCollapse: true,
      cells: [],
    };

    if (projects.length > 0) {
      rows.push(totalRow);
    }

    const metrics = {
      clients: _.groupBy(query.metrics.clients, (metric) => `${metric.clientId}`),
      projects: _.groupBy(query.metrics.projects, (metric) => `${metric.id}_${metric.start}`),
      total: _.groupBy(query.metrics.total, (metric) => metric.start),
    };

    const periodCount = moment(end).diff(start, params.unit) + 1;

    for (let index = 0; index < periodCount; index++) {
      const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

      const metric = metrics.total[date] ? metrics.total[date][0] : null;

      totalRow.cells.push({
        key: `total_${date}`,
        mode: 'header',
        type: 'total',
        date,
        allocated: metric?.allocated ?? 0,
      });
    }

    //Group the projects by client
    const projectsByClient = _.groupBy(projects, 'client.id');

    _.forEach(projectsByClient, (projects, key) => {
      const headerRow = {
        id: key,
        type: 'header',
        cells: [],
        client: projects[0].client,
      };

      let headerSubtotal = _.groupBy(metrics.clients[projects[0].client.id], 'start');

      for (let index = 0; index < periodCount; index++) {
        const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);
        const metric = headerSubtotal[date] ? headerSubtotal[date][0] : null;

        headerRow.cells.push({
          key: `${key}_${date}`,
          mode: 'header',
          type: 'header',
          date,
          allocated: metric?.allocated ?? 0,
        });
      }
      rows.push(headerRow);

      for (const project of projects) {
        const group = {
          id: project.id,
          type: 'group',
          component: (props) => (
            <Group
              {...props}
              showGroupActions
              hasMembers={projectMembers[project.id]?.length > 0}
              onShowTeam={!projectMembers[project.id] ? handleShowTeam : undefined}
            />
          ),
          projectId: project.id,
          project,
          hasAllocations: allocationsByProject[project.id]?.length > 0,
          cells: [],
        };
        rows.push(group);

        for (let index = 0; index < periodCount; index++) {
          const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

          const key = `${group.id}_${date}`;

          const metric = metrics.projects[key] ? metrics.projects[key][0] : {};

          group.cells.push({
            key: `${group.id}_${date}`,
            mode: 'standard',
            type: 'group',
            group,
            manage: project.permissions.manageAllocations,
            date,
            allocated: metric.allocated ?? 0,
          });
        }

        const projectResources = {};

        // Order the allocations by resource type and name
        const projectAllocations = _.orderBy(allocationsByProject[project.id] || [], [
          'resourceTypeId',
          (allocation) => resourcesById[allocation.resourceId]?.name,
        ]);

        for (const allocation of projectAllocations) {
          if (!projectResources[allocation.resourceId]) {
            projectResources[allocation.resourceId] = {
              id: `${project.id}_${allocation.resourceId}`,
              resourceId: allocation.resourceId,
              type: 'row',
              projectId: project.id,
              parentId: group.id,
              resource: resourcesById[allocation.resourceId],
              allocations: [],
              cells: [],
            };
          }

          projectResources[allocation.resourceId].allocations.push(allocation);
        }

        if (projectMembers[project.id]) {
          for (const projectMember of projectMembers[project.id]) {
            if (!projectResources[projectMember.memberId]) {
              projectResources[projectMember.memberId] = {
                id: `${project.id}_${projectMember.memberId}`,
                resourceId: projectMember.memberId,
                type: 'row',
                projectId: project.id,
                parentId: group.id,
                resource: projectMember.member,
                allocations: [],
                cells: [],
              };
            }
          }
        }

        _.orderBy(projectResources, (pr) => pr.resource?.name).forEach((value) => {
          // Add cells for each assignment row
          for (let index = 0; index < periodCount; index++) {
            const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

            value.cells.push({
              key: `${value.id}_${date}`,
              mode: 'row',
              type: 'row',
              group,
              manage: project.permissions.manageAllocations,
              row: value,
              date,
            });
          }

          rows.push(value);
        });
      }
    });

    return { rows };
  }, [
    query.allocations,
    query.projects,
    query.resources,
    query.metrics,
    start,
    end,
    params.unit,
    params.onlyAllocatedProjects,
    projectMembers,
    handleShowTeam,
  ]);

  const updateParams = useCallback(
    (params) => {
      setParams((state) => ({ ...state, ...params }));
      searchParams.set(params);
    },
    [searchParams],
  );

  const handleGroupsToggle = useCallback(
    (status) => {
      updateParams({ groups: status });
    },
    [updateParams],
  );

  const { groups, hasExpandedGroups, toggleGroup, toggleGroups } = useGroups({
    rows: data?.rows,
    defaultGroupStatus: params.groups,
    onGroupsToggle: handleGroupsToggle,
  });

  const canvasRef = useRef();
  const bodyRef = useRef();

  const {
    allocations,
    cells,
    styles,
    virtualRows: rows,
  } = useSchedule({
    data,
    start,
    end,
    unit: params.unit,
    groups,
    parentRef: bodyRef,
  });

  const headerMetrics = useHeaderMetrics({
    parentRef: bodyRef,
    headers: useMemo(() => rows.filter((row) => row.type === 'header'), [rows]),
    projects: useMemo(() => data.rows.filter((row) => row.type === 'group'), [data.rows]),
    start,
    end,
    unit: params.unit,
    allocations: query.allocations,
    onFetched: useCallback((metrics) => {
      setQuery((state) => {
        return state.metrics
          ? {
              ...state,
              metrics: {
                ...state.metrics,
                total: merge(state.metrics.total, metrics, (obj) => `${obj.start}`),
              },
            }
          : state;
      });
    }, []),
  });

  const clientMetrics = useClientMetrics({
    parentRef: bodyRef,
    clients: useMemo(() => rows.filter((row) => row.type === 'header'), [rows]),
    projects: useMemo(() => data.rows.filter((row) => row.type === 'group'), [data.rows]),
    start,
    end,
    unit: params.unit,
    allocations: query.allocations,
    onFetched: useCallback((metrics) => {
      setQuery((state) => {
        return state.metrics
          ? {
              ...state,
              metrics: {
                ...state.metrics,
                clients: merge(state.metrics.clients, metrics, (obj) => `${obj.clientId}_${obj.start}`),
              },
            }
          : state;
      });
    }, []),
  });

  const projectMetrics = useProjectMetrics({
    parentRef: bodyRef,
    projects: useMemo(() => rows.filter((row) => row.type === 'group'), [rows]),
    start,
    end,
    unit: params.unit,
    allocations: query.allocations,
    onFetched: useCallback((metrics) => {
      setQuery((state) => {
        return state.metrics
          ? {
              ...state,
              metrics: {
                ...state.metrics,
                projects: merge(state.metrics.projects, metrics, (obj) => `${obj.id}_${obj.start}`),
              },
            }
          : state;
      });
    }, []),
  });

  const fetchProjects = useCallback(async () => {
    setQuery((state) => ({ ...state, action: 'fetch-projects' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .projectSchedule.projects({
        // Client
        clientId: params.client?.map((v) => v.id),
        clientOwnerId: params.clientOwner?.map((v) => v.id),
        clientTagId: params.clientTags?.map((v) => v.id),
        clientLocationId: params.clientLocations?.map((v) => v.id),
        clientIndustryId: params.clientIndustries?.map((v) => v.id),
        clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),

        // Project
        projectId: params.project?.map((v) => v.id),
        projectAdminId: params.projectAdmin?.map((v) => v.id),
        projectPracticeId: params.projectPractice?.map((v) => v.id),
        projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
        recordStatusId: params.recordStatusId ?? undefined,
        billingTypeId: params.billingType?.map((v) => v.id),
        statusId: params.status?.map((v) => v.id),
        projectTagId: params.projectTags?.map((v) => v.id),
        projectTypeId: params.projectTypes?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, action: null, projects: data }));
    return data;
  }, [api, workspace.id, params]);

  const fetchResources = useCallback(async () => {
    setQuery((state) => ({ ...state, action: 'fetch-resources' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .projectSchedule.resources({
        // Resource
        resourcePracticeId: params.resourcePractice?.map((v) => v.id),
        resourceLocationId: params.resourceLocation?.map((v) => v.id),
        resourceDisciplineId: params.resourceDiscipline?.map((v) => v.id),
        resourceSkillId: params.resourceSkill?.map((v) => v.id),
        resourceStatusId: params.resourceStatusId ?? undefined,
        resourceTypeId: params.resourceTypeId ?? undefined,

        // Member
        memberId: params.member?.map((v) => v.id),
        memberBillableTypeId: params.memberBillableTypeId ?? undefined,
        memberEmploymentTypeId: params.memberEmploymentType?.map((v) => v.id),
        jobTitleId: params.jobTitles?.map((v) => v.id),
        memberTagId: params.memberTags?.map((v) => v.id),
        memberLocationId: params.memberLocations?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, action: null, resources: data }));
    return data;
  }, [api, workspace.id, params]);

  const fetchAllocations = useCallback(async () => {
    setQuery((state) => ({ ...state, fetching: true, action: 'fetch-allocations' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .projectSchedule.allocations({
        start,
        end,
        unit: params.unit,

        // Allocation
        allocationBillableTypeId: params.allocationBillableTypes?.map((v) => v.id),

        // Resource
        resourcePracticeId: params.resourcePractice?.map((v) => v.id),
        resourceLocationId: params.resourceLocation?.map((v) => v.id),
        resourceDisciplineId: params.resourceDiscipline?.map((v) => v.id),
        resourceSkillId: params.resourceSkill?.map((v) => v.id),
        resourceStatusId: params.resourceStatusId ?? undefined,
        resourceTypeId: params.resourceTypeId ?? undefined,

        // Member
        memberId: params.member?.map((v) => v.id),
        memberBillableTypeId: params.memberBillableTypeId ?? undefined,
        memberEmploymentTypeId: params.memberEmploymentType?.map((v) => v.id),
        jobTitleId: params.jobTitles?.map((v) => v.id),
        memberTagId: params.memberTags?.map((v) => v.id),
        memberLocationId: params.memberLocations?.map((v) => v.id),

        // Client
        clientId: params.client?.map((v) => v.id),
        clientOwnerId: params.clientOwner?.map((v) => v.id),
        clientTagId: params.clientTags?.map((v) => v.id),
        clientLocationId: params.clientLocations?.map((v) => v.id),
        clientIndustryId: params.clientIndustries?.map((v) => v.id),
        clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),

        // Project
        projectId: params.project?.map((v) => v.id),
        projectAdminId: params.projectAdmin?.map((v) => v.id),
        projectPracticeId: params.projectPractice?.map((v) => v.id),
        projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
        recordStatusId: params.recordStatusId ?? undefined,
        billingTypeId: params.billingType?.map((v) => v.id),
        statusId: params.status?.map((v) => v.id),
        projectTagId: params.projectTags?.map((v) => v.id),
        projectTypeId: params.projectTypes?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, fetching: false, action: null, allocations: data }));

    headerMetrics.initialize();
    clientMetrics.initialize();
    projectMetrics.initialize();

    return data;
  }, [start, end, params, api, workspace.id, headerMetrics, clientMetrics, projectMetrics]);

  const fetchMoreAllocations = useCallback(
    async ({ start, end }) => {
      setQuery((state) => ({ ...state, fetching: true }));

      const { data } = await api.www
        .workspaces(workspace.id)
        .allocations()
        .projectSchedule.allocations({
          start,
          end,
          unit: params.unit,

          // Allocation
          allocationBillableTypeId: params.allocationBillableTypes?.map((v) => v.id),

          // Resource
          resourcePracticeId: params.resourcePractice?.map((v) => v.id),
          resourceLocationId: params.resourceLocation?.map((v) => v.id),
          resourceDisciplineId: params.resourceDiscipline?.map((v) => v.id),
          resourceSkillId: params.resourceSkill?.map((v) => v.id),
          resourceStatusId: params.resourceStatusId ?? undefined,
          resourceTypeId: params.resourceTypeId ?? undefined,

          // Member
          memberId: params.member?.map((v) => v.id),
          memberBillableTypeId: params.memberBillableTypeId ?? undefined,
          memberEmploymentTypeId: params.memberEmploymentType?.map((v) => v.id),
          jobTitleId: params.jobTitles?.map((v) => v.id),
          memberTagId: params.memberTags?.map((v) => v.id),
          memberLocationId: params.memberLocations?.map((v) => v.id),

          // Client
          clientId: params.client?.map((v) => v.id),
          clientOwnerId: params.clientOwner?.map((v) => v.id),
          clientTagId: params.clientTags?.map((v) => v.id),
          clientLocationId: params.clientLocations?.map((v) => v.id),
          clientIndustryId: params.clientIndustries?.map((v) => v.id),
          clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),

          // Project
          projectId: params.project?.map((v) => v.id),
          projectAdminId: params.projectAdmin?.map((v) => v.id),
          projectPracticeId: params.projectPractice?.map((v) => v.id),
          projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
          recordStatusId: params.recordStatusId ?? undefined,
          billingTypeId: params.billingType?.map((v) => v.id),
          statusId: params.status?.map((v) => v.id),
          projectTagId: params.projectTags?.map((v) => v.id),
          projectTypeId: params.projectTypes?.map((v) => v.id),
        });

      setQuery((state) => ({
        ...state,
        status: 'ready',
        action: 'fetch-more',
        fetching: false,
        allocations: merge(state.allocations, data, 'id'),
      }));

      headerMetrics.load({ start, end, unit: params.unit, allocations: data });
      clientMetrics.load({ start, end, unit: params.unit, allocations: data });
      projectMetrics.load({ start, end, unit: params.unit, allocations: data });

      return data;
    },
    [params, api, workspace.id, headerMetrics, clientMetrics, projectMetrics],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'ready' || !['load', 'refetch', 'refetch-allocations'].includes(query.action)) return;

    (async () => {
      switch (query.action) {
        case 'load':
        case 'refetch': {
          await fetchResources();
          await fetchProjects();
          await fetchAllocations();
          break;
        }

        case 'refetch-allocations':
          await fetchAllocations();
          break;
      }

      setQuery((state) => ({ ...state, fetching: false, action: null, status: 'ready' }));
    })();
  }, [searchParamsStatus, query.action, fetchResources, fetchAllocations, fetchProjects]);

  const refetch = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch',
      metrics: {
        total: [],
        clients: [],
        projects: [],
      },
    }));
  };

  const refetchAllocations = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch-allocations',
      metrics: {
        total: [],
        clients: [],
        projects: [],
      },
    }));
  };

  const handleDateChange = (date) => {
    updateParams({ date });
    refetchAllocations();
  };

  const handleDateNavPrevious = () => {
    const date = {
      day: moment(params.date).subtract(1, 'week'),
      week: moment(params.date).subtract(1, 'week'),
      month: moment(params.date).subtract(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).startOf('month').format(dateFormats.isoDate),
        end: moment(date).endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleDateNavNext = () => {
    const date = {
      day: moment(params.date).add(1, 'week'),
      week: moment(params.date).add(1, 'week'),
      month: moment(params.date).add(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).add(periods.day, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).add(periods.week, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).add(periods.month, 'months').startOf('month').format(dateFormats.isoDate),
        end: moment(date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleFormClose = () => {
    setAllocationFormInitialValues(null);
    history.push({ pathname: route.url, search: location.search });
    documentTitle.set('Resource Allocations');
  };

  const handleCreateAllocation = () => {
    const unit = { day: 'isoWeek', week: 'isoWeek', month: 'month' }[params.unit];

    setAllocationFormInitialValues({
      start: moment(params.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(params.date).endOf(unit).format(dateFormats.isoDate),
    });

    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleView = (allocation) => {
    history.push({ pathname: `${route.url}/view/${allocation.id}`, search: location.search });
  };

  const handleEdit = (allocation) => {
    history.push({ pathname: `${route.url}/edit/${allocation.id}`, search: location.search });
  };

  const handleClone = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).clone();
    await fetchAllocations();
    await toast.success('Allocation successfully cloned.');
  };

  const handleSplit = async (allocation, date) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).split({ date });
    await fetchAllocations();
    toast.success('Allocation successfully split.');
  };

  const handleSplitByDay = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByDay();
    await fetchAllocations();
    toast.success('Allocation successfully split by day.');
  };

  const handleSplitByWeek = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByWeek();
    await fetchAllocations();
    toast.success('Allocation successfully split by week.');
  };

  const handleSplitByMonth = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByMonth();
    await fetchAllocations();
    toast.success('Allocation successfully split by month.');
  };

  const handleDelete = async (allocation) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this allocation?</DeleteConfirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).allocations(allocation.id).delete();

    await fetchAllocations();
  };

  const handleSelectCellsEnd = (cells) => {
    const unit = { day: 'day', week: 'isoWeek', month: 'month' }[params.unit];

    const cell = _.first(cells);
    const lastCell = _.last(cells);

    let initialValues = {
      start: moment(cell.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(lastCell.date).endOf(unit).format(dateFormats.isoDate),
    };

    const project = query.projects.find((r) => r.id === cell.group.project.id);

    if (project) {
      initialValues.assignmentTypeId = 'project';
      initialValues.projectId = project.id;
    }

    if (cell.row) {
      const resources = _.unionWith(
        query.resources,
        _(projectMembers)
          .values()
          .flatten()
          .map((pm) => pm.member)
          .compact()
          .value(),
        'id',
      );

      const resource = resources.find((a) => a.id === cell.row.resource.id);

      if (resource) {
        const resourceType = { member: 'member', placeholder: 'placeholder' }[resource.resourceType];

        initialValues.resourceTypeId = resource.resourceType;
        initialValues[resourceType] = resource;
      }
    }

    setAllocationFormInitialValues(initialValues);
    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleUpdateAllocationDates = useCallback(
    async (allocation) => {
      try {
        const body = _.pick(allocation, [
          'unit',
          'start',
          'end',
          'hoursPerDay',
          'hoursPerWeek',
          'hoursPerMonth',
          'hoursPerAllocation',
          'hoursRatioOfCapacity',
        ]);

        // Optimistic update
        setQuery((state) => ({
          ...state,
          allocations: state.allocations.map((a) => (a.id === allocation.id ? { ...a, ...body } : a)),
        }));

        await api.www.workspaces(workspace.id).allocations(allocation.id).upsert(body);
      } catch (error) {
        toast.error(error.message);
      } finally {
        await fetchAllocations();
      }
    },
    [api, fetchAllocations, toast, workspace.id],
  );

  const handleUnitChange = (unit) => {
    if (unit !== params.unit) {
      setQuery((state) => ({ ...state, status: 'grid_loading' }));
      updateParams({ unit });
      refetchAllocations();
    }
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) {
      values = _.omit(values, 'unit', 'date');
      setQuery((state) => ({ ...state, status: 'filtering' }));
      updateParams({ ...values });
      refetch();
      setProjectMembers([]);
    }

    hideFilters();
  };

  const handleFilterChange = (filter) => {
    handleApplyFilters({ ...params, ...filter });
  };

  const handleAllocationSaved = async (allocation) => {
    await fetchAllocations();

    if (groups[allocation.assignmentId]?.state !== 'expanded') {
      toggleGroup({ id: allocation.assignmentId });
    }
  };

  const components = useMemo(
    () => ({
      sidebar: {
        Empty,
        Group,
        Row,
        HeaderLabel,
      },
    }),
    [],
  );

  const confirmation = useConfirmation();

  const handleExport = async (mimeType) => {
    let filename =
      mimeType === mimeTypes.xlsx
        ? `project_allocations_by_${params.unit}.xlsx`
        : `project_allocations_by_${params.unit}.csv`;

    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .allocations()
          .projectSchedule.export(
            {
              assignmentTypeId: ['project'],
              start,
              end,
              unit: params.unit,
              allocationBillableTypeId: params.allocationBillableTypes.map((v) => v.id),
              clientId: params.client?.map((v) => v.id),
              clientOwnerId: params.clientOwner?.map((v) => v.id),
              clientTagId: params.clientTags?.map((v) => v.id),
              clientLocationId: params.clientLocations?.map((v) => v.id),
              clientIndustryId: params.clientIndustries?.map((v) => v.id),
              clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),

              projectId: params.project?.map((v) => v.id),
              projectAdminId: params.projectAdmin?.map((v) => v.id),
              projectPracticeId: params.projectPractice?.map((v) => v.id),
              projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
              projectRecordStatusId: params.recordStatusId ?? undefined,
              projectBillingTypeId: params.billingType?.map((v) => v.id),
              projectStatusId: params.status?.map((v) => v.id),
              projectTagId: params.projectTags?.map((v) => v.id),
              projectTypeId: params.projectTypes?.map((v) => v.id),

              resourcePracticeId: params.resourcePractice?.map((v) => v.id),
              resourceLocationId: params.resourceLocation?.map((v) => v.id),
              resourceDisciplineId: params.resourceDiscipline?.map((v) => v.id),
              resourceSkillId: params.resourceSkill?.map((v) => v.id),
              resourceStatusId: params.resourceStatusId ?? undefined,
              resourceTypeId: params.resourceTypeId ?? undefined,

              memberId: params.member?.map((v) => v.id),
              memberBillableTypeId: params.memberBillableTypeId ?? undefined,
              employmentTypeId: params.memberEmploymentType?.map((v) => v.id),
              jobTitleId: params.jobTitles?.map((v) => v.id),
              memberTagId: params.memberTags?.map((v) => v.id),
              memberLocationId: params.memberLocations?.map((v) => v.id),

              onlyAllocatedProjects: params.onlyAllocatedProjects ? 'true' : undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <ActionBar
        unit={params.unit}
        date={params.date}
        start={start}
        end={end}
        filters={true}
        create={true}
        disabled={query.fetching}
        onCreateAllocation={handleCreateAllocation}
        onDateChange={handleDateChange}
        onDateNavNext={handleDateNavNext}
        onDateNavPrevious={handleDateNavPrevious}
        onUnitChange={handleUnitChange}
        onFilter={showFilters}
        onExport={handleExport}
      />

      <FiltersBar params={params} onFilterChange={handleFilterChange} />

      <Schedule>
        {query.fetching && <Loader />}

        <Grid
          canvasRef={canvasRef}
          bodyRef={bodyRef}
          start={start}
          end={end}
          unit={params.unit}
          rows={rows}
          styles={styles}
          loading={query.status !== 'ready'}
          navigation={true}
          Sidebar={() => (
            <Sidebar
              groups={groups}
              toggleGroup={toggleGroup}
              toggleGroups={toggleGroups}
              hasExpandedGroups={hasExpandedGroups}
              styles={styles}
              rows={rows}
              components={components}
              parentRef={bodyRef}
            />
          )}
          onDateChange={handleDateChange}>
          {query.status === 'ready' && rows.length > 0 && (
            <>
              <Cells
                bodyRef={bodyRef}
                styles={styles}
                metric={params.metric}
                cells={cells}
                rows={rows}
                start={start}
                end={end}
                unit={params.unit}
                onSelectEnd={handleSelectCellsEnd}
              />

              <Allocations
                allocations={allocations}
                rows={rows}
                styles={styles}
                canvasRef={canvasRef}
                parentRef={bodyRef}
                onView={handleView}
                onEdit={handleEdit}
                onClone={handleClone}
                onSplit={handleSplit}
                onSplitByDay={handleSplitByDay}
                onSplitByWeek={handleSplitByWeek}
                onSplitByMonth={handleSplitByMonth}
                onDelete={handleDelete}
                onAllocationDatesChange={handleUpdateAllocationDates}
              />
            </>
          )}
        </Grid>
      </Schedule>

      <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />

      <Switch>
        {auth.allocations.manage && (
          <Route path={[route.path.concat('/new'), route.path.concat('/edit/:allocationId')]}>
            <AllocationForm
              initialValues={allocationFormInitialValues}
              onSaved={handleAllocationSaved}
              onDeleted={fetchAllocations}
              onClose={handleFormClose}
            />
          </Route>
        )}

        <Route path={route.path.concat('/view/:allocationId')}>
          <AllocationDetails onClose={handleFormClose} />
        </Route>

        <Redirect to={route.url.concat(location.search)} />
      </Switch>
    </>
  );
}
