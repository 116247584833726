import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Header = styled.div`
  display: flex;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-color: ${colors.grey5};
  border-bottom: 1px solid ${colors.grey10};
  position: sticky;
  top: -2rem;
  z-index: 3;
`;

function ListViewHeader({ children, ...props }) {
  const columns = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));
  return <Header {...props}>{columns}</Header>;
}

export default ListViewHeader;
