import classNames from 'classnames';
import { Button, DayPickerDropdown, Icon, InlineTooltip, Level } from '~/components';
import { useAuth } from '~/hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import ExportDropdown from '~/routes/app/reports/components/ExportDropdown';
import mimeTypes from '~/utils/mimeTypes';
import {
  ActionBarStyles,
  CircleButton,
  DateNav,
  DateNavButton,
  DatePickerToggleButton,
  ViewNav,
} from './ActionBarStyles';

export default function ActionBar({
  start,
  unit,
  date,
  children,
  filters = true,
  create = true,
  disabled,
  onCreateAllocation,
  onDateChange,
  onDateNavPrevious,
  onDateNavNext,
  onUnitChange,
  onFilter,
  onExport,
}) {
  const auth = useAuth();

  const displayDate = useMemo(() => {
    return {
      month: moment(start).format('MMMM, YYYY'),
      week: moment(start).format('MMMM D, YYYY'),
      day: moment(start).format('MMMM D, YYYY'),
    }[unit];
  }, [unit, start]);

  const scope = useMemo(() => {
    return { day: 'week', week: 'week', month: 'month' }[unit];
  }, [unit]);

  return (
    <ActionBarStyles>
      <Level width="100%">
        <Level.Item narrow>
          <DateNav>
            <DateNavButton disabled={disabled} onClick={onDateNavPrevious} isAnchor>
              <Icon icon="angle-left" />
            </DateNavButton>

            <DayPickerDropdown
              value={date}
              disabled={disabled}
              onChange={onDateChange}
              alignOffset="1rem"
              positionOffset="1rem"
              scope={scope}>
              {({ setIsOpen }) => (
                <DatePickerToggleButton isAnchor onClick={() => setIsOpen((isOpen) => !isOpen)}>
                  {displayDate}
                </DatePickerToggleButton>
              )}
            </DayPickerDropdown>

            <DateNavButton disabled={disabled} onClick={onDateNavNext} isAnchor>
              <Icon icon="angle-right" />
            </DateNavButton>
          </DateNav>
        </Level.Item>

        <Level.Item>
          <ViewNav>
            <Button
              className={classNames({ 'is-active': unit === 'month' })}
              disabled={disabled}
              onClick={() => onUnitChange('month')}>
              Month
            </Button>
            <Button
              className={classNames({ 'is-active': unit === 'week' })}
              disabled={disabled}
              onClick={() => onUnitChange('week')}>
              Week
            </Button>
            <Button
              className={classNames({ 'is-active': unit === 'day' })}
              disabled={disabled}
              onClick={() => onUnitChange('day')}>
              Day
            </Button>
          </ViewNav>
        </Level.Item>

        <Level.Item right narrow>
          <Level>
            {children}

            <Level.Item narrow right>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await onExport(mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await onExport(mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            {filters && (
              <Level.Item>
                <CircleButton isOutline onClick={onFilter}>
                  <Icon icon="sliders-h" />
                </CircleButton>
              </Level.Item>
            )}

            {create && (
              <>
                <Level.Item>
                  <CircleButton isOutline onClick={onCreateAllocation} disabled={!auth.allocations.manage}>
                    <Icon icon="plus" data-testid="new_allocation" />
                    {!auth.allocations.manage && (
                      <InlineTooltip message="Your security role prohibits you from creating allocations." />
                    )}
                  </CircleButton>
                </Level.Item>
              </>
            )}
          </Level>
        </Level.Item>
      </Level>
    </ActionBarStyles>
  );
}
