import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  ClientTagFilter,
  FiltersBar,
  InlineTooltip,
  LinkButton,
  MemberSelect,
  Page,
  PracticeSelect,
  SearchInput,
  SingleSelect,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import mimeTypes from '~/utils/mimeTypes.js';
import ExportDropdown from '../settings/ExportDropdown.jsx';

function ClientsListFilters({ filters: { q, practice, recordStatusId, owner, tags } = {}, onChange, onExport }) {
  const auth = useAuth();

  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Page.Header>
        <Page.Title>Clients</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`clients.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`clients.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <LinkButton to={`${url}/new${location.search}`} disabled={!auth.clients.create} className="button">
            Create a Client
            {!auth.clients.create && (
              <InlineTooltip message="Your security role prohibits you from creating clients." />
            )}
          </LinkButton>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
            value={q}
          />

          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Client Archived"
            materialAlwaysVisible
            value={recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>

          <MemberSelect
            value={owner}
            name="owner"
            placeholder="All"
            materialPlaceholder="Client Relationship Owner"
            materialAlwaysVisible
            onChange={handleChange}
          />

          {features.practices && (
            <PracticeSelect
              name="practice"
              value={practice}
              placeholder="All"
              materialPlaceholder="Practice"
              materialAlwaysVisible
              onChange={handleChange}
            />
          )}

          <ClientTagFilter name="tags" value={tags} onChange={handleChange} />
        </FiltersBar>
      </Page.Filters>
    </>
  );
}

export default ClientsListFilters;
