import styled from 'styled-components';

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 1rem;
  flex: 1;

  &:empty {
    display: none;
  }

  &,
  & input,
  & span > strong {
    font-size: 0.875rem;
  }

  & input {
    height: 2.5rem;
  }
`;

export default FiltersBar;
