import { Button, Buttons, CancelButton, Checkbox, Confirmation, Drawer, Field, Form, Radio } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik, useFormikContext } from 'formik';
import { useDirtyCheck, useFeatures, useIsMounted } from '~/hooks';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import InvoiceProjectMultiSelect from '../components/InvoiceProjectMultiSelect';
import InvoicePurchaseOrderSelect from '../components/InvoicePurchaseOrderSelect';

const ControlLabel = styled.p`
  display: flex;
  color: ${colors.grey75};

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

function EnforceTimeReportRestrictions() {
  const { values, setFieldValue } = useFormikContext();
  const { groupTimeReportBy, timeReportFields } = values;

  useEffect(() => {
    const fields = [];
    if (['person', 'role_and_person'].includes(groupTimeReportBy) && !timeReportFields.includes('person')) {
      fields.push('person');
    }
    if (['role', 'role_and_person'].includes(groupTimeReportBy) && !timeReportFields.includes('role')) {
      fields.push('role');
    }
    if (fields.length > 0) {
      setFieldValue('timeReportFields', [...timeReportFields, ...fields]);
    }
  }, [groupTimeReportBy, timeReportFields, setFieldValue]);

  return null;
}

export default function SettingsDrawer({ invoice, values, onApply, onClose }) {
  const [client, setClient] = useState(null);
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const isMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).client();
      if (!isMounted.current) return;
      setClient(data);
    })();
  }, [invoice.id, api, workspace.id, isMounted]);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      currency: null,
      includeReceipts: false,
      includeTimeReport: false,
      groupTimeReportBy: 'role',
      timeReportFields: [],
      displayColumns: [],
      projects: [],
      purchaseOrder: null,
    },
    values,
  );

  const schema = Yup.object().shape({
    projects: Yup.array().when('$permissions', (_, schema) =>
      client?.permissions.manageDraftInvoices
        ? schema
        : schema.min(1, `Your security role requires this invoice to be associated with at least one project.`),
    ),
    includeReceipts: Yup.boolean().label('Include receipts').required(),
    includeTimeReport: Yup.boolean().label('Include detailed time report').required(),
    groupTimeReportBy: Yup.string().label('Group time report by').oneOf(['role', 'role_and_person', 'person']),
    timeReportFields: Yup.array().of(Yup.string()).label('Time report columns'),
    displayColumns: Yup.array().of(Yup.string()).label('Invoice display columns'),
  });

  const confirmation = useConfirmation();

  if (!client) return null;

  return (
    <Drawer
      isOpen
      title="Invoice Settings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(formValues) {
          if (
            values.lines.some((line) => line.project && !formValues.projects.map((p) => p.id).includes(line.project.id))
          ) {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>
                You have removed a project that is associated with line items on the invoice. Those line items will be
                removed from the invoice. Are you sure that you want to continue?
              </Confirmation>
            ));
            if (!confirm) return;
          }

          onApply(formValues);
          closeDrawer();
        }

        return (
          <Formik
            innerRef={formRef}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {({ values, setFieldValue, setValues, submitForm }) => {
              function handleTimeReportFieldsChange(name) {
                let fields;
                if (values.timeReportFields.some((v) => v === name)) {
                  fields = values.timeReportFields.filter((v) => v !== name);
                } else {
                  fields = [...values.timeReportFields, name];
                }
                setFieldValue('timeReportFields', fields);
              }

              function handleDisplayColumnsChange(name) {
                let fields;
                if (values.displayColumns.some((v) => v === name)) {
                  fields = values.displayColumns.filter((v) => v !== name);
                } else {
                  fields = [...values.displayColumns, name];
                }
                setFieldValue('displayColumns', fields);
              }

              function handleProjectsChange({ target: { value } }) {
                setFieldValue(
                  'projects',
                  value.map((p) => ({ id: p.id, name: p.name, key: p.key, currency: p.currency })),
                );
              }

              function handlePurchaseOrderChange({ target: { value } }) {
                // Only reset the projects if the purchase order actually changed
                if (value?.id !== values.purchaseOrder?.id) {
                  const projectIds = value?.projects.map((p) => p.id) ?? [];
                  const projects = values.projects.filter((p) => projectIds.includes(p.id)) ?? [];

                  setValues({
                    ...values,
                    purchaseOrder: value,
                    projects,
                  });
                }
              }

              function handleCurrencyChange({ target: { value } }) {
                // Only reset the projects and purchase order if the currency actually changed
                if (value !== values.currency) {
                  setValues({
                    ...values,
                    currency: value,
                    projects: [],
                    purchaseOrder: null,
                  });
                }
              }

              return (
                <Form>
                  <Form.Section title="General">
                    <Form.Control>
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        clearable={false}
                        disabled={!features.multicurrency}
                        onChange={handleCurrencyChange}
                      />
                    </Form.Control>
                    {features.purchaseOrders && (
                      <Form.Control>
                        <InvoicePurchaseOrderSelect
                          name="purchaseOrder"
                          invoice={invoice}
                          currency={values.currency}
                          value={values.purchaseOrder}
                          initialValue={invoice.purchaseOrder}
                          onChange={handlePurchaseOrderChange}
                        />
                      </Form.Control>
                    )}
                    <Form.Control>
                      <InvoiceProjectMultiSelect
                        name="projects"
                        value={values.projects}
                        invoice={invoice}
                        currency={values.currency}
                        onChange={handleProjectsChange}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Form.Section
                    title="Invoice Columns"
                    subtitle="Select the columns to include on the published version of this invoice.">
                    <Form.Control>
                      <Checkboxes>
                        <Checkbox
                          label="Item"
                          checked={values.displayColumns.some((v) => v === 'item')}
                          onChange={() => handleDisplayColumnsChange('item')}
                        />
                        <Checkbox
                          label="Details"
                          checked={values.displayColumns.some((v) => v === 'details')}
                          onChange={() => handleDisplayColumnsChange('details')}
                        />
                        <Checkbox
                          label="Quantity"
                          checked={values.displayColumns.some((v) => v === 'quantity')}
                          onChange={() => handleDisplayColumnsChange('quantity')}
                        />
                        <Checkbox
                          label="Rate"
                          checked={values.displayColumns.some((v) => v === 'rate')}
                          onChange={() => handleDisplayColumnsChange('rate')}
                        />
                        <Checkbox label="Amount" checked={true} disabled={true} />
                        <Checkbox
                          label="Tax"
                          checked={values.displayColumns.some((v) => v === 'tax')}
                          onChange={() => handleDisplayColumnsChange('tax')}
                        />
                      </Checkboxes>
                    </Form.Control>
                  </Form.Section>

                  <Form.Section title="Receipt Options">
                    <Form.Control>
                      <Field.Checkbox name="includeReceipts" label="Include receipts with this invoice" />
                    </Form.Control>
                  </Form.Section>

                  <Form.Section title="Time Report Options">
                    <Form.Control>
                      <Field.Checkbox
                        name="includeTimeReport"
                        label="Include a detailed time report with this invoice"
                      />
                    </Form.Control>
                    <EnforceTimeReportRestrictions />
                    {(!!values.includeTimeReport || !!values.includeTimeReportPdfAttachment) && (
                      <>
                        <ControlLabel>Group by:</ControlLabel>
                        <Form.Control>
                          <Field.RadioGroup name="groupTimeReportBy">
                            <Radio value="role" label="Project Role" />
                            <Radio value="role_and_person" label="Project Role and Member" />
                            <Radio value="person" label="Member" />
                          </Field.RadioGroup>
                        </Form.Control>
                        <ControlLabel>Include these columns:</ControlLabel>
                        <Form.Control>
                          <Checkboxes>
                            <Checkbox
                              label="Billable Flag"
                              checked={values.timeReportFields.some((v) => v === 'billable')}
                              onChange={() => handleTimeReportFieldsChange('billable')}
                            />
                            <Checkbox
                              label="Date"
                              checked={values.timeReportFields.some((v) => v === 'date')}
                              onChange={() => handleTimeReportFieldsChange('date')}
                            />
                            <Checkbox
                              label="Member"
                              disabled={['person', 'role_and_person'].includes(values.groupTimeReportBy)}
                              checked={values.timeReportFields.some((v) => v === 'person')}
                              onChange={() => handleTimeReportFieldsChange('person')}
                            />
                            <Checkbox
                              label="Project"
                              checked={values.timeReportFields.some((v) => v === 'project')}
                              onChange={() => handleTimeReportFieldsChange('project')}
                            />
                            <Checkbox
                              label="Role"
                              disabled={['role', 'role_and_person'].includes(values.groupTimeReportBy)}
                              checked={values.timeReportFields.some((v) => v === 'role')}
                              onChange={() => handleTimeReportFieldsChange('role')}
                            />
                            <Checkbox
                              label="Task"
                              checked={values.timeReportFields.some((v) => v === 'task')}
                              onChange={() => handleTimeReportFieldsChange('task')}
                            />
                            <Checkbox
                              label="Notes"
                              checked={values.timeReportFields.some((v) => v === 'notes')}
                              onChange={() => handleTimeReportFieldsChange('notes')}
                            />
                          </Checkboxes>
                        </Form.Control>
                      </>
                    )}
                  </Form.Section>

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button onClick={submitForm}>Apply</Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
