import { Currency, Hours, Page, SingleSelect, Tab, Tabs } from '~/components';
import { useApi, useConfirmation, useToast } from '~/contexts';
import { useForm } from '~/hooks';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch, useHistory } from 'react-router';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, devices, weights } from '~/styles';
import DateRange from '../components/DateRange';
import { Badge, Eyebrow, HeaderTitle, InfoContainer, TabContent, Tag, Tags } from '../components/PageComponents';
import RejectExpenseDialog from '../components/RejectExpenseDialog';
import RejectTimeDialog from '../components/RejectTimeDialog';
import StatusTag from '../components/StatusTag';
import ClientActions from './ClientActions';
import ExpensesTab from './ExpensesTab';
import notFoundGraphic from './not-found.svg';
import SubmitDialog from './SubmitDialog';
import TimeTab from './TimeTab';
import moment from 'moment';
import { dateFormats } from '~/utils';

const Title = styled.h1`
  margin-top: 2.5rem;
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 1.2;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 1.875rem;
  }

  strong {
    font-weight: ${weights.black};
  }
`;

const Graphic = styled.img`
  display: flex;
`;

const ApprovalSelectContainer = styled.div`
  margin-top: 2rem;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
`;

const MultiApprovalsText = styled.p`
  margin-left: 1rem;
  color: ${colors.red};
`;

export default function ClientApprovalPublicPage() {
  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const api = useApi();
  const { workspaceKey, clientApprovalId } = useParams();
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const confirmation = useConfirmation();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const [timeSelection, setTimeSelection] = useState([]);
  const [approvals, setApprovals] = useState([]);

  const fetchData = useCallback(async () => {
    // Only fetch approvals one time
    if (approvals.length) return;

    try {
      const {
        data: { approvals },
      } = await api.clientApprovals({ workspaceKey, clientApprovalId }).get();

      setApprovals(approvals);
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [api, workspaceKey, clientApprovalId, approvals]);

  const updateApproval = useCallback(async () => {
    try {
      const {
        data: { approval },
      } = await api.clientApprovals({ workspaceKey, clientApprovalId }).get();

      setApprovals((approvals) => {
        if (approvals.some((a) => a.id === approval.id))
          return approvals ? approvals.map((a) => (a.id === approval.id ? approval : a)) : approvals;
      });
      setQuery({ isReady: true, data: { approval } });

      return { approval };
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [api, clientApprovalId, workspaceKey]);

  const outstandingCount = useMemo(
    () => (approvals ? approvals.filter((ca) => ca.statusId === 'sent').length : 0),
    [approvals],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    updateApproval();
  }, [updateApproval]);

  const refetchData = async () => {
    const clientApproval = await updateApproval().then((r) => r.approval);
    if (
      _.every(clientApproval.timeEntries, (entry) => !!entry.clientStatusId) &&
      _.every(clientApproval.expenseItems, (item) => !!item.clientStatusId) &&
      clientApproval.status.id !== 'submitted'
    ) {
      handleSubmit();
    }
  };

  // Time
  const handleTimeSelectionChange = (ids) => setTimeSelection(ids);

  const handleTimeStatusChange = async (entry, statusId) => {
    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectTimeDialog count={1} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit(entry.id);

      await api
        .clientApprovals({ workspaceKey, clientApprovalId })
        .updateTimeStatus({ ids: [entry.id], statusId, reason });

      await refetchData();
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleTimeBatchStatusChange = async (statusId) => {
    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectTimeDialog count={timeSelection.length} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit('batch');

      const { data } = await api
        .clientApprovals({ workspaceKey, clientApprovalId })
        .updateTimeStatus({ ids: timeSelection, statusId, reason });

      await refetchData();

      toast.success(
        statusId
          ? `${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${pluralize('time entry', data.length, true)}.`
          : `Set ${pluralize('time entry', data.length, true)} to pending approval.`,
      );

      setTimeSelection([]);
      form.save('batch');
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleTimeGroupAction = async (group, statusId) => {
    const ids = group.entries.map((entry) => entry.id);

    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectTimeDialog count={ids.length} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit({ action: 'group', group });

      await api.clientApprovals({ workspaceKey, clientApprovalId }).updateTimeStatus({ ids, statusId, reason });

      await refetchData();
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  // Expenses
  const [expenseSelection, setExpenseSelection] = useState([]);

  const handleExpenseSelectionChange = (ids) => setExpenseSelection(ids);

  const handleExpenseStatusChange = async (item, statusId) => {
    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={1} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit(item.id);

      await api
        .clientApprovals({ workspaceKey, clientApprovalId })
        .updateExpenseStatus({ ids: [item.id], statusId, reason });

      await refetchData();
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleExpenseBatchStatusChange = async (statusId) => {
    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={expenseSelection.length} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit('batch');

      const { data } = await api
        .clientApprovals({ workspaceKey, clientApprovalId })
        .updateExpenseStatus({ ids: expenseSelection, statusId, reason });

      await refetchData();

      toast.success(
        statusId
          ? `${{ approved: 'Approved', rejected: 'Rejected' }[statusId]}  ${pluralize(
              'expense item',
              data.length,
              true,
            )}.`
          : `Set ${pluralize('expense item', data.length, true)} to pending approval.`,
      );

      setExpenseSelection([]);
      form.save('batch');
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleExpenseGroupAction = async (group, statusId) => {
    const ids = group.items.map((item) => item.id);

    try {
      let reason;
      if (statusId === 'rejected') {
        reason = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={ids.length} onResolve={(reason) => resolve(reason)} />
        ));
        if (!reason) return;
      }

      form.submit({ action: 'group', group });

      await api.clientApprovals({ workspaceKey, clientApprovalId }).updateExpenseStatus({ ids, statusId, reason });

      await refetchData();
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleSubmit = async () => {
    const result = await confirmation.prompt((resolve) => (
      <SubmitDialog clientApproval={clientApproval} onResolve={resolve} />
    ));
    if (!result) return;

    try {
      form.submit('submit');
      await api.clientApprovals({ workspaceKey, clientApprovalId }).submit(result);
      toast.success('The client approval has been submitted.');
      refetchData();
      form.save('submit');
    } catch (error) {
      toast.error(error.message ?? 'An error has occurred submitting this client approval. Please try again.');
      form.done();
    }
  };

  if (!query.isReady) return <PageLoader />;

  if (query.error?.status === 404) return <ClientApprovalNotFound />;

  if (query.error) return <ErrorPage />;

  const handleClientApprovalSelectChange = (event) => {
    history.push(`/${workspaceKey}/client-approvals/${event.target.value}`);
  };

  const clientApproval = query.data.approval;
  const clientApprovals = approvals?.length > 1 && approvals;

  const pendingTime = clientApproval.timeEntries.filter((entry) => !entry.clientStatusId).length;
  const pendingExpenses = clientApproval.expenseItems.filter((item) => !item.clientStatusId).length;

  const locale = clientApproval.project.client.locale ?? clientApproval.workspace.locale;
  const currency = clientApproval.project.currency;

  return (
    <>
      {clientApprovals && (
        <ApprovalSelectContainer>
          <SingleSelect
            data-testid="outstanding-dropdown"
            style={{ width: '30rem' }}
            value={clientApproval.id}
            onChange={handleClientApprovalSelectChange}>
            {clientApprovals.map((approval) => {
              const periodStart = moment(approval.periodStart).locale(locale).format(dateFormats.compactDate);
              const periodEnd = moment(approval.periodEnd).locale(locale).format(dateFormats.compactDate);

              const submitted = approval.status.id === 'submitted';
              return (
                <option
                  key={approval.id}
                  value={approval.id}
                  style={{ color: submitted ? colors.grey40 : colors.black }}>
                  {approval.project.name}: {periodStart} - {periodEnd}
                </option>
              );
            })}
          </SingleSelect>
          <MultiApprovalsText>{outstandingCount} Client Approval Requests Outstanding</MultiApprovalsText>
        </ApprovalSelectContainer>
      )}

      <Page>
        <Page.Header data-testid="header">
          <InfoContainer>
            <Eyebrow>Client Approval</Eyebrow>

            <HeaderTitle data-testid="client-name">{clientApproval.project.name}</HeaderTitle>

            <Tags>
              <Tag>{clientApproval.project.client.name}</Tag>

              <StatusTag clientApproval={clientApproval} locale={locale} />

              <Tag>
                <DateRange clientApproval={clientApproval} locale={locale} />
              </Tag>

              {clientApproval.totalHours > 0 && (
                <Tag>
                  <Hours value={clientApproval.totalHours} locale={locale} /> total hours
                </Tag>
              )}

              {clientApproval.totalExpenses && (
                <Tag>
                  <Currency value={clientApproval.totalExpenses} currency={currency} locale={locale} /> total expenses
                </Tag>
              )}
            </Tags>
          </InfoContainer>
        </Page.Header>

        <Tabs style={{ marginTop: '1.6rem' }}>
          <Tab to={`${url}/time`} data-testid="time_tab">
            <TabContent>Time {pendingTime > 0 && <Badge>{pendingTime < 100 ? pendingTime : '99+'}</Badge>}</TabContent>
          </Tab>

          <Tab to={`${url}/expenses`} data-testid="expenses_tab">
            <TabContent>
              Expenses {pendingExpenses > 0 && <Badge>{pendingExpenses < 100 ? pendingExpenses : '99+'}</Badge>}
            </TabContent>
          </Tab>
        </Tabs>

        <Page.Section>
          <Switch>
            <Route path={`${path}/time`}>
              <TimeTab
                actionable
                readOnly={clientApproval.statusId === 'submitted'}
                clientApproval={clientApproval}
                isSubmitting={isSubmitting}
                selection={timeSelection}
                locale={locale}
                onSelectionChange={handleTimeSelectionChange}
                onStatusChange={handleTimeStatusChange}
                onGroupAction={handleTimeGroupAction}
              />
            </Route>

            <Route path={`${path}/expenses`}>
              <ExpensesTab
                view="public"
                actionable
                readOnly={clientApproval.statusId === 'submitted'}
                clientApproval={clientApproval}
                isSubmitting={isSubmitting}
                selection={expenseSelection}
                locale={locale}
                onSelectionChange={handleExpenseSelectionChange}
                onStatusChange={handleExpenseStatusChange}
                onGroupAction={handleExpenseGroupAction}
              />
            </Route>

            <Redirect path="/" to={`${url}/time`} />
          </Switch>
        </Page.Section>
      </Page>

      <Switch>
        <Route path={`${path}/time`}>
          <ClientActions
            clientApproval={clientApproval}
            selection={timeSelection}
            isSubmitting={isSubmitting}
            saved={saved}
            onAction={handleTimeBatchStatusChange}
            onSubmit={handleSubmit}
          />
        </Route>

        <Route path={`${path}/expenses`}>
          <ClientActions
            clientApproval={clientApproval}
            selection={expenseSelection}
            isSubmitting={isSubmitting}
            saved={saved}
            onAction={handleExpenseBatchStatusChange}
            onSubmit={handleSubmit}
          />
        </Route>
      </Switch>
    </>
  );
}

const ClientApprovalNotFound = () => {
  return (
    <ErrorPage pageTitle="Not Found">
      <Graphic src={notFoundGraphic} alt="Not found" />
      <Title>
        <strong>Oh no.</strong> This Client Approval Request has been deleted.
      </Title>
    </ErrorPage>
  );
};
