import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  DateTime,
  ExportDialog,
  FiltersBar,
  Hours,
  Icon,
  ListView,
  Page,
  PeriodFilter,
  RouteLink,
  SingleSelect,
  Spinner,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { intervalOptions, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import ProjectTimeViewSelect from './ProjectTimeViewSelect';

export default function ProjectTimesheets({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ isReady: false, isFetching: true, data: null, error: null });
  const [params, setParams] = useState({
    period: intervalOptions.last_week,
    timesheetStatusId: null,
    projectMemberStatus: null,
  });

  const fetchData = useCallback(async () => {
    setQuery((query) => ({ ...query, isFetching: true }));

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .timesheets({
          start: params.period?.start ?? undefined,
          end: params.period?.end ?? undefined,
          timesheetStatusId: params.timesheetStatusId ?? undefined,
          projectMemberStatusId: params.projectMemberStatus ?? undefined,
        });

      setQuery({ isReady: true, isFetching: false, data });
    } catch (error) {
      setQuery({ isReady: true, isFetching: false, data: null, error });
    }
  }, [workspace.id, api, project.id, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .timesheets(
            {
              start: params.period?.start ?? undefined,
              end: params.period?.end ?? undefined,
              timesheetStatusId: params.timesheetStatusId ?? undefined,
              projectMemberStatusId: params.projectMemberStatus ?? undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Page.Filters>
        <FiltersBar>
          <ProjectTimeViewSelect project={project} value={view} onChange={onViewChange} />

          <div>
            <PeriodFilter
              materialPlaceholder="Date Range"
              intervals={[
                intervalOptions.this_week,
                intervalOptions.this_semi_month_to_date,
                intervalOptions.this_month_to_date,
                intervalOptions.this_quarter_to_date,
                intervalOptions.this_year_to_date,
                intervalOptions.last_week,
                intervalOptions.last_month,
                intervalOptions.last_semi_month,
                intervalOptions.last_quarter,
                intervalOptions.last_year,
              ]}
              value={params.period}
              onChange={({ target: { value } }) => handleFilter({ period: value })}
            />
          </div>

          <SingleSelect
            materialPlaceholder="Submitted"
            placeholder="All"
            materialAlwaysVisible
            showEmptyOption
            value={params.timesheetStatusId}
            onChange={({ target: { value } }) => handleFilter({ timesheetStatusId: value })}>
            <option value="submitted">Yes</option>
            <option value="not_submitted">No</option>
          </SingleSelect>

          <SingleSelect
            materialPlaceholder="Member Status"
            placeholder="All"
            materialAlwaysVisible
            showEmptyOption
            value={params.projectMemberStatus}
            onChange={({ target: { value } }) => handleFilter({ projectMemberStatus: value })}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </SingleSelect>
        </FiltersBar>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_timesheets.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_timesheets.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.Filters>

      <Data query={query} params={params} project={project} />
    </>
  );
}

const GroupRow = styled(ListView.Row)`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  p {
    font-weight: ${weights.bold};
  }
  padding: 0.1rem 0;
  position: sticky;
  top: 2rem;
  background: ${colors.white};
  z-index: 3;
`;

function Data({ query, params, project }) {
  const report = { records: query.data };
  const { workspace } = useWorkspace();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start,
          end: params.period?.end,
          project: project.id,
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, project.id],
  );

  const groups = useMemo(() => {
    return _.groupBy(report.records, (record) => `${record.start}_${record.end}`);
  }, [report.records]);

  if (!query.isReady) return <PageLoader />;

  if (!query.data) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Page.ListView>
      <ListView>
        <ListView.Status>
          {query.isFetching && <Spinner />}
          <ListView.Total value={report.records.length} />
        </ListView.Status>

        <ListView.Header>
          <ListView.Column sticky minWidth="16rem">
            Period
          </ListView.Column>
          <ListView.Column width="6rem" align="center">
            Submitted
          </ListView.Column>
          <ListView.Column width="10rem" align="right">
            Submitted On
          </ListView.Column>
          <ListView.Column width="6rem" align="right">
            Project Hours
          </ListView.Column>
        </ListView.Header>

        <ListView.Body fade={query.isFetching}>
          {_.map(groups, (projectMembers, week) => {
            const period = projectMembers[0];

            const hours = _.sumBy(projectMembers, 'hours');

            return (
              <React.Fragment key={week}>
                <GroupRow>
                  <ListView.Cell>
                    <strong>
                      <DateTime value={period.start} /> - <DateTime value={period.end} />
                    </strong>
                  </ListView.Cell>
                  <ListView.Cell />
                  <ListView.Cell />
                  <ListView.Cell>
                    <strong>
                      {/* Note: these hours can't link to "Time Detail" because there's no filter by timesheet status */}
                      <Hours value={hours} />
                    </strong>
                  </ListView.Cell>
                </GroupRow>

                {projectMembers.map((pm) => {
                  const { member } = pm;

                  return (
                    <ListView.Row key={pm.member.id}>
                      <ListView.Cell>
                        <div style={{ marginLeft: '1rem' }}>{member.name}</div>
                      </ListView.Cell>
                      <ListView.Cell>
                        {pm.submittedOn ? (
                          <Icon icon="check" color={colors.success} />
                        ) : (
                          <Icon icon="times" color={colors.danger} />
                        )}
                      </ListView.Cell>
                      <ListView.Cell>
                        <DateTime value={pm.submittedOn} />
                      </ListView.Cell>
                      <ListView.Cell>
                        <RouteLink to={timeDetail({ member: member.id, start: period.start, end: period.end })}>
                          <Hours value={pm.hours} />
                        </RouteLink>
                      </ListView.Cell>
                    </ListView.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </ListView.Body>
      </ListView>
    </Page.ListView>
  );
}
