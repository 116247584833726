import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  Confirmation,
  DateTime,
  Dropdown,
  FiltersBar,
  InlineTooltip,
  LinkButton,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
  SingleSelect,
  Spinner,
  SplitButton,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import ContactCreateForm from '../pipeline/contacts/ContactCreateForm';
import ContactForm from '../pipeline/contacts/ContactForm';
import ContactSelect from '../pipeline/contacts/ContactSelect';
import ViewContactDrawer from '../pipeline/contacts/ViewContactDrawer';

const ContactInfo = styled.div`
  line-height: 1;
  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const AddContactButton = styled(ActionButton)`
  margin-left: 0.5rem;
  border-radius: 999rem 0rem 0rem 999rem;
`;

const AddContactDropdown = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 20rem;
`;

const StyledLink = styled(SplitButton.Link)`
  white-space: nowrap;
`;

function ClientContactsTab({ client }) {
  const documentTitle = useDocumentTitle();

  const { workspace } = useWorkspace();
  const api = useApi();
  const history = useHistory();
  const { contactId, action } = useParams();
  const { url } = useRouteMatch();
  const features = useFeatures();
  const toast = useToast();
  const auth = useAuth();

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    isActive: 'true',
    clientIds: [client.id],
    sort: new QuerySort('name', 'asc'),
  });

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).contacts().get(params);

      const contacts = data.map((c) => {
        // Find the companyContact for the specific clientId
        const clientContact = c.clientContacts.find((cc) => cc.clientId === client.id);

        // Return a new object with the original contact data and the isActive from the relation
        return {
          ...c,
          isActive: clientContact.isActive,
        };
      });

      setQuery({ status: 'ready', data: contacts, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params, client.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  async function handleDeleted() {
    fetchData();
    handleClose();
  }

  async function handleCreated(contact) {
    fetchData();
    handleEdit(contact);
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, sort: new QuerySort(column, direction) }));
  };

  function handleClose() {
    history.push(`/app/${workspace.key}/clients/${client.key}/contacts`);
    documentTitle.set(client.name);
  }

  const handleDeleteConfirmation = async (contact) => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>
        {features.pipeline
          ? 'Are you sure you want to remove the association with this contact?'
          : 'Are you sure you want to delete this contact?'}
      </Confirmation>
    ));

    if (!confirm) return;
    if (features.pipeline) {
      await api.www.workspaces(workspace.id).contacts(contact.id).clients(client.id).remove();
    } else {
      await api.www.workspaces(workspace.id).contacts(contact.id).delete();
    }

    await handleDeleted();
  };

  async function handleActiveStatusChange(contact, flag) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).clients(client.id).setActiveStatus(flag);

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the contact status. Please try again.');
    }
  }

  async function handleAddContact(contact) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).clients(client.id).add();

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem associating the contact. Please try again.');
    }
  }

  const handleView = ({ id }) => {
    history.push(`/app/${workspace.key}/clients/${client.key}/contacts/${id}/view`);
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/clients/${client.key}/contacts/${id}/edit`);
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page.Filters>
        <FiltersBar>
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Contact Name"
            materialAlwaysVisible
            onChange={handleChange}
          />

          <SingleSelect
            name="isActive"
            value={params.isActive}
            placeholder="All"
            materialPlaceholder="Status"
            materialAlwaysVisible
            showEmptyOption
            onChange={handleChange}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </SingleSelect>
        </FiltersBar>

        <Page.Actions>
          {features.pipeline && auth.pipeline.manage ? (
            <AddContactDropdown>
              {({ setIsOpen, isOpen }) => (
                <>
                  <Dropdown.Trigger>
                    <SplitButton style={{ alignItems: 'center' }}>
                      <AddContactButton onClick={() => setIsOpen(!isOpen)}>Associate a Contact</AddContactButton>
                      <SplitButton.Menu>
                        <StyledLink to={`${url}/new`}>Create a Contact</StyledLink>
                      </SplitButton.Menu>
                    </SplitButton>
                  </Dropdown.Trigger>
                  <Dropdown.Menu>
                    <Dropdown.Panel>
                      <ContactSelect
                        filterContacts={query.data}
                        onChange={async ({ target: { value } }) => {
                          handleAddContact(value);
                          setIsOpen(false);
                        }}
                      />
                    </Dropdown.Panel>
                  </Dropdown.Menu>
                </>
              )}
            </AddContactDropdown>
          ) : (
            <LinkButton to={`${url}/new`} disabled={!client.permissions.edit} className="button">
              Create a Contact
              {!client.permissions.edit && (
                <InlineTooltip message="Your security role prohibits you from creating contacts." />
              )}
            </LinkButton>
          )}
        </Page.Actions>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Status>
            {query.status !== 'ready' && <Spinner />}
            <ListView.Total value={query.data.length} label="Contact" />
          </ListView.Status>

          <ListView.Header>
            <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
              Contact
            </ListView.Column>
            <ListView.Column minWidth="16rem" name="email" onSort={handleSort} sort={params.sort}>
              Email
            </ListView.Column>
            <ListView.Column width="8rem" name="createdAt" onSort={handleSort} sort={params.sort} align="right">
              Created
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body fade={query.status === 'filtering'}>
            {query.data.map((contact) => {
              const { id, isActive, createdAt } = contact;

              async function handleRowClick(contact) {
                if (contact.permissions.manage) {
                  handleEdit(contact);
                } else {
                  handleView(contact);
                }
              }

              return (
                <ListView.Row isDisabled={!isActive} key={id} onClick={() => handleRowClick(contact)}>
                  <ListView.Cell>
                    <ContactInfo>
                      <p>{contact.name}</p>
                      {contact.title && <small>{contact.title}</small>}
                    </ContactInfo>
                  </ListView.Cell>
                  <ListView.Cell>{contact.email}</ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={createdAt} />
                  </ListView.Cell>
                  <ListViewActions>
                    {contact.permissions.manage ? (
                      <ListViewActions.Edit onClick={() => handleEdit(contact)} />
                    ) : (
                      <ListViewActions.View onClick={() => handleView(contact)} />
                    )}
                    <hr />
                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(handleView(contact))}>
                              View
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? 'Insufficient permissions to edit this contact.'
                                  : undefined
                              }
                              onClick={() => handleAction(handleEdit(contact))}>
                              Edit
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? `Insufficient permissions to ${features.pipeline ? 'remove' : 'delete'} this contact.`
                                  : undefined
                              }
                              onClick={() => handleAction(handleDeleteConfirmation(contact))}>
                              {features.pipeline ? 'Remove' : 'Delete'}
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this contact.`
                                  : undefined
                              }
                              onClick={() => handleAction(handleActiveStatusChange(contact, !isActive))}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {query.data.length === 0 && <ListView.Empty />}
          </ListView.Body>
        </ListView>
      </Page.ListView>

      {action &&
        {
          new: <ContactCreateForm clientId={client.id} onSaved={handleCreated} onClose={handleClose} />,
          edit: contactId && (
            <ContactForm
              clientId={client.id}
              onSaved={handleSaved}
              onDeleted={handleDeleteConfirmation}
              onClose={handleClose}
            />
          ),
          view: contactId && <ViewContactDrawer clientId={client.id} onClose={handleClose} />,
        }[action]}
    </>
  );
}

export default ClientContactsTab;
