import {
  ClientProjectSelect,
  ExpenseReimbursableTypeFilter,
  FiltersBar,
  MemberSelect,
  Page,
  PracticeSelect,
  SingleSelect,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { intervalOptions, mimeTypes } from '~/utils';
import ExportDropdown from '../settings/ExportDropdown.jsx';

function ExpenseFilters({ filters, onChange, onExport, hideApprover, isApprovals }) {
  const features = useFeatures();

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'today',
        'yesterday',
        'this_week',
        'this_week_to_date',
        'this_month',
        'this_month_to_date',
        'this_quarter',
        'this_quarter_to_date',
        'this_year',
        'this_year_to_date',
        'last_week',
        'last_month',
        'last_quarter',
        'last_year',
        'past_7_days',
        'past_30_days',
        'past_90_days',
        'past_180_days',
        'past_365_days',
      ),
    [],
  );

  const handleFilterChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Expenses</Page.Eyebrow>
          <Page.Title>Expense Auditing</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`expense_auditing.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`expense_auditing.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SingleSelect
            name="period"
            placeholder="All"
            showEmptyOption
            materialPlaceholder="Expense Item Date"
            materialAlwaysVisible
            value={filters.period}
            onChange={handleFilterChange}>
            {_.map(intervals, ({ label }, key) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </SingleSelect>

          <ClientProjectSelect
            name="project"
            placeholder="All"
            materialPlaceholder="Project"
            materialAlwaysVisible
            assignedOnly={false}
            activeOnly={!!isApprovals}
            pastExpenses={!isApprovals}
            value={filters.project}
            onChange={handleFilterChange}
          />

          <SingleSelect
            name="status"
            placeholder="All"
            showEmptyOption
            materialPlaceholder="Approval Status"
            materialAlwaysVisible
            value={filters.status}
            onChange={handleFilterChange}>
            <option value="not_submitted">Not Submitted</option>
            <option value="pending_approval">Pending Approval</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </SingleSelect>

          <MemberSelect
            name="member"
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={filters.member}
            onChange={handleFilterChange}
          />

          <ExpenseReimbursableTypeFilter
            name="reimbursableType"
            value={filters.reimbursableType}
            onChange={handleFilterChange}
          />

          <ReimbursedFilter name="isReimbursed" value={filters.isReimbursed} onChange={handleFilterChange} />

          {features.practices && (
            <PracticeSelect
              name="memberPractice"
              placeholder="All"
              materialPlaceholder="Member Practice"
              materialAlwaysVisible
              value={filters.memberPractice}
              onChange={handleFilterChange}
            />
          )}

          {!hideApprover && (
            <MemberSelect
              name="approver"
              placeholder="All"
              materialPlaceholder="Approver"
              materialAlwaysVisible
              value={filters.approver}
              onChange={handleFilterChange}
            />
          )}
        </FiltersBar>
      </Page.Filters>
    </>
  );
}

export default ExpenseFilters;
