import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  BillableIcon,
  Currency,
  ExportDialog,
  FiltersBar,
  HoursProgress,
  ListView,
  MemberSelect,
  Page,
  Percent,
  SingleSelect,
  Spinner,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import ProjectTimeViewSelect from './ProjectTimeViewSelect';

export default function ProjectTimeByMember({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({
    member: null,
    billableTypeId: null,
  });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .timeByMember({
          projectMemberId: params.member?.id,
          billableTypeId: params.billableTypeId ?? undefined,
        });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .timeByMember(
            {
              projectMemberId: params.member?.id,
              billableTypeId: params.billableTypeId ?? undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Page.Filters>
        <FiltersBar>
          <ProjectTimeViewSelect project={project} value={view} onChange={onViewChange} />

          <MemberSelect
            materialPlaceholder="Member"
            placeholder="All"
            materialAlwaysVisible
            value={params.member}
            onChange={({ target: { value } }) => handleFilter({ member: value })}
          />

          <SingleSelect
            materialPlaceholder="Billable"
            placeholder="All"
            materialAlwaysVisible
            showEmptyOption
            value={params.billableTypeId}
            onChange={({ target: { value } }) => handleFilter({ billableTypeId: value })}>
            <option value="billable">Yes</option>
            <option value="non_billable">No</option>
          </SingleSelect>
        </FiltersBar>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_time_by_member.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_time_by_member.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.Filters>

      <Data data={data} project={project} params={params} isReady={isReady} />
    </>
  );
}

const Data = ({ data, isReady, params, project }) => {
  const { workspace } = useWorkspace();

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      billableType: params.billableTypeId
        ? project.client.isInternal && params.billableTypeId === 'non_billable'
          ? 'internal'
          : params.billableTypeId
        : undefined,
      ...query,
    }).toString(true)}`;

  const url = {
    total: (member) => timeDetail({ member }),
  };

  if (!isReady) return <PageLoader />;

  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const currency = project.currency;

  return (
    <Page.ListView>
      <ListView>
        <ListView.Status>
          {!isReady && <Spinner />}
          <ListView.Total value={records?.length} />
        </ListView.Status>

        <ListView.Header>
          <ListView.Column width="2rem" isVisible={columns.billable} />

          <ListView.Column minWidth="16rem" sticky>
            Member
          </ListView.Column>

          <ListView.Column width="12rem">Hours</ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.realizationRate}>
            Realization Rate
          </ListView.Column>

          <ListView.Column align="right" width="8rem" isVisible={columns.budgetRevenue}>
            Budget Revenue
          </ListView.Column>

          <ListView.Column align="right" width="8rem" isVisible={columns.revenue}>
            Earned Revenue
          </ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.budgetEffectiveRate}>
            Budget Effective Rate
          </ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.effectiveRate}>
            Effective Rate
          </ListView.Column>
        </ListView.Header>

        <ListView.Body>
          {records.map((row) => {
            return (
              <ListView.Row key={row.id} data-testid="row">
                <ListView.Cell>
                  <BillableIcon value={row.isBillable} />
                </ListView.Cell>

                <ListView.Cell>{row.member.name}</ListView.Cell>

                <ListView.Cell>
                  <HoursProgress
                    worked={row.totalHours}
                    estimated={row.budgetTotalHours}
                    to={url.total(row.member.id)}
                  />
                </ListView.Cell>

                <ListView.Cell>
                  <Percent value={row.realizationRate} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={row.budgetTotalRevenue} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={row.revenue} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={row.budgetEffectiveRate} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={row.effectiveRate} currency={currency} />
                </ListView.Cell>
              </ListView.Row>
            );
          })}

          <ListView.Row style={{ fontWeight: weights.bold }}>
            <ListView.Cell />

            <ListView.Cell>Total</ListView.Cell>

            <ListView.Cell>
              <HoursProgress
                worked={totals.totalHours}
                estimated={totals.budgetTotalHours}
                to={url.total(params.member?.id)}
              />
            </ListView.Cell>

            <ListView.Cell>
              <Percent value={totals.realizationRate} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetTotalRevenue} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.revenue} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetEffectiveRate} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.effectiveRate} currency={currency} />
            </ListView.Cell>
          </ListView.Row>
        </ListView.Body>
      </ListView>
    </Page.ListView>
  );
};
