import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  ActionsBanner,
  ButtonBadge,
  Checkbox,
  ExportDialog,
  InlineTooltip,
  Level,
  ListView,
  Page,
  Spinner,
  SplitButton,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useForm, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import DeleteExpenseItemConfirmation from '../DeleteExpenseItemConfirmation';
import ExpenseFilters from '../ExpenseFilters';
import ExpenseItemDrawer from '../item/ExpenseItemDrawer';
import ExpenseAuditRow from './ExpenseAuditRow';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
  }
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    status: '',
    page: 0,
    project: '',
    member: '',
    memberPractice: null,
    isReimbursed: null,
    reimbursableType: null,
    size: 100,
    period: 'past_90_days',
    sort: new QuerySort('date', 'desc'),
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results?.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.some((i) => i.id === item.id)
        ? data.results.map((i) => (i.id === item.id ? item : i))
        : [...data.results, item],
    },
  }),
  closeDeleteConfirmation: () => ({ dialog: null }),
  showDeleteConfirmation: ({ target }) => ({ dialog: 'delete', target }),
};

function ExpenseAuditingPage({ parentUrl }) {
  useDocumentTitle('Expense Auditing');

  const { expenseItemId, mode } = useParams();
  const drawer = expenseItemId ? 'open' : null;
  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const { workspace } = useWorkspace();
  const [{ isReady, data, query, target, dialog, searchParamsStatus, action }, actions] = useActions(
    handlers,
    initialState,
  );
  const [{ isSubmitting, saved }, form] = useForm();
  const [selection, setSelection] = useState([]);

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'today',
        'yesterday',
        'this_week',
        'this_week_to_date',
        'this_month',
        'this_month_to_date',
        'this_quarter',
        'this_quarter_to_date',
        'this_year',
        'this_year_to_date',
        'last_week',
        'last_month',
        'last_quarter',
        'last_year',
        'past_7_days',
        'past_30_days',
        'past_90_days',
        'past_180_days',
        'past_365_days',
      ),
    [],
  );

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: initialState.query.period,
          valid: ['all', ..._.keys(intervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        status: searchParamsConfig.approvalStatus,
        sort: {
          default: initialState.query.sort,
          ...searchParamsConfig.sort,
        },
        project: searchParamsConfig.project,
        member: searchParamsConfig.member,
        memberPractice: searchParamsConfig.practice,
        isReimbursed: searchParamsConfig.isReimbursed,
        reimbursableType: searchParamsConfig.expenseReimbursableType,
      }),
      [searchParamsConfig, intervals],
    ),

    sessionKey: 'expense_auditing',

    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = intervals[query.period] || {};

      const serializedQueryParams = {
        ..._.omit(query, ['member', 'memberPractice', 'project', 'reimbursableType']),
        project: query.project?.id,
        member: query.member?.id,
        memberPracticeId: query.memberPractice?.id,
        status: query.status ?? undefined,
        isReimbursed: query.isReimbursed ?? undefined,
        reimbursableTypeId: query.reimbursableType ?? undefined,
        start,
        end,
      };
      const { data } = await api.www.workspaces(workspace.id).expenseAdmin().auditing(serializedQueryParams);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query, api, intervals]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = (filter) => {
    actions.setParams(filter);
    setSelection([]);
    searchParams.set(filter);
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleBatchReimbursedStatusChange = async (isReimbursed) => {
    try {
      form.submit('batch');

      await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateReimbursedStatus({ ids: selection.map(({ id }) => id), isReimbursed });

      toast.success(
        `${selection.length} expense ${pluralize('item', selection.length)} set as ${isReimbursed ? 'Reimbursed' : 'Not Reimbursed'}.`,
      );
      setSelection([]);
      actions.load();
      form.save();
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setSelection([]);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleDeleted = () => {
    setSelection([]);
    actions.load();
  };

  async function handleReimbursedStatusChange(item, flag) {
    await api.www.workspaces(workspace.id).expenseAdmin(item.id).setReimbursedStatus(flag);
    setSelection([]);
    actions.load();
  }

  if (!isReady && !data) return <PageLoader />;

  const handleSelectAllChange = () =>
    handleSelectionChange(
      selection.length > 0
        ? []
        : data.results.map((item) => {
            return {
              id: item.id,
              isReimbursable: item.isReimbursable,
              isReimbursed: item.isReimbursed,
            };
          }),
    );

  const handleExport = async (filename, mimeType) => {
    const { start, end } = intervals[query.period] || {};

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .expenseAdmin()
          .exportAuditing(
            {
              ..._.omit(query, ['member', 'memberPractice', 'project', 'reimbursableType']),
              project: query.project?.id,
              member: query.member?.id,
              memberPracticeId: query.memberPractice?.id,
              status: query.status ?? undefined,
              isReimbursed: query.isReimbursed ?? undefined,
              reimbursableTypeId: query.reimbursableType ?? undefined,
              size: null,
              start,
              end,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page scrollable>
      <ExpenseFilters onChange={handleFilterChange} filters={query} onExport={handleExport} hideApprover={true} />

      <Page.ListView>
        <ListView>
          <ListView.Status>
            {!!action && <Spinner />}
            <ListView.Total value={data.total} label="Expense Items" />
          </ListView.Status>

          <ListView.Header>
            <ListView.Column sticky name="selector" width="3.5rem" align="center">
              <StyledCheckbox>
                <Checkbox
                  checked={selection.length > 0}
                  partial={selection.length < data.results.length}
                  disabled={isSubmitting || data.results.length === 0 || action === 'filter'}
                  onChange={handleSelectAllChange}
                />
              </StyledCheckbox>
            </ListView.Column>
            <ListView.Column name="selector" width="3.5rem" align="center"></ListView.Column>
            <ListView.Column name="date" width="7.5rem" sort={query.sort} onSort={handleSort}>
              Date
            </ListView.Column>
            <ListView.Column name="member" minWidth="12rem" sort={query.sort} onSort={handleSort}>
              Member
            </ListView.Column>
            <ListView.Column name="category" minWidth="16rem" sort={query.sort} onSort={handleSort}>
              Category/Vendor
            </ListView.Column>
            <ListView.Column name="project" width="20rem" sort={query.sort} onSort={handleSort}>
              Project/Client
            </ListView.Column>
            <ListView.Column name="amount" width="12.5rem" align="right" sort={query.sort} onSort={handleSort}>
              Amount
            </ListView.Column>
            <ListView.Column name="selector" width="3rem"></ListView.Column>
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {_.map(data.results, (item) => {
              return (
                <ExpenseAuditRow
                  key={item.id}
                  item={item}
                  onDelete={(target) => {
                    return actions.showDeleteConfirmation({ target });
                  }}
                  onReimbursedStatusChange={handleReimbursedStatusChange}
                  selection={selection}
                  onSelectionChange={handleSelectionChange}
                />
              );
            })}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && <ListView.Loader onIntersecting={actions.loadMore} />}
          </ListView.Body>
        </ListView>
      </Page.ListView>

      {selection?.length > 0 && (
        <ActionsBanner>
          <Level right>
            <Level.Item>
              <SplitButton>
                <ActionButton
                  disabled={!!selection.find((s) => s.isReimbursable === false)}
                  isLoading={isSubmitting === 'batch'}
                  style={{ position: 'relative' }}
                  ok={saved}
                  onClick={() => handleBatchReimbursedStatusChange(true)}>
                  Set as Reimbursed
                  {!!selection.find((s) => s.isReimbursable === false) && (
                    <InlineTooltip message="Only reimbursable items can be set as reimbursed." />
                  )}
                  <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                </ActionButton>

                <SplitButton.Menu position="top">
                  {({ setIsOpen }) => (
                    <SplitButton.Item
                      onClick={() => setIsOpen(false) || handleBatchReimbursedStatusChange(false)}
                      disabled={selection.length === 0 || !!selection.find((s) => s.isReimbursable === false)}>
                      Set as Not Reimbursed
                      {!!selection.find((s) => s.isReimbursable === false) && (
                        <InlineTooltip message="Only reimbursable items can be set as not reimbursed." />
                      )}
                    </SplitButton.Item>
                  )}
                </SplitButton.Menu>
              </SplitButton>
            </Level.Item>
          </Level>
        </ActionsBanner>
      )}
      {dialog === 'delete' && (
        <DeleteExpenseItemConfirmation
          id={target.expenseItemId}
          onClose={actions.closeDeleteConfirmation}
          onDelete={handleDeleted}
        />
      )}
      {drawer === 'open' && (
        <ExpenseItemDrawer
          overrideShowMember={true}
          mode={mode}
          onSaved={fetchData}
          onClose={() => history.push({ pathname: parentUrl, search: location.search, state: { scrollToTop: false } })}
        />
      )}
    </Page>
  );
}
export default ExpenseAuditingPage;
