import styled from 'styled-components';

const ListViewStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;

  .icon {
    font-size: 0.875rem;
  }

  position: sticky;
  margin-left: auto;
  right: 0;
  width: max-content;
`;

export default ListViewStatus;
