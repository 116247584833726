import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';
import reportFiles from './reportFiles';

const reports = (workspacesUrl) => () => {
  const url = _([workspacesUrl, 'reports']).compact().join('/');

  return {
    reportFiles: reportFiles(url.concat('/report-files')),

    // Expenses
    expenseSummaryByClientAndProject: (query = {}, config) =>
      get(
        url
          .concat('/expense-summary-by-client-and-project')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseSummaryByMember: (query = {}, config) =>
      get(
        url.concat('/expense-summary-by-member').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseSummaryByExpenseCategory: (query = {}, config) =>
      get(
        url
          .concat('/expense-summary-by-expense-category')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseSummaryByMemberPractice: (query = {}, config) =>
      get(
        url
          .concat('/expense-summary-by-member-practice')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseSummaryByProject: (query = {}, config) =>
      get(
        url.concat('/expense-summary-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseSummaryByProjectType: (query = {}, config) =>
      get(
        url.concat('/expense-summary-by-project-type').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    expenseDetail: (query = {}, config) =>
      get(url.concat('/expense-detail').concat(new QueryString(query, { multi: true }).toString(true)), config),

    // Financial
    performanceByProject: (query = {}, config) =>
      get(url.concat('/performance-by-project').concat(new QueryString(query, { multi: true }).toString(true)), config),
    performanceByProjectAndTask: (query = {}, config) =>
      get(
        url.concat('/performance-by-project-and-task').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceByProjectPractice: (query = {}, config) =>
      get(
        url.concat('/performance-by-project-practice').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceByProjectType: (query = {}, config) =>
      get(
        url.concat('/performance-by-project-type').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceByProjectAndMember: (query = {}, config) =>
      get(
        url.concat('/performance-by-project-and-member').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceByMember: (query = {}, config) =>
      get(url.concat('/performance-by-member').concat(new QueryString(query, { multi: true }).toString(true)), config),
    performanceByMemberPractice: (query = {}, config) =>
      get(
        url.concat('/performance-by-member-practice').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceByClient: (query = {}, config) =>
      get(url.concat('/performance-by-client').concat(new QueryString(query, { multi: true }).toString(true)), config),
    performanceByClientPractice: (query = {}, config) =>
      get(
        url.concat('/performance-by-client-practice').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    fixedFeeRevenue: (query = {}, config) =>
      get(url.concat('/fixed-fee-revenue').concat(new QueryString(query, { multi: true }).toString(true)), config),
    uninvoicedRevenue: (query = {}, config) =>
      get(url.concat('/uninvoiced-revenue').concat(new QueryString(query, { multi: true }).toString(true)), config),
    invoicesList: (query = {}, config) =>
      get(url.concat('/invoices-list').concat(new QueryString(query, { multi: true }).toString(true)), config),
    invoicesByClient: (query = {}, config) =>
      get(url.concat('/invoices-by-client').concat(new QueryString(query, { multi: true }).toString(true)), config),
    invoiceDetail: (query = {}, config) =>
      get(url.concat('/invoice-detail').concat(new QueryString(query, { multi: true }).toString(true)), config),
    payments: (query = {}, config) =>
      get(url.concat('/payments').concat(new QueryString(query, { multi: true }).toString(true)), config),
    accountsReceivableAging: (query = {}, config) =>
      get(
        url.concat('/accounts-receivable-aging').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    creditNotes: (query = {}, config) =>
      get(url.concat('/credit-notes').concat(new QueryString(query, { multi: true }).toString(true)), config),
    creditNoteDetail: (query = {}, config) =>
      get(url.concat('/credit-note-detail').concat(new QueryString(query, { multi: true }).toString(true)), config),
    clientPurchaseOrders: (query = {}, config) =>
      get(url.concat('/client-purchase-orders').concat(new QueryString(query, { multi: true }).toString(true)), config),
    hourlyFees: (query = {}, config) =>
      get(url.concat('/hourly-fees').concat(new QueryString(query, { multi: true }).toString(true)), config),
    scheduledItems: (query = {}, config) =>
      get(url.concat('/scheduled-items').concat(new QueryString(query, { multi: true }).toString(true)), config),
    transactionSummaryByInvoiceItem: (query = {}, config) =>
      get(
        url
          .concat('/transaction-summary-by-invoice-item')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    clientStatement: (query = {}, config) =>
      get(url.concat('/client-statement').concat(new QueryString(query, { multi: true }).toString(true)), config),

    // Forecast
    performanceForecastByProject: (query = {}, config) =>
      get(
        url.concat('/performance-forecast-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performanceForecastByProjectByTimeUnit: (query = {}, config) =>
      get(
        url
          .concat('/performance-forecast-by-project-by-time-unit')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    hoursPlanByResourceAndProject: (query = {}, config) =>
      get(
        url
          .concat('/hours-plan-by-resource-and-project')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    hoursPlanByProjectAndResource: (query = {}, config) =>
      get(
        url
          .concat('/hours-plan-by-project-and-resource')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    servicesRevenuePlanByProject: (query = {}, config) =>
      get(
        url.concat('/services-revenue-plan-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    utilizationPlanByMember: (query = {}, config) =>
      get(
        url.concat('/utilization-plan-by-member').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    utilizationPlanByMemberPractice: (query = {}, config) =>
      get(
        url
          .concat('/utilization-plan-by-member-practice')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    performancePlanByProject: (query = {}, config) =>
      get(
        url.concat('/performance-plan-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    hoursVarianceByMemberAndProject: (query = {}, config) =>
      get(
        url
          .concat('/hours-variance-by-member-and-project')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    hoursVarianceByProjectAndResource: (query = {}, config) =>
      get(
        url
          .concat('/hours-variance-by-project-and-resource')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    hoursVarianceByProjectAndRole: (query = {}, config) =>
      get(
        url
          .concat('/hours-variance-by-project-and-role')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),

    // Pipeline
    opportunityDetail: (query = {}, config) =>
      get(url.concat('/opportunity-detail').concat(new QueryString(query, { multi: true }).toString(true)), config),
    opportunitiesByMonth: (query = {}, config) =>
      get(url.concat('/opportunities-by-month').concat(new QueryString(query, { multi: true }).toString(true)), config),

    // Time
    timeAttachments: (query = {}, config) =>
      get(url.concat('/time-attachments').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeSummaryByClient: (query = {}, config) =>
      get(url.concat('/time-summary-by-client').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeSummaryByClientAndMember: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-client-and-member').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByClientAndProject: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-client-and-project')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByMember: (query = {}, config) =>
      get(url.concat('/time-summary-by-member').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeSummaryByMemberPractice: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-member-practice').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeApprovalSummaryByMember: (query = {}, config) =>
      get(
        url.concat('/time-approval-summary-by-member').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByProject: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByProjectType: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-project-type').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeOffByMember: (query = {}, config) =>
      get(url.concat('/time-off-by-member').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeSummaryByMemberAndClient: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-member-and-client').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByMemberAndProject: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-member-and-project')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByMemberByTimeUnit: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-member-by-time-unit')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByProjectAndMember: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-project-and-member')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByProjectAndTask: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-project-and-task').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByProjectAndRole: (query = {}, config) =>
      get(
        url.concat('/time-summary-by-project-and-role').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeDetail: (query = {}, config) =>
      get(url.concat('/time-detail').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeEntriesPendingApproval: (query = {}, config) =>
      get(
        url.concat('/time-entries-pending-approval').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    utilizationByMember: (query = {}, config) =>
      get(url.concat('/utilization-by-member').concat(new QueryString(query, { multi: true }).toString(true)), config),
    utilizationByMemberPractice: (query = {}, config) =>
      get(
        url.concat('/utilization-by-member-practice').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    utilizationByTimeUnit: (query = {}, config) =>
      get(
        url.concat('/utilization-by-time-unit').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    unsubmittedTimesheets: (query = {}, config) =>
      get(url.concat('/unsubmitted-timesheets').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeSummaryByProjectByTimeUnit: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-project-by-time-unit')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
    timeSummaryByClientByTimeUnit: (query = {}, config) =>
      get(
        url
          .concat('/time-summary-by-client-by-time-unit')
          .concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),

    // Workspace
    membersList: (query = {}, config) =>
      get(url.concat('/members-list').concat(new QueryString(query, { multi: true }).toString(true)), config),
    clientsList: (query = {}, config) =>
      get(url.concat('/clients-list').concat(new QueryString(query, { multi: true }).toString(true)), config),
    clientContacts: (query = {}, config) =>
      get(url.concat('/client-contacts').concat(new QueryString(query, { multi: true }).toString(true)), config),
    history: (query = {}, config) =>
      get(url.concat('/history').concat(new QueryString(query, { multi: true }).toString(true)), config),
    projectsList: (query = {}, config) =>
      get(url.concat('/projects-list').concat(new QueryString(query, { multi: true }).toString(true)), config),
    projectMembers: (query = {}, config) =>
      get(url.concat('/project-members').concat(new QueryString(query, { multi: true }).toString(true)), config),
    projectTasksList: (query = {}, config) =>
      get(url.concat('/project-tasks-list').concat(new QueryString(query, { multi: true }).toString(true)), config),
    projectHealthByProject: (query = {}, config) =>
      get(
        url.concat('/project-health-by-project').concat(new QueryString(query, { multi: true }).toString(true)),
        config,
      ),
  };
};

export default reports;
