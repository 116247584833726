import {
  Avatar,
  ClientLink,
  CloudFolderLink,
  CompanyLink,
  Currency,
  DateTime,
  MemberContactPopover,
  MultilineText,
  Pill,
  Pills,
  ProjectHealthCircle,
  ProjectHealthCircles,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import revenueAttributionMethods from '~/lookups/revenue-attribution-methods';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Section from './components/Section';
import SlackChannel from './components/SlackChannel';

const Separator = styled.div`
  margin: 2rem -2rem;
  position: relative;
  border-top: 1px solid ${colors.grey25};

  + ${Section} {
    margin-top: 0;
  }
`;

const Info = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
`;

const Administrator = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const AdministratorInfo = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

const ProjectDatesContainer = styled.div`
  flex: 1;
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

const Contact = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  a {
    color: ${colors.grey40};
  }
`;

const Panel = styled.div`
  position: absolute;
  width: calc(20% + 2.5rem);
  background: ${colors.grey5};
  right: -2rem;
  top: calc(-2.5rem - 1px);
  min-height: calc(100% + 4.5rem + 1px);
  padding: 2.75rem 2rem;
`;

const PillLink = styled(Link)`
  &:hover ${Pill} {
    transition: all 100ms ease-in-out;
    background: ${colors.accent};
  }
`;

export default function ProjectDetails({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [client, setClient] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).projects(project.id).client();
        setClient(data);
      } catch (err) {
        // Do nothing
      }
    })();
  }, [api, workspace.id, project]);

  const currency = project.currency;

  return (
    <Panel>
      <Section>
        <h3>Project Details</h3>

        <InfoBlock title="Project Admin">
          {project.administrators.map((admin) => (
            <Administrator key={admin.id}>
              <MemberContactPopover member={admin.member}>
                <Avatar value={admin.member} isCircle hasBackground initialsFontSize=".9rem" />
              </MemberContactPopover>

              <AdministratorInfo>{admin.member.name}</AdministratorInfo>
            </Administrator>
          ))}
        </InfoBlock>

        {project.businessUnit && <InfoBlock title="Business Unit">{project.businessUnit.name}</InfoBlock>}

        {project.salesRepresentative && (
          <InfoBlock title="Sales Representative">
            <Administrator>
              <MemberContactPopover member={project.salesRepresentative}>
                <Avatar value={project.salesRepresentative} isCircle hasBackground initialsFontSize=".9rem" />
              </MemberContactPopover>

              <AdministratorInfo>{project.salesRepresentative.name}</AdministratorInfo>
            </Administrator>
          </InfoBlock>
        )}

        {project.recordStatusId === 'archived' && (
          <InfoBlock title="Active Status">{project.recordStatus.name}</InfoBlock>
        )}

        <InfoBlock title="Currency">
          {project.currencyName} ({project.currency})
        </InfoBlock>

        <InfoBlock title="Billing Type">{project.billingType.name}</InfoBlock>

        {['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
          <InfoBlock title="Revenue Recognition Method">
            {revenueRecognitionMethods[project.revenueRecognitionMethod].name}
          </InfoBlock>
        )}

        {['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
          <InfoBlock title="Revenue Attribution Method">
            {revenueAttributionMethods[project.revenueAttributionMethod].name}
          </InfoBlock>
        )}

        {project.isBillable && project.useBudget && project.permissions.viewRevenue && (
          <InfoBlock title="Total Revenue Budget">
            <Currency value={project.budgetRevenue} currency={currency} />
          </InfoBlock>
        )}

        {project.isBillable && project.useMonthlyBudget && project.permissions.viewRevenue && (
          <InfoBlock title="Monthly Budget">
            <Currency value={project.monthlyBudgetRevenue} currency={currency} /> per month
          </InfoBlock>
        )}

        {project.projectType && <InfoBlock title="Project Type">{project.projectType.name}</InfoBlock>}

        {project.practice && <InfoBlock title="Project Practice">{project.practice.name}</InfoBlock>}

        {project?.tags?.length > 0 && (
          <InfoBlock title="Tags">
            <Pills>
              {project.tags.map((tag) => (
                <PillLink key={tag.id} to={`/app/${workspace.key}/projects?projectTag=${tag.id}`}>
                  <Pill variant="grey10">{tag.name}</Pill>
                </PillLink>
              ))}
            </Pills>
          </InfoBlock>
        )}

        {project.code && <InfoBlock title="Project Code">{project.code}</InfoBlock>}

        {project.latestHealthReport && project.permissions.edit && project.useHealthReports && (
          <InfoBlock title="Project Health">
            <ProjectHealthCircles>
              <ProjectHealthCircle value={project.latestHealthReport.budgetHealth} />
              <ProjectHealthCircle value={project.latestHealthReport.scheduleHealth} />
              <ProjectHealthCircle value={project.latestHealthReport.clientSatisfaction} />
              <ProjectHealthCircle value={project.latestHealthReport.teamSatisfaction} />
            </ProjectHealthCircles>
          </InfoBlock>
        )}

        {(project.start || project.end) && (
          <InfoBlock title="Project Time Frame">
            <ProjectDates project={project} />
          </InfoBlock>
        )}

        <InfoBlock title="Project Status">{project.status.name}</InfoBlock>

        {project.statusId === 'completed' && (
          <InfoBlock title="Completed On">
            <DateTime value={project.completedOn} />
          </InfoBlock>
        )}

        {project.poNumber && <InfoBlock title="PO Number">{project.poNumber}</InfoBlock>}

        {project?.cloudFolderUrl && (
          <InfoBlock title="Cloud Folder">
            <CloudFolderLink url={project.cloudFolderUrl} />
          </InfoBlock>
        )}

        {project.slackChannelId && (
          <InfoBlock title="Slack Channel">
            <SlackChannel project={project} />
          </InfoBlock>
        )}

        {project.description && (
          <InfoBlock title="Notes">
            <MultilineText value={project.description} chars={280} buttonProps={{ isAnchor: true }} />
          </InfoBlock>
        )}
      </Section>

      <Separator />

      <Section>
        <h3>Client Details</h3>

        <InfoBlock title="Client Name">
          <ClientLink client={project.client} />
        </InfoBlock>

        {client && (
          <>
            {client.owner && (
              <InfoBlock title="Relationship Owner">
                <Administrator>
                  <MemberContactPopover member={client.owner}>
                    <Avatar value={client.owner} isCircle hasBackground initialsFontSize=".9rem" />
                  </MemberContactPopover>

                  <AdministratorInfo>{client.owner.name}</AdministratorInfo>
                </Administrator>
              </InfoBlock>
            )}

            {client.salesRepresentative && (
              <InfoBlock title="Sales Representative">
                <Administrator>
                  <MemberContactPopover member={client.salesRepresentative}>
                    <Avatar value={client.salesRepresentative} isCircle hasBackground initialsFontSize=".9rem" />
                  </MemberContactPopover>

                  <AdministratorInfo>{client.salesRepresentative.name}</AdministratorInfo>
                </Administrator>
              </InfoBlock>
            )}

            {project?.client.company && (
              <InfoBlock title={'Company'}>
                <CompanyLink company={project.client.company} />
              </InfoBlock>
            )}

            {client.industry && <InfoBlock title="Client Industry">{client.industry.name}</InfoBlock>}

            {client.practice && <InfoBlock title="Client Practice">{client.practice.name}</InfoBlock>}

            {client.tags?.length > 0 && (
              <InfoBlock title="Tags">
                <Pills>
                  {client.tags.map((tag) => (
                    <PillLink key={tag.id} to={`/app/${workspace.key}/clients?clientTag=${tag.id}`}>
                      <Pill variant="grey10">{tag.name}</Pill>
                    </PillLink>
                  ))}
                </Pills>
              </InfoBlock>
            )}

            {client.location && <InfoBlock title="Client Location">{client.location.name}</InfoBlock>}

            {client.contacts.length > 0 && (
              <InfoBlock title="Contacts">
                {client.contacts.map((contact) => {
                  return (
                    <Contact key={contact.id}>
                      <p>
                        {contact.name} {contact.title && `(${contact.title})`}
                      </p>
                      {contact.email && <a href={`mailto:${contact.email}`}>{contact.email}</a>}
                    </Contact>
                  );
                })}
              </InfoBlock>
            )}

            {client.cloudFolderUrl && (
              <InfoBlock title="Cloud Folder">
                <CloudFolderLink url={client.cloudFolderUrl} />
              </InfoBlock>
            )}

            {client.notes && (
              <InfoBlock title="Notes">
                <MultilineText value={client.notes} chars={280} buttonProps={{ isAnchor: true }} />
              </InfoBlock>
            )}
          </>
        )}
      </Section>
    </Panel>
  );
}

function ProjectDates({ project: { start, end } }) {
  return (
    <ProjectDatesContainer>
      {start && end ? (
        <p>
          <DateTime value={start} /> - <DateTime value={end} />
        </p>
      ) : start ? (
        <p>
          {moment(start).isBefore() ? 'Started' : 'Starts'} <DateTime value={start} />
        </p>
      ) : end ? (
        <p>
          {moment(end).isBefore() ? 'Ended' : 'Ends'} <DateTime value={end} />
        </p>
      ) : null}
    </ProjectDatesContainer>
  );
}
