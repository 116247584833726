import { useCallback, useMemo, useState } from 'react';
import useSearchParamsConfig from './useSearchParamsConfig';

export default function useClientFilters(defaultSearchParams) {
  const filters = useMemo(
    () => ({
      clients: [],
      clientRecordStatusId: null,
      clientPractices: [],
      clientBusinessUnits: [],
      clientSalesRepresentatives: [],
      clientOwners: [],
      clientTags: [],
      clientLocations: [],
      clientIndustries: [],
    }),
    [],
  );

  const [defaultParams] = useState(defaultSearchParams);

  const searchParamsConfig = useSearchParamsConfig();

  const clientSearchParamsConfig = useMemo(
    () => ({
      clients: searchParamsConfig.clients,
      clientRecordStatusId: searchParamsConfig.recordStatusId,
      clientPractices: { ...searchParamsConfig.practices, key: 'clientPractice' },
      clientBusinessUnits: { ...searchParamsConfig.businessUnits, key: 'clientBusinessUnit' },
      clientSalesRepresentatives: { ...searchParamsConfig.members, key: 'clientSalesRepresentative' },
      clientOwners: { ...searchParamsConfig.members, key: 'clientOwner' },
      clientTags: searchParamsConfig.clientTags,
      clientLocations: { ...searchParamsConfig.locations, key: 'clientLocation' },
      clientIndustries: { ...searchParamsConfig.industries, key: 'clientIndustry' },
      ...defaultParams,
    }),
    [searchParamsConfig, defaultParams],
  );

  const mapUrlSearchParams = useCallback((filters) => {
    return {
      clientId: filters.clients?.map((v) => v.id),
      clientRecordStatusId: filters.clientRecordStatusId ?? undefined,
      clientPracticeId: filters.clientPractices?.map((v) => v.id),
      clientBusinessUnitId: filters.clientBusinessUnits?.map((v) => v.id),
      clientSalesRepresentativeId: filters.clientSalesRepresentatives?.map((v) => v.id),
      clientOwnerId: filters.clientOwners?.map((v) => v.id),
      clientTagId: filters.clientTags?.map((v) => v.id),
      clientLocationId: filters.clientLocations?.map((v) => v.id),
      clientIndustryId: filters.clientIndustries?.map((v) => v.id),
    };
  }, []);

  const mapClickThroughParams = useCallback(
    (filters) => ({
      client: filters.clients?.map((v) => v.id),
      clientPractice: filters.clientPractices?.map((v) => v.id),
      clientBusinessUnit: filters.clientBusinessUnits?.map((v) => v.id),
      clientRecordStatusId: filters.clientRecordStatusId ?? undefined,
      clientSalesRepresentative: filters.clientSalesRepresentatives?.map((v) => v.id),
      clientOwner: filters.clientOwners?.map((v) => v.id),
      clientTag: filters.clientTags?.map((v) => v.id),
      clientLocation: filters.clientLocations?.map((v) => v.id),
      clientIndustry: filters.clientIndustries?.map((v) => v.id),
    }),
    [],
  );

  return useMemo(
    () => ({ filters, searchParamsConfig: clientSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams }),
    [filters, clientSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams],
  );
}
