import React from 'react';
import styled from 'styled-components';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableColumn from './TableColumn';
import { TableProvider } from './TableContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableTotal from './TableTotal';
import TableGroupRow from './TableGroupRow';
import TableLoader from './TableLoader';

const TableContents = styled.div`
  min-width: fit-content;
  margin-right: -2rem;
  padding-right: 2rem;
`;

function Table({ ...props }) {
  return (
    <TableProvider>
      <TableContents {...props} />
    </TableProvider>
  );
}

Table.Header = TableHeader;
Table.Column = TableColumn;
Table.Body = TableBody;
Table.Row = TableRow;
Table.GroupRow = TableGroupRow;
Table.Cell = TableCell;
Table.Total = TableTotal;
Table.Loader = TableLoader;

export default Table;
