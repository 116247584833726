import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import MultiSelect from '../MultiSelect';

export default function BusinessUnitFilter({
  materialPlaceholder = 'Business Unit',
  placeholder = 'All',
  name,
  value,
  onChange,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).businessUnits().get();
      setOptions(data);
      setIsLoading(false);
    })();
  }, [workspace.id, api]);

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => (o.isActive ? 0 : 1))
      .groupBy((o) => (o.isActive ? 'Active' : 'Inactive'))
      .value();
  }, [options]);

  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return Object.keys(groups).map((status) => (
          <MultiSelect.Group key={status} label={status}>
            {groups[status].map((option) => (
              <MultiSelect.Option key={option.id} inactive={!option.isActive} {...optionsById[option.id].props}>
                {option.name}
              </MultiSelect.Option>
            ))}
          </MultiSelect.Group>
        ));
      }}
      isLoading={isLoading}
      onChange={onChange}
    />
  );
}
