import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ClientSelect,
  DateTime,
  FiltersBar,
  Icon,
  InternalClientTooltip,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberSelect,
  Page,
  ProjectAdmins,
  ProjectBillingTypeSelect,
  ProjectLink,
  SearchInput,
  Spinner,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { dateFormats, QuerySort, QueryString } from '~/utils';
import ProjectDrawer from '../projects/project-drawer/ProjectDrawer';
import ProjectDeleteConfirmation from '../projects/ProjectDeleteConfirmation';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Status = styled.span`
  color: ${({ status }) =>
    ({
      tentative: colors.grey25,
      not_started: colors.warning,
      in_progress: colors.success,
      paused: colors.danger,
      completed: colors.black,
      cancelled: colors.grey55,
    })[status]};
`;

export default function MyProjects() {
  const documentTitle = useDocumentTitle('My Projects');

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();

  const [query, setQuery] = useState({ status: 'loading', data: null });

  const toast = useToast();
  const history = useHistory();
  const [dialog, setDialog] = useState(null);
  const [member, setMember] = useState();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();

  const currentMember = member || workspace.member;
  const { id: memberId } = currentMember;

  const [params, setParams] = useState({
    q: '',
    client: null,
    administrator: null,
    billingTypeId: null,
    sort: new QuerySort('project.name', 'asc'),
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        client: searchParamsConfig.client,
        administrator: searchParamsConfig.member,
        billingTypeId: searchParamsConfig.projectBillingType,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'my_projects',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      clientId: params.client?.id,
      administratorId: params.administrator?.id,
      billingTypeId: params.billingTypeId ?? undefined,
      memberId: memberId,
      sort: params.sort,
    }),
    [params, memberId],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).personalDashboard().myProjects(urlSearchParams);
      setQuery((state) => ({ ...state, data, status: 'ready' }));
    } catch (error) {
      toast.error(error.message);
    }
  }, [api, workspace.id, toast, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const closeDialog = () => {
    setDialog(null);
  };

  const handleDeleted = () => {
    fetchData();
    closeDialog();
  };

  const handleDelete = (project) => {
    confirmation.prompt((resolve) => (
      <ProjectDeleteConfirmation
        project={project}
        onClose={resolve}
        onDelete={() => {
          handleDeleted();
          resolve(true);
        }}
      />
    ));
  };

  const [timeEntryDrawer, setTimeEntryDrawer] = useState(null);

  const filterMembers = useMemo(() => {
    return [workspace.member.id];
  }, [workspace]);

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Title>My Projects</Page.Title>
        </Page.Header>

        <Page.Filters>
          <FiltersBar>
            <SearchInput
              name="q"
              placeholder="Search"
              materialPlaceholder="Project or Client Name"
              materialAlwaysVisible
              onChange={handleChange}
              value={params.q}
            />

            <ClientSelect
              name="client"
              placeholder="All"
              materialPlaceholder="Client"
              materialAlwaysVisible
              activeOnly={false}
              value={params.client}
              onChange={handleChange}
            />

            <MemberSelect
              name="administrator"
              placeholder="All"
              materialPlaceholder="Project Admin"
              materialAlwaysVisible
              value={params.administrator}
              onChange={handleChange}
            />

            {auth.members.manageTimeAndExpenses && (
              <MemberSelect
                filterMembers={filterMembers}
                placeholder="Teammates"
                permission="manageTimeAndExpenses"
                onChange={(event) => setMember(event.target.value)}
                value={member}
                align="right"
              />
            )}

            <ProjectBillingTypeSelect
              name="billingTypeId"
              placeholder="All"
              materialPlaceholder="Billing Type"
              materialAlwaysVisible
              value={params.billingTypeId}
              showEmptyOption
              onChange={handleChange}
            />
          </FiltersBar>
        </Page.Filters>

        <Page.ListView>
          <ListView>
            <ListView.Status>
              {query.status !== 'ready' && <Spinner />}
              <ListView.Total value={query.data.length} label="Project" />
            </ListView.Status>

            <ListView.Header>
              <ListView.Column sticky minWidth="16rem" name="project.name" onSort={handleSort} sort={params.sort}>
                Project
              </ListView.Column>
              <ListView.Column width="10rem" name="project.statusId" onSort={handleSort} sort={params.sort}>
                Status
              </ListView.Column>
              <ListView.Column minWidth="16rem">Project Admin</ListView.Column>
              <ListView.Column width="10rem" name="project.billingTypeId" onSort={handleSort} sort={params.sort}>
                Billing Type
              </ListView.Column>
              <ListView.Column width="8rem" align="right" name="createdAt" onSort={handleSort} sort={params.sort}>
                Created
              </ListView.Column>
              <ListViewActions.Column />
            </ListView.Header>

            <ListView.Body fade={query.status === 'filtering'}>
              {query.data.map((project) => {
                const handleEdit = () => {
                  setDialog({ type: 'edit', project });
                };

                const handleViewClick = () => {
                  history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}`);
                };

                return (
                  <ListView.Row key={project.id} onClick={handleViewClick}>
                    <ListView.Cell>
                      <ProjectInfo>
                        <p style={{ display: 'flex' }}>
                          <ProjectLink project={project} onClick={(e) => e.stopPropagation()} />
                          {project.lockTimeAndExpenses && (
                            <Tooltip message="Time and expenses have been locked for this project." placement="right">
                              <Icon icon="lock" color={colors.grey40} spaceLeft />
                            </Tooltip>
                          )}
                        </p>
                        <small>
                          {project.client.name}
                          {project.client.isInternal && <InternalClientTooltip />}
                        </small>
                      </ProjectInfo>
                    </ListView.Cell>
                    <ListView.Cell>
                      <Status status={project.status.id}>{project.status.name}</Status>
                    </ListView.Cell>
                    <ListView.Cell>
                      <ProjectAdmins project={project} />
                    </ListView.Cell>
                    <ListView.Cell>{project.billingType.name}</ListView.Cell>
                    <ListView.Cell>
                      <DateTime value={project.createdAt} />
                    </ListView.Cell>

                    <ListViewActions>
                      {project.permissions.edit ? (
                        <ListViewActions.Edit onClick={handleEdit} />
                      ) : (
                        <ListViewActions.View onClick={handleViewClick} />
                      )}

                      <hr />

                      <ListViewMenu>
                        {() => {
                          return (
                            <>
                              <ListViewMenu.Item onClick={handleViewClick}>View</ListViewMenu.Item>
                              <ListViewMenu.Item
                                disabled={!project.permissions.edit}
                                tooltip={
                                  !project.permissions.edit
                                    ? 'Insufficient permissions to edit this project.'
                                    : undefined
                                }
                                onClick={handleEdit}>
                                Edit
                              </ListViewMenu.Item>

                              <ListViewMenu.Link
                                to={`/app/${workspace.key}/reports/time/time-entries?${new QueryString({
                                  start: 'not_set',
                                  end: 'not_set',
                                  project: project.id,
                                  member: workspace.member.id,
                                })}`}>
                                View Time Entries
                              </ListViewMenu.Link>

                              <ListViewMenu.Item
                                onClick={() => setTimeEntryDrawer({ project })}
                                disabled={project.lockTimeAndExpenses}
                                tooltip={
                                  project.lockTimeAndExpenses
                                    ? 'This project does not allow adding new time entries.'
                                    : null
                                }>
                                Track Time
                              </ListViewMenu.Item>

                              <ListViewMenu.DeleteItem
                                disabled={!project.permissions.edit}
                                tooltip={
                                  !project.permissions.edit
                                    ? 'Insufficient permissions to delete this project.'
                                    : undefined
                                }
                                onClick={() => handleDelete(project)}>
                                Delete
                              </ListViewMenu.DeleteItem>
                            </>
                          );
                        }}
                      </ListViewMenu>
                    </ListViewActions>
                  </ListView.Row>
                );
              })}

              {query.data.length === 0 && <ListView.Empty />}
            </ListView.Body>
          </ListView>
        </Page.ListView>
      </Page>

      {dialog && (
        <ProjectDrawer
          projectId={dialog.project.id}
          onSaved={fetchData}
          onDeleted={handleDeleted}
          onClose={closeDialog}
          fetchData={fetchData}
        />
      )}

      {timeEntryDrawer && (
        <EditTimeEntry
          initialValues={{ projectId: timeEntryDrawer.project.id, date: moment().format(dateFormats.isoDate) }}
          onClose={() => {
            documentTitle.set('My Projects');
            setTimeEntryDrawer(null);
          }}
          onSaved={() => {
            notify(useSubscription.keys.refresh_timer);
            fetchData();
          }}
        />
      )}
    </>
  );
}
