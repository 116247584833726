import { useFeatures } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { BusinessUnitsPage } from '../business-units';
import { ClientTagsPage } from '../client-tags';
import { CompanySpendTiersPage } from '../company-spend-tiers';
import { CompanyTypesPage } from '../company-types';
import { DisciplinesPage } from '../disciplines';
import { ExpenseCategoriesPage } from '../expense-categories';
import { HolidaySchedulesPage } from '../holiday-schedules';
import { HolidaysPage } from '../holidays';
import { IndustriesPage } from '../industries';
import { InvoiceItemsPage } from '../invoice-items';
import { JobTitlesPage } from '../job-titles';
import { LocationsPage } from '../locations';
import { MemberTagsPage } from '../member-tags';
import { OpportunityTypesPage } from '../opportunity-types';
import { OpportunityLeadSourcesPage } from '../opportunityLeadSource';
import { PaymentMethodsPage } from '../payment-methods';
import { PipelineActivityTypesPage } from '../pipeline-activity-type';
import { OpportunityStagesPage } from '../opportunity-stages';
import { PracticesPage } from '../practices';
import { ProjectTagsPage } from '../project-tags';
import { ProjectTaskTagsPage } from '../project-task-tags';
import { ProjectTaskTemplatesPage } from '../project-task-templates';
import { ProjectTypesPage } from '../project-types';
import { ResourcePlaceholdersPage } from '../resource-placeholders';
import { SkillsPage } from '../skills';
import { TaxRatesPage } from '../tax-rates';
import { TimeOffTypesPage } from '../time-off-types';
import { WorkspaceRolesPage } from '../workspace-roles';
import CustomDataListPage from './CustomDataListPage';
import { OpportunityTagsPage } from '../opportunity-tags';
import { Page } from '~/components';

export default function CustomDataArea() {
  const { path, url } = useRouteMatch();
  const features = useFeatures();

  return (
    <Page>
      <Switch>
        <Route path={path} exact>
          <CustomDataListPage />
        </Route>

        {features.businessUnits && (
          <Route path={`${path}/business-units`}>
            <BusinessUnitsPage />
          </Route>
        )}

        <Route path={`${path}/client-tags`}>
          <ClientTagsPage />
        </Route>

        {features.pipeline && (
          <Route path={`${path}/company-spend-tiers`}>
            <CompanySpendTiersPage />
          </Route>
        )}

        {features.pipeline && (
          <Route path={`${path}/company-types`}>
            <CompanyTypesPage />
          </Route>
        )}

        {features.disciplines && (
          <Route path={`${path}/disciplines`}>
            <DisciplinesPage />
          </Route>
        )}

        <Route path={`${path}/expense-categories`}>
          <ExpenseCategoriesPage />
        </Route>

        <Route path={`${path}/holiday-schedules`}>
          <HolidaySchedulesPage />
        </Route>

        {features.pipeline && (
          <Route path={`${path}/opportunity-tags`}>
            <OpportunityTagsPage />
          </Route>
        )}

        {features.pipeline && (
          <Route path={`${path}/opportunity-types`}>
            <OpportunityTypesPage />
          </Route>
        )}

        <Route path={`${path}/holidays`}>
          <HolidaysPage />
        </Route>

        <Route path={`${path}/industries`}>
          <IndustriesPage />
        </Route>

        <Route path={`${path}/invoice-items`}>
          <InvoiceItemsPage />
        </Route>

        <Route path={`${path}/job-titles`}>
          <JobTitlesPage />
        </Route>

        <Route path={`${path}/locations`}>
          <LocationsPage />
        </Route>

        <Route path={`${path}/skills`}>
          <SkillsPage />
        </Route>

        <Route path={`${path}/member-tags`}>
          <MemberTagsPage />
        </Route>

        {features.pipeline && (
          <Route path={`${path}/opportunity-lead-sources`}>
            <OpportunityLeadSourcesPage />
          </Route>
        )}

        {features.pipeline && (
          <Route path={`${path}/opportunity-types`}>
            <OpportunityTypesPage />
          </Route>
        )}

        <Route path={`${path}/payment-methods`}>
          <PaymentMethodsPage />
        </Route>

        {features.pipeline && (
          <Route path={`${path}/pipeline-activity-types`}>
            <PipelineActivityTypesPage />
          </Route>
        )}

        {features.pipeline && (
          <Route path={`${path}/opportunity-stages`}>
            <OpportunityStagesPage />
          </Route>
        )}

        {features.practices && (
          <Route path={`${path}/practices`}>
            <PracticesPage />
          </Route>
        )}

        <Route path={`${path}/project-tags`}>
          <ProjectTagsPage />
        </Route>

        <Route path={`${path}/project-types`}>
          <ProjectTypesPage />
        </Route>

        {features.allocations && (
          <Route path={`${path}/resource-placeholders`}>
            <ResourcePlaceholdersPage />
          </Route>
        )}

        <Route path={`${path}/task-tags`}>
          <ProjectTaskTagsPage />
        </Route>

        <Route path={`${path}/tax-rates`}>
          <TaxRatesPage />
        </Route>

        <Route path={`${path}/task-templates`}>
          <ProjectTaskTemplatesPage />
        </Route>

        <Route path={`${path}/time-off-types`}>
          <TimeOffTypesPage />
        </Route>

        {features.workspaceRoles && (
          <Route path={`${path}/workspace-roles-and-rates`}>
            <WorkspaceRolesPage />
          </Route>
        )}

        <Redirect from="/" to={url} />
      </Switch>
    </Page>
  );
}
