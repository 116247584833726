import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  ClientSelect,
  Currency,
  DateTime,
  ExportDialog,
  FiltersBar,
  InlineTooltip,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  RouteLink,
  SearchInput,
  SingleSelect,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { useActions, useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import ExportDropdown from '~/routes/app/settings/ExportDropdown.jsx';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import QBOIndicator from '../components/QBOIndicator';
import SentIndicator from '../components/SentIndicator';
import XeroIndicator from '../components/XeroIndicator';
import CreateDialog from '../dialogs/CreateDialog';
import DeleteDialog from '../dialogs/DeleteDialog';
import LoadFromQuickBooksDialog from '../dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../dialogs/LoadFromXeroDialog';
import PublishDialog from '../dialogs/PublishDialog';
import UnpublishDialog from '../dialogs/UnpublishDialog';
import WebLinkModal from '../dialogs/WebLinkModal';
import SendCreditNoteDrawer from '../send-drawer/SendCreditNoteDrawer';
import CreditNoteStatusSelect from './CreditNoteStatusSelect';
import intervals from './intervals';

const Small = styled.small`
  display: block;
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    statusIds: null,
    sort: new QuerySort('issuedOn', 'desc'),
    page: 0,
    size: 50,
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItems: (items, { data }) => ({
    data: {
      ...data,
      results: data.results.map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        return item ? { ...result, ...item } : result;
      }),
    },
  }),
  removeItem: (id, { data }) => ({
    dialog: null,
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function CreditNotesListPage({ onRowClick, sessionKey }) {
  useDocumentTitle('Credit Notes');

  const { workspace } = useWorkspace();
  const api = useApi();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [dialog, setDialog] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const auth = useAuth();
  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        statusIds: {
          default: 'draft,open',
          valid: ['all', 'draft,open', 'draft', 'open', 'applied'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        period: {
          default: initialState.query.period,
          valid: ['all', ..._.keys(intervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        client: searchParamsConfig.client,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),

      [searchParamsConfig],
    ),
    sessionKey,
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = intervals[query.period] || {};

      const { data } = await api.www
        .workspaces(workspace.id)
        .creditNotes()
        .get({
          ..._.omit(query, 'period', 'client'),
          start,
          end,
          q: query.q || undefined,
          statusIds: query.statusIds || undefined,
          statusId: query.statusId || undefined,
          clientId: query.client?.id,
        });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: { total: 0, results: [] } });
    }
  }, [actions, workspace.id, query, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const integrations = useIntegrations();

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    actions.setParams({ ...value });
    searchParams.set({ ...value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleRowClick = (creditNote) => {
    if (onRowClick) onRowClick(creditNote);
    else history.push(`${url}/${creditNote.id}`);
  };

  const refreshSummary = () => {
    setRefreshKey(refreshKey + 1);
  };

  const reload = async (...ids) => {
    const creditNotes = await api.www
      .workspaces(workspace.id)
      .creditNotes()
      .get({ ids })
      .then((res) => res.data);

    actions.updateItems(creditNotes);

    refreshSummary();

    return creditNotes;
  };

  const handleDelete = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <DeleteDialog
        creditNote={creditNote}
        onClose={resolve}
        onDelete={() => {
          actions.removeItem(creditNote.id);
          refreshSummary();
          resolve();
        }}
      />
    ));
  };

  const handlePublish = async (creditNote) => {
    return await confirmation.prompt((resolve) => (
      <PublishDialog
        creditNoteId={creditNote.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await reload(creditNote.id);
          resolve(true);
        }}
      />
    ));
  };

  const handleUnpublish = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <UnpublishDialog
        creditNoteId={creditNote.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleGetWebLink = (creditNote) => {
    setDialog({ type: 'webLink', creditNote });
  };

  const handleSend = async (creditNote) => {
    const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).get();
    setDialog({ type: 'send', creditNote: data });
  };

  const handlePublishAndSend = async (creditNote) => {
    const isPublished = await handlePublish(creditNote);
    if (isPublished) {
      await handleSend(creditNote);
    }
  };

  const handleSent = async (creditNote) => {
    await reload(creditNote.id);
  };

  const handleCreateCreditNote = () => {
    setDialog({ type: 'create' });
  };

  const handleReloadFromQuickBooks = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        creditNote={creditNote}
        resolve={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        creditNote={creditNote}
        resolve={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleExport = async (filename, mimeType) => {
    const { start, end } = intervals[query.period] || {};

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .creditNotes()
          .export(
            {
              ..._.omit(query, 'period', 'client'),
              size: null,
              start,
              end,
              q: query.q || undefined,
              statusIds: query.statusIds || undefined,
              statusId: query.statusId || undefined,
              clientId: query.client?.id,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Billing</Page.Eyebrow>
          <Page.Title>Credit Notes</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`credit-notes.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`credit-notes.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <Button
            disabled={!auth.invoices.manage.client}
            style={{ position: 'relative' }}
            onClick={handleCreateCreditNote}>
            Create Credit Note
            {!auth.invoices.manage.client && (
              <InlineTooltip message="Your security role prohibits you from creating credit notes." />
            )}
          </Button>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput
            value={query.q}
            placeholder="Search"
            materialPlaceholder="Client or Credit Note #"
            materialAlwaysVisible
            onChange={({ target: { value } }) => handleFilter({ q: value })}
          />

          <ClientSelect
            name="client"
            placeholder="All"
            materialAlwaysVisible
            materialPlaceholder="Client"
            activeOnly={false}
            value={query.client}
            onChange={({ target: { value } }) => handleFilter({ client: value })}
          />

          <CreditNoteStatusSelect
            value={query.statusIds}
            onChange={({ target: { value } }) => handleFilter({ statusIds: value })}
          />

          <SingleSelect
            placeholder="All"
            materialPlaceholder="Issue Date"
            materialAlwaysVisible
            showEmptyOption
            value={query.period}
            onChange={({ target: { value } }) => handleFilter({ period: value })}>
            {_.map(intervals, ({ label }, key) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </SingleSelect>
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Status>
            {!!action && <Spinner />}
            <ListView.Total value={data.total} label="Credit Note" />
          </ListView.Status>

          <ListView.Header>
            <ListView.Column sticky width="8rem" name="transactionNumber" onSort={handleSort} sort={query.sort}>
              #
            </ListView.Column>
            <ListView.Column minWidth="16rem" name="client.name" onSort={handleSort} sort={query.sort}>
              Client
            </ListView.Column>
            <ListView.Column minWidth="16rem">Projects</ListView.Column>
            <ListView.Column width="10rem" name="issuedOn" onSort={handleSort} sort={query.sort}>
              Issued
            </ListView.Column>
            <ListView.Column width="6rem" name="statusId" onSort={handleSort} sort={query.sort}>
              Status
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedBalance" onSort={handleSort} sort={query.sort}>
              Remaining
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedTotal" onSort={handleSort} sort={query.sort}>
              Total
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {data.results.map((creditNote) => {
              const { id, number, issuedOn, status, statusId, sentAt, qboCreditMemoId, xeroCreditNoteId } = creditNote;

              const publish = !creditNote.permissions.manage
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to publish this credit note.',
                  }
                : creditNote.total < 0
                  ? { disabled: true, tooltip: 'Credit Note total must be zero or greater.' }
                  : qboCreditMemoId
                    ? { disabled: true, tooltip: 'This credit note has been saved to QuickBooks Online.' }
                    : xeroCreditNoteId
                      ? { disabled: true, tooltip: 'This credit note has been saved to Xero.' }
                      : { disabled: false, tooltip: undefined };

              const unpublish = !creditNote.permissions.manage
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to unpublish this credit note.',
                  }
                : qboCreditMemoId
                  ? { disabled: true, tooltip: 'This credit note has been saved to QuickBooks Online.' }
                  : xeroCreditNoteId
                    ? { disabled: true, tooltip: 'This credit note has been saved to Xero.' }
                    : { disabled: false, tooltip: undefined };

              return (
                <ListView.Row key={id} data-testid={id} onClick={() => handleRowClick(creditNote)}>
                  <ListView.Cell>
                    <RouteLink
                      to={`/app/${workspace.key}/billing/credit-notes/${id}`}
                      style={{ wordBreak: 'break-word' }}>
                      {number}
                    </RouteLink>
                  </ListView.Cell>
                  <ListView.Cell>{creditNote.client.name}</ListView.Cell>
                  <ListView.Cell>
                    <FirstProject creditNote={creditNote} />
                    <Projects creditNote={creditNote} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={issuedOn} />
                    {sentAt && <SentIndicator sentAt={sentAt} />}
                  </ListView.Cell>
                  <ListView.Cell>
                    {status.name}

                    {qboCreditMemoId && (
                      <span>
                        <QBOIndicator />
                      </span>
                    )}

                    {xeroCreditNoteId && (
                      <span>
                        <XeroIndicator xeroCreditNoteId={xeroCreditNoteId} />
                      </span>
                    )}
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={creditNote.convertedBalance} currency={workspace.currency} />
                      {creditNote.currency !== workspace.currency && (
                        <Small>
                          <Currency value={creditNote.balance} currency={creditNote.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={creditNote.convertedTotal} currency={workspace.currency} />
                      {creditNote.currency !== workspace.currency && (
                        <Small>
                          <Currency value={creditNote.total} currency={creditNote.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>

                  <ListViewActions>
                    {status.id === 'draft' ? (
                      <ListViewActions.Edit
                        onClick={() => history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`)}
                      />
                    ) : (
                      <ListViewActions.View
                        onClick={() => history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`)}
                      />
                    )}

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return {
                          draft: (
                            <>
                              <ListViewMenu.Item
                                disabled={publish.disabled}
                                tooltip={publish.tooltip}
                                onClick={() => handleAction(() => handlePublish(creditNote))}>
                                Publish
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={publish.disabled}
                                tooltip={publish.tooltip}
                                onClick={() => handleAction(() => handlePublishAndSend(creditNote))}>
                                Publish & Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                                Edit
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/credit-notes/${creditNote.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(creditNote))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to delete this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(creditNote))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),

                          open: (
                            <>
                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to send this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(creditNote))}>
                                Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={unpublish.disabled}
                                tooltip={unpublish.tooltip}
                                onClick={() => handleAction(() => handleUnpublish(creditNote))}>
                                Unpublish
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                                View
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/credit-notes/${creditNote.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(creditNote))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              {integrations.qbo && creditNote.qboCreditMemoId && (
                                <ListViewMenu.Item
                                  disabled={!creditNote.permissions.manage}
                                  tooltip={
                                    !creditNote.permissions.manage
                                      ? 'Insufficient permissions to reload this credit note from QuickBooks Online.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => handleReloadFromQuickBooks(creditNote))}>
                                  Reload from QuickBooks
                                </ListViewMenu.Item>
                              )}

                              {!!integrations.xero && creditNote.xeroCreditNoteId && (
                                <ListViewMenu.Item
                                  disabled={!creditNote.permissions.manage}
                                  tooltip={
                                    !creditNote.permissions.manage
                                      ? 'Insufficient permissions to reload this credit note from Xero.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => handleReloadFromXero(creditNote))}>
                                  Reload from Xero
                                </ListViewMenu.Item>
                              )}

                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to delete this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(creditNote))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),
                        }[statusId];
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && (
              <ListView.Loader key={data.results.length} onIntersecting={actions.loadMore} />
            )}
          </ListView.Body>
        </ListView>
      </Page.ListView>

      {dialog &&
        {
          webLink: () => <WebLinkModal creditNote={dialog.creditNote} onClose={handleCloseDialog} />,
          send: () => (
            <SendCreditNoteDrawer creditNote={dialog.creditNote} onSent={handleSent} onClose={handleCloseDialog} />
          ),
          create: () => <CreateDialog onClose={handleCloseDialog} />,
        }[dialog.type]()}
    </Page>
  );
}

const FirstProject = ({ creditNote }) => {
  const project = creditNote.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ creditNote }) => {
  let projectsCount = creditNote.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {creditNote.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default CreditNotesListPage;
