import {
  Avatar,
  BillableIcon,
  Currency,
  Hours,
  Icon,
  Level,
  MemberContactPopover,
  SingleSelect,
  Stack,
  Table,
  Tooltip,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { QueryString } from '~/utils';
import List from '../reports/components/List.jsx';

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
`;

const Card = styled.div`
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  border-radius: 8px;
  position: relative;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 5px 15px 0px ${colors.grey25};
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      ${AvatarContainer}, ${Info}, ${Footer} {
        opacity: 0.3;
      }
    `}
`;

const Body = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex: 1;
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const Billable = styled.div`
  border: 2px solid ${colors.grey5};
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

const Info = styled.div`
  margin-left: 1rem;
`;

const Name = styled.p`
  font-size: 1.25rem;
`;

const Role = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 0.25rem;
  }
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  > div:not(:first-child) {
    margin-top: 0.5rem;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
`;

const Footer = styled.div`
  padding: 0.75rem 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HoursWorkedLink = styled(Link)`
  font-size: 1.25rem;
  display: flex;
  align-items: center;

  small {
    font-size: 0.75rem;
    margin-left: 0.75rem;
  }
`;

const Rate = ({ memberRole, currency }) => {
  if (!memberRole.isActuallyBillable) return '(Non-billable)';
  if (memberRole.actualRate == null) return null;
  return (
    <>
      (<Currency value={memberRole.actualRate} maximumFractionDigits={7} currency={currency} />
      /hr)
    </>
  );
};

const MemberRate = ({ member, currency }) => {
  if (!member.isActuallyBillable) return <div style={{ fontSize: '0.75rem' }}>(Non-billable)</div>;
  if (member.rate == null) return null;
  return (
    <div style={{ fontSize: '0.75rem' }}>
      (<Currency value={member.rate} maximumFractionDigits={7} currency={currency} />
      /hr)
    </div>
  );
};

const initialState = {
  isReady: false,
  data: null,
  query: { isActive: 'true', size: 1000 },
};

const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data }),
  filter: (query) => ({ query }),
  updateItem: (item, { data }) => ({
    data: data.some((i) => i.id === item.id) ? data.map((i) => (i.id === item.id ? item : i)) : [...data, item],
  }),
  removeItem: (id, { data }) => ({ data: data.filter((i) => i.id !== id) }),
};

function ProjectTeamTab({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, data, query }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().team(query);
      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, project.id, query, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const handleFilter = ({ target: { name, value } }) => {
    actions.filter({ ...query, [name]: value });
  };

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const currency = project.currency;

  return (
    <>
      <Level margin="2.5rem 0">
        <Level.Item width="20rem">
          <SingleSelect
            name="isActive"
            placeholder="All"
            materialPlaceholder="Member Status"
            materialAlwaysVisible
            showEmptyOption
            value={query.isActive}
            onChange={handleFilter}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </SingleSelect>
        </Level.Item>
      </Level>

      <Stack>
        <Table.Total value={data.length} label="Team Member" />

        <Grid>
          {data.map((projectMember) => {
            const {
              member,
              isActuallyBillable,
              roles,
              billableHours,
              nonBillableHours,
              internalHours,
              hoursWorked,
              isActuallyActive,
            } = projectMember;

            return (
              <Card key={member.id} isActive={isActuallyActive}>
                <Body>
                  <AvatarContainer>
                    <MemberContactPopover member={member} placement="right">
                      <Avatar value={member} isCircle size={70} fontSize="1.125rem" hasBackground hasBorder />
                    </MemberContactPopover>

                    {!project.useRoles && (
                      <Billable>
                        <BillableIcon value={isActuallyBillable} />
                      </Billable>
                    )}
                  </AvatarContainer>
                  <Info>
                    <Name>
                      {member.name}

                      {projectMember.typeId === 'administrator' && (
                        <Tooltip
                          placement="right"
                          message="Project Admin"
                          style={{ display: 'inline', marginLeft: '0.5rem' }}>
                          <Icon icon="user-gear" color={colors.primary} />
                        </Tooltip>
                      )}
                    </Name>
                    {project.useRoles
                      ? roles.map(
                          (memberRole) =>
                            memberRole.role.isActive && (
                              <Role key={memberRole.id}>
                                <BillableIcon value={memberRole.isActuallyBillable} />
                                {memberRole.role.name}{' '}
                                {project.permissions.viewRates && <Rate memberRole={memberRole} currency={currency} />}
                              </Role>
                            ),
                        )
                      : project.permissions.viewRates && <MemberRate member={projectMember} currency={currency} />}
                  </Info>
                  <Actions>
                    <div>
                      <a href={`mailto:${member.email}`}>
                        <Icon type="far" icon="envelope" />
                      </a>
                    </div>
                  </Actions>
                </Body>
                {project.permissions.viewTimeAndExpenses && (
                  <>
                    <hr />
                    <Footer>
                      <Tooltip
                        message={
                          <List>
                            <List.Item label="Billable">
                              <Hours value={billableHours} minimumFractionDigits={0} />
                            </List.Item>
                            <List.Item label="Non-Billable">
                              <Hours value={nonBillableHours} minimumFractionDigits={0} />
                            </List.Item>
                            <List.Item label="Internal">
                              <Hours value={internalHours} minimumFractionDigits={0} />
                            </List.Item>
                          </List>
                        }>
                        <HoursWorkedLink
                          to={`/app/${workspace.key}/reports/time/time-entries?${new QueryString({
                            start: 'not_set',
                            end: 'not_set',
                            member: member.id,
                            project: project.id,
                          }).toString()}`}>
                          <Hours value={hoursWorked} minimumFractionDigits={0} /> <small>Hours Worked</small>
                        </HoursWorkedLink>
                      </Tooltip>
                    </Footer>
                  </>
                )}
              </Card>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
}

export default ProjectTeamTab;
