import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Currency, ExportDialog, FiltersBar, ListView, MemberSelect, Page, RouteLink } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';

export default function ExpensesByCategory({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [{ isReady, data }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({ member: null });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .expensesByCategory({ projectMemberId: params.member?.id });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .expensesByCategory(
            { projectMemberId: params.member?.id },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const expenseDetail = (query = {}) =>
    `/app/${workspace.key}/reports/expenses/expense-items${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      member: params.member?.id,
      ...query,
    }).toString(true)}`;

  const url = {
    total: (expenseCategory) => expenseDetail({ expenseCategory }),
    billable: (expenseCategory) => expenseDetail({ billableType: 'billable', expenseCategory }),
    non_billable: (expenseCategory) => expenseDetail({ billableType: 'non_billable', expenseCategory }),
  };

  const currency = project.currency;

  return (
    <>
      <Page.Filters>
        <FiltersBar>
          <MemberSelect
            name="member"
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={params.member}
            onChange={({ target: { value } }) => handleFilter({ member: value })}
          />
        </FiltersBar>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Status>
            <ListView.Total value={records.length} label="Result" />
          </ListView.Status>

          <ListView.Header>
            <ListView.Column sticky minWidth="16rem">
              Category
            </ListView.Column>

            <ListView.Column align="right" width="5rem">
              Items
            </ListView.Column>

            <ListView.Column align="right" width="8rem" isVisible={columns.totalAmount}>
              Total Spent
            </ListView.Column>

            <ListView.Column align="right" width="8rem" isVisible={columns.budgetBillableAmount}>
              Budget Billable
            </ListView.Column>

            <ListView.Column align="right" width="8rem" isVisible={columns.billableAmount}>
              Billable Spent
            </ListView.Column>

            <ListView.Column align="right" width="8rem" isVisible={columns.budgetNonBillableAmount}>
              <p>
                Budget <nobr>Non-billable</nobr>
              </p>
            </ListView.Column>

            <ListView.Column align="right" width="8rem">
              Non-billable Spent
            </ListView.Column>
          </ListView.Header>

          <ListView.Body showNoResults={false}>
            {records.map((category) => {
              return (
                <ListView.Row key={category.id} data-testid="row">
                  <ListView.Cell>
                    <RouteLink to={url.total(category.id)}>{category.name}</RouteLink>
                  </ListView.Cell>

                  <ListView.Cell>{category.itemCount}</ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <RouteLink to={url.total(category.id)}>
                        <Currency value={category.totalAmount} currency={currency} />
                      </RouteLink>
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.budgetBillableAmount} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <RouteLink to={url.billable(category.id)}>
                        <Currency value={category.billableAmount} currency={currency} />
                      </RouteLink>
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.budgetNonBillableAmount} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <RouteLink to={url.non_billable(category.id)}>
                        <Currency value={category.nonBillableAmount} currency={currency} />
                      </RouteLink>
                    </ListView.CellContent>
                  </ListView.Cell>
                </ListView.Row>
              );
            })}
          </ListView.Body>

          <ListView.Row style={{ fontWeight: weights.bold }}>
            <ListView.Cell>
              <RouteLink to={url.total()}>Total</RouteLink>
            </ListView.Cell>

            <ListView.Cell>{totals.itemCount}</ListView.Cell>

            <ListView.Cell>
              <RouteLink to={url.total()}>
                <Currency value={totals.totalAmount} currency={currency} />
              </RouteLink>
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetBillableAmount} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <RouteLink to={url.billable()}>
                <Currency value={totals.billableAmount} currency={currency} />
              </RouteLink>
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetNonBillableAmount} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <RouteLink to={url.non_billable()}>
                <Currency value={totals.nonBillableAmount} currency={currency} />
              </RouteLink>
            </ListView.Cell>
          </ListView.Row>
        </ListView>
      </Page.ListView>
    </>
  );
}
