import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  ActionsBanner,
  Button,
  ButtonBadge,
  DeleteButton,
  Icon,
  ListView,
  Page,
  Spinner,
  SplitButton,
  TooltipButton,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { ExpenseItemDrawer } from '../item/ExpenseItemDrawer';
import ExpenseItemRow from '../item/ExpenseItemRow';
import DeleteExpenseReportConfirmation from './DeleteExpenseReportConfirmation';
import Drawer from './Drawer';
import Header from './Header';
import ImportFileModal from './ImportFileModal';

const EditLinkContainer = styled.div`
  width: max-content;
  position: sticky;
  left: 50%;
`;

const EditLink = styled(Link)`
  align-self: center;
  margin-top: 1rem;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.grey10};
  }

  transition: all 100ms ease-in-out;
`;

const SaveButton = styled(ActionButton)`
  min-width: 8.25rem;
  position: relative;
`;

const SplitButtonItem = styled(SplitButton.Item)`
  border-radius: 0;
  background-color: ${colors.white};
  color: ${colors.grey100};
  font-weight: ${weights.normal};

  &:hover {
    color: ${colors.grey100};
    font-weight: ${weights.normal};
    background-color: ${colors.grey5};
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  & > * {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const NoResults = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 3rem;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
`;

const NoResultsMessage = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

function DetailPage({ member }) {
  const { number } = useParams();
  useDocumentTitle(`Expense Report ${number}`);
  const { workspace } = useWorkspace();
  const { path, url } = useRouteMatch();

  // const [result, setResult] = useState(null);
  const [dialog, setDialog] = useState(null);
  const history = useHistory();
  const api = useApi();
  const toast = useToast();

  const [{ isReady, expenseReport }, actions] = useActions(handlers, initialState);
  const { notify } = useSubscription();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const fetchData = useCallback(
    async (num) => {
      try {
        const query = { memberId: member ? member.id : workspace.member.id };
        const { data: expenseReport } = await api.www.workspaces(workspace.id).expenseReports(num).get(query);
        actions.ready({ expenseReport });
      } catch (error) {
        actions.ready({ expenseReport: null });
      }
    },
    [actions, workspace.id, member, workspace.member.id, api],
  );

  async function handleSaved() {
    handleCloseDialog();
    await fetchData(number);
  }

  function onDelete() {
    history.push('../reports');
  }

  const handleCloseDialog = () => {
    setDialog(null);
  };

  async function handleSubmit() {
    try {
      await api.www.workspaces(workspace.id).expenseReports(expenseReport.id).submit();
      await handleSaved();
      notify(useSubscription.keys.refresh_expense_approval_count);
      toast.success('Expense items have been submitted for approval.');
    } catch (error) {
      actions.ready({ expenseReport: null });
    }
  }

  useEffect(() => {
    fetchData(number);
  }, [fetchData, number]);

  if (!isReady) return <PageLoader />;
  if (!expenseReport) return <ErrorPage.NotFound publicSite={false} />;

  const hasLockedItem = _.filter(expenseReport?.expenseItems, (x) => x.lockStatusId != 'unlocked').length > 0;
  const allowDelete = expenseReport.id && !hasLockedItem;

  const unsubmittedCount = _.filter(
    expenseReport.expenseItems,
    (item) => item.statusId === 'not_submitted' || item.statusId === 'rejected',
  ).length;

  return (
    <>
      <Page scrollable>
        <Header expenseReport={expenseReport} member={member} />
        {expenseReport?.expenseItems?.length > 0 && (
          <Page.ListView>
            <ListView style={{ paddingBottom: '6.75rem' }}>
              <ListView.Status>
                {!!false && <Spinner />}
                <ListView.Total value={expenseReport?.expenseItems?.length} label="Expense Items" />
              </ListView.Status>

              <ListView.Header>
                <ListView.Column sticky width="3.5rem" align="center"></ListView.Column>
                <ListView.Column width="7.5rem">Date</ListView.Column>
                <ListView.Column minWidth="16rem">Category/Vendor</ListView.Column>
                <ListView.Column minWidth="16rem">Project/Client</ListView.Column>
                <ListView.Column width="12.5rem" align="right">
                  Amount
                </ListView.Column>
                <ListView.Column width="3rem"></ListView.Column>
              </ListView.Header>
              <ListView.Body fade={false}>
                {_.map(expenseReport?.expenseItems, (item) => {
                  return <ExpenseItemRow key={item.id} item={item} onSaved={handleSaved} />;
                })}
                {!expenseReport?.isExternallyManaged && (
                  <EditLinkContainer>
                    <EditLink to={`${url}/new-item`}>
                      <Icon icon="plus" />
                    </EditLink>
                  </EditLinkContainer>
                )}
              </ListView.Body>
            </ListView>
          </Page.ListView>
        )}
        {expenseReport?.expenseItems?.length < 1 && (
          <NoResults>
            <NoResultsMessage>This expense report is currently empty</NoResultsMessage>

            <SplitButton>
              <SaveButton onClick={() => history.push(`${url}/new-item/`)}>New Expense Item</SaveButton>
              <SplitButton.Menu>
                <>
                  <SplitButtonItem onClick={() => setDialog('import')}>Import Expenses</SplitButtonItem>
                </>
              </SplitButton.Menu>
            </SplitButton>
          </NoResults>
        )}
        {
          {
            import: <ImportFileModal expenseReport={expenseReport} onClose={handleCloseDialog} onSaved={handleSaved} />,
          }[dialog?.type ?? dialog]
        }

        {!expenseReport?.isExternallyManaged && (
          <ActionsBanner style={{ justifyContent: 'space-between' }}>
            <ActionsContainer>
              {allowDelete && (
                <TooltipButton component={DeleteButton} onClick={() => setConfirmDelete(true)}>
                  Delete
                </TooltipButton>
              )}
            </ActionsContainer>

            <ActionsContainer>
              <SplitButton>
                <SaveButton onClick={() => history.push(`${url}/new-item/`)}>New Expense Item</SaveButton>
                <SplitButton.Menu position="top">
                  <>
                    <SplitButtonItem onClick={() => setDialog('import')}>Import Expenses</SplitButtonItem>
                  </>
                </SplitButton.Menu>
              </SplitButton>

              {unsubmittedCount > 0 && (
                <Button onClick={handleSubmit}>
                  Submit for Approval <ButtonBadge>{unsubmittedCount}</ButtonBadge>
                </Button>
              )}
            </ActionsContainer>
          </ActionsBanner>
        )}
      </Page>

      <Switch>
        <Route path={[`${path}/edit`]}>
          <Drawer
            onSaved={handleSaved}
            onClose={(deleted = false) => {
              return deleted ? history.push('../../reports') : history.push(url);
            }}
          />
        </Route>
        <Route path={[`${path}/new-item`]}>
          <ExpenseItemDrawer
            impersonatedMember={member || workspace.member}
            expenseReport={expenseReport}
            onSaved={handleSaved}
            onClose={() => history.push(url)}
          />
        </Route>
        <Route path={[`${path}/item/:expenseItemId/edit`]}>
          <ExpenseItemDrawer
            impersonatedMember={member || workspace.member}
            expenseReport={expenseReport}
            onSaved={handleSaved}
            mode="edit"
            onClose={() => history.push(url)}
          />
        </Route>
        <Route path={[`${path}/item/:expenseItemId/view`]}>
          <ExpenseItemDrawer
            impersonatedMember={member || workspace.member}
            expenseReport={expenseReport}
            onSaved={handleSaved}
            mode="view"
            onClose={() => history.push(url)}
          />
        </Route>
      </Switch>
      {confirmDelete && (
        <DeleteExpenseReportConfirmation
          id={expenseReport.id}
          title={expenseReport.title}
          onClose={() => setConfirmDelete(false)}
          onDelete={() => onDelete()}
        />
      )}
    </>
  );
}

const initialState = { isReady: false, expenseReport: null };
const handlers = {
  ready: ({ expenseReport }) => ({ isReady: true, expenseReport }),
};

export default DetailPage;
export { DetailPage };
