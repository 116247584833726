import {
  ActionButton,
  BillableIcon,
  Button,
  ButtonBadge,
  ClientLink,
  Duration,
  Icon,
  InlineTooltip,
  ProjectLink,
  SplitButton,
  TaskLink,
  TextArea,
  Tooltip,
} from '~/components';
import { useApi, useSubscription, useTimeEntries, useToast, useMember, useWorkspace } from '~/contexts';
import { useDateTimeFormat, useDocumentTitle, useFeatures } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import { formatDuration, parseTime } from '~/utils';
import CalendarPage from './CalendarPage';
import { CalendarStats } from './stats';
import timeLockedReasons from './timeLockedReasons';
import ResubmitRejectedTimeButton from './timesheets/ResubmitRejectedTimeButton';
import SubmitTimesheetButton from './timesheets/SubmitTimesheetButton';
import { useTimesheets } from './timesheets/TimesheetContext';
import TimesheetSubmittedTooltip from './timesheets/TimesheetSubmittedTooltip';
import UnsubmitTimesheetButton from './timesheets/UnsubmitTimesheetButton';
import { AttachButton, useWeekFiles, WeekFilesDrawer, WeekFilesList } from './week-files';
import WeekTimeEntry from './WeekTimeEntry';
import WeekTimeEntryDeleteConfirmation from './WeekTimeEntryDeleteConfirmation';
import pluralize from 'pluralize';
import WeekApprovalPopover from './WeekApprovalPopover.jsx';

const NotesIndicator = styled(Icon)`
  position: absolute;
  z-index: 2;
  font-size: 0.75rem;
  color: ${colors.grey40};
  margin: 0.25rem;
`;

const AllocationIcon = styled(Icon)`
  color: ${colors.primary};
  background-color: ${colors.grey10};
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 999rem;
  z-index: 2;
  padding: 0.1rem;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    cursor: pointer;
  }
`;

const AllocationIconContainer = styled.div`
  position: absolute;
  color: ${colors.grey40};
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Week = styled.div`
  display: grid;
  grid-row-gap: 1px;
  grid-template-columns: 4.75fr repeat(7, 1fr) 2.5fr;
  margin-bottom: auto;
  background-color: ${colors.grey10};

  > * {
    background-color: ${colors.white};
  }
`;

const Header = styled.div`
  position: sticky;
  top: ${({ hasMessage }) => (hasMessage ? '6.25rem' : '3.75rem')};
  z-index: 4;
  margin-top: 1.25rem;
  padding-top: 0.75rem;
  background-color: ${colors.white};
  border-bottom: ${({ hasEntries }) => (hasEntries ? `1px solid ${colors.grey10}` : 'none')};
`;

const WeekDayButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3125rem;
  padding: 0.3125rem 0;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;

  ${({ isHoliday }) =>
    isHoliday &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        ${colors.primary10},
        ${colors.primary10} 5px,
        ${colors.primary5} 5px,
        ${colors.primary5} 10px
      );
    `}
`;

const WeekBoxLabel = styled.span`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const WeekBoxValue = styled.span`
  margin: 0.1875rem 0;
  color: ${colors.black};
  font-weight: ${weights.normal};
  font-size: 0.75rem;
  min-height: 1.05rem;

  transition: opacity 150ms;
  opacity: ${({ transparent }) => (transparent ? 0 : 1)};

  ${({ isTimesheetSubmitted }) =>
    isTimesheetSubmitted &&
    css`
      color: ${colors.grey25};
    `}
`;

const RejectedEntriesBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.danger};
  border-radius: 50%;
`;

const HeaderValue = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: ${colors.grey40};
  font-size: 0.625rem;
  font-weight: ${weights.medium};
  letter-spacing: 0.0625rem;
  text-align: center;
`;

const HeaderDay = styled.div`
  padding: 0 0.25rem;

  a ${WeekDayButton}, a ${HeaderValue} {
    color: ${({ isToday }) => (isToday ? colors.primary : colors.grey40)};
  }

  a:hover ${WeekDayButton} {
    background-color: ${colors.grey10};

    ${({ isHoliday }) =>
      isHoliday &&
      css`
        background-image: repeating-linear-gradient(
          -45deg,
          ${colors.primary25},
          ${colors.primary25} 5px,
          ${colors.primary5} 5px,
          ${colors.primary5} 10px
        );
      `}
  }
`;

const HeaderTotal = styled.div`
  padding-left: 1.25rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5rem;
  color: ${colors.grey55};
  overflow: hidden;
`;

const DescriptionTask = styled(TaskLink)`
  font-weight: ${weights.normal};
`;

const DescriptionTitle = styled.p`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  strong {
    color: ${colors.black};
  }
`;

const DescriptionBody = styled.p`
  padding-top: 0.3125rem;
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
`;

const DayEntryWrapper = styled.div`
  position: relative;
`;

const DayTimeEntryDetails = styled.div`
  font-size: 0.75rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const ExtraMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
  border-top: 1px solid ${colors.grey10};
`;

const ExtraMessageSymbol = styled.div`
  color: ${colors.grey55};
  font-size: 1rem;
`;

const ExtraMessageText = styled.div`
  padding-left: 0.5rem;
  color: ${colors.grey55};
  font-style: italic;
`;

const NotesMissingMessage = styled.div`
  color: ${colors.grey55};
  font-style: italic;
`;

const TimeEntryDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid ${colors.grey10};
  padding: 0.75rem 0;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

const TimeEntryNote = styled.div`
  flex: 1;
  padding: 0 0.25rem 0 0.75rem;
  white-space: pre-wrap;
`;

const TimeEntryBillable = styled.div``;

const TimeEntryHours = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.25rem;
  height: 1.875rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-weight: ${weights.medium};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey10,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};
  border-radius: 999rem;
  font-size: 0.875rem;
`;

const AllocatedDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 1px solid ${colors.grey10};
  padding: 0.75rem 0;
  align-items: center;
`;

const Timer = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey5};
  z-index: 1;
  cursor: not-allowed;
`;

const DayEntryLoader = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey40};
  background-color: ${colors.white};
  pointer-events: none;
  z-index: 1;
`;

const DayEntryMultiple = styled.div`
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  border: solid 1px ${colors.grey25};
  border-radius: 0.3125rem;
  background-color: ${colors.grey5};
`;

const DayEntry = styled.input`
  border: 1px solid blue;
  && {
    border: 1px solid green;
    position: relative;
    height: 2.5rem;
    padding: 0;
    color: ${({ error }) => (error ? colors.danger : colors.black)};
    text-align: center;
    border-color: ${({ error }) => (error ? colors.danger : colors.grey25)};
    z-index: 1;

    &:focus {
      border-width: 2px;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${colors.grey5};
    }

    &::placeholder {
      color: ${colors.grey20};
    }
  }
`;

const DayTotal = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  font-weight: ${weights.bold};
  color: ${colors.grey40};

  button {
    position: absolute;
    padding-right: 1rem;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: ${colors.danger};

    &:hover {
      color: ${colors.danger50};
    }

    &:disabled,
    &:disabled:hover {
      color: ${colors.grey25};
    }
  }
`;

const FooterDayTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  font-size: 1rem;
  color: ${colors.grey40};
  font-weight: ${weights.bold};
`;

const FooterTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  padding-bottom: 0;
  color: ${colors.primary};
  font-size: 1rem;
  font-weight: ${weights.black};
`;

const AddTimeEntryButton = styled(Button)`
  width: 2.5rem;
  margin-top: 1.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }
`;

const NoResults = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
`;

const NoResultsMessage = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const NoResultsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0.5rem 0;
  }
`;

const TimeEntryLocked = styled.div`
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
  font-size: 0.5rem;
  z-index: 1;
  pointer-events: none;
`;

const Arrow = styled.div`
  position: absolute;
  right: -0.25rem;
  top: 7.25rem;

  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.white};
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const NotesForm = styled.div`
  position: absolute;
  right: calc(100% + 0.5rem);
  bottom: -6.25rem;
  width: 25rem;
  height: 15rem;
  padding: 0.75rem;
  background: ${colors.white};
  z-index: 81;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  border-radius: 5px;

  > div {
    display: flex;

    textarea {
      resize: none;
    }
  }
`;

const NotesTextArea = styled(TextArea)`
  height: 13.5rem;
  font-size: 0.85rem;
`;

function DayEntryInput({ date, member, group, entries, allocatedMinutes, onChange, onDelete, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [value, setValue] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [focused, setFocused] = useState(false);
  const [notes, setNotes] = useState(() => entries[0]?.notes || '');
  const { member: currentMember } = useMember();
  let transientValue = value;

  const timerEntry = useMemo(() => {
    return _.find(entries, (entry) => !!entry.timerStartedAt);
  }, [entries]);

  const minutes = _.sumBy(entries, 'minutes');
  const minutesDisplay = minutes
    ? formatDuration(moment.duration(minutes, 'minutes'), {
        useDecimal: currentMember?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry,
        locale: currentMember?.locale,
      })
    : '';

  const allocatedMinutesDisplay =
    allocatedMinutes > 0
      ? formatDuration(moment.duration(allocatedMinutes, 'minutes'), {
          useDecimal: currentMember?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry,
          locale: currentMember?.locale,
        })
      : '';

  const handleFocus = () => {
    if (focused) return;

    setError();
    setFocused(true);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
        setValue(undefined);
        transientValue = undefined;
        event.target.blur();
        break;
      case 'Enter':
        event.target.blur();
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = async (event) => {
    // Exit if the user is changing between the time input and the notes text area.
    // "currentTarget" is the parent element, "relatedTarget" is the clicked element.
    if (event.currentTarget.contains(event.relatedTarget)) return;

    setFocused(false);

    const minutes = parseTime(transientValue !== undefined ? transientValue : minutesDisplay);
    if (isNaN(minutes) || minutes < 0 || minutes > 1440) {
      setError('Invalid time amount.');
      return;
    }

    if (minutes !== parseTime(minutesDisplay) || (entries.length === 1 && (entries[0].notes || '') !== notes)) {
      setIsLoading(true);

      const typeId = group.typeId;
      const timeOffTypeId = group.timeOffType?.id;
      const projectId = group.project?.id;
      const projectRoleId = group.role?.id;
      const projectTaskId = group.task?.id ?? null;
      const trimmedNotes = typeof notes === 'string' ? notes.trim() : '';

      const entryData = {
        date,
        typeId,
        timeOffTypeId,
        projectId,
        projectRoleId,
        projectTaskId,
        minutes,
        notes: trimmedNotes || null,
      };
      if (member) {
        entryData.memberId = member.id;
      }

      try {
        const { data: entry, status } = await api.www.workspaces(workspace.id).timeEntries().upsertDay(entryData);

        if (status === 204) {
          if (onDelete) {
            onDelete(date, group);
          }
        } else {
          if (onChange) {
            onChange(entry);
          }
        }
        setValue(undefined);
      } catch ({ message }) {
        setError(message || 'There was a problem saving this time entry.');
      }
      setIsLoading(false);
    } else {
      setValue(undefined);
    }
  };

  const timeOffAllowed = (member ?? workspace.member).timeOffAllowed;
  const manageTimeAndExpenses = (member ?? workspace.member).permissions.manageTimeAndExpenses;

  const timesheetContext = useTimesheets();

  const { disabled, tooltipMessage, lockedEntry } = useMemo(() => {
    const action = entries.length === 0 ? 'create' : 'update';

    const lockedEntry = entries.find((e) => e.isLocked);

    // The full row is disabled if the max age determined in the workspace is exceeded (unless the member is a workspace time admin)
    const dateOutOfBoundaries =
      !manageTimeAndExpenses &&
      ((workspace.lockTimeAndExpenses &&
        moment.duration(moment().diff(moment(date), 'days'), 'days').asDays() >
          workspace.lockTimeAndExpensesAfterDays) ||
        (workspace.lockTimeAndExpensesAfterWeekEnds &&
          moment.duration(moment().diff(moment(date).endOf('isoWeek'), 'days'), 'days').asDays() >=
            workspace.lockTimeAndExpensesAfterWeekEndsDays) ||
        (workspace.lockTimeAndExpensesAfterMonthEnds &&
          !moment(date)
            .startOf('month')
            .isAfter(
              moment()
                .subtract(moment().date() > workspace.lockTimeAndExpensesAfterMonthEndsDays ? 1 : 2, 'months')
                .startOf('month'),
            )));

    // New time entries can't be created if the member is not active on the project
    const inactiveProjectMember =
      group.typeId === 'project_time' && action === 'create' && !group.projectMember?.isActive;

    // New time entries can't be created if the role is not active on the project
    const inactiveProjectRole =
      group.typeId === 'project_time' && action === 'create' && group.project?.useRoles && !group.role?.isActive;

    // The full row is disabled for archived projects (unless the member is a workspace time admin)
    const projectArchived =
      group.typeId === 'project_time' &&
      group.project?.recordStatusId === 'archived' &&
      (!manageTimeAndExpenses || action === 'create');

    const projectLocked =
      group.typeId === 'project_time' &&
      group.project?.lockTimeAndExpenses &&
      (!manageTimeAndExpenses || action === 'create');

    const taskArchived =
      group.typeId === 'project_time' &&
      group.task?.recordStatusId === 'archived' &&
      (!manageTimeAndExpenses || action === 'create');

    const taskLocked =
      group.typeId === 'project_time' && group.task?.lockTime && (!manageTimeAndExpenses || action === 'create');

    // The full row is disabled if the project uses roles but the row doesn't have a role set
    const missingRole = group.typeId === 'project_time' && group.project?.useRoles && !group.role;

    const missingTask = group.typeId === 'project_time' && group.project?.requireTimeEntryTask && !group.task;

    // The row is disabled if the project requires being assigned to the role but the member is not assigned to it
    const notAssignedToRole =
      group.typeId === 'project_time' &&
      action === 'create' &&
      group.project?.useRoles &&
      group.project?.trackTimeToAssignedRoles &&
      !group.isAssignedToRole;

    const taskNotAssigned = group.typeId === 'project_time' && group.task?.forAssignedOnly && !group.isTaskAssigned;

    const timeOffNotAllowed = group.typeId === 'time_off' && !timeOffAllowed;

    const isTimesheetSubmitted =
      timesheetContext.isTimesheetSubmitted({ start: date, end: date }) &&
      (!manageTimeAndExpenses || action === 'create');

    const outsideProjectDates =
      group.typeId === 'project_time' &&
      action === 'create' &&
      workspace.timeAndExpensesWithinProjectDates &&
      ((group.project?.start && !moment(date).isSameOrAfter(group.project?.start, 'day')) ||
        (group.project?.end && !moment(date).isSameOrBefore(group.project?.end, 'day')));

    const disabled =
      isLoading ||
      !!timerEntry?.timerStartedAt ||
      projectArchived ||
      projectLocked ||
      taskLocked ||
      entries.length > 1 ||
      !!lockedEntry ||
      dateOutOfBoundaries ||
      inactiveProjectMember ||
      inactiveProjectRole ||
      missingRole ||
      notAssignedToRole ||
      missingTask ||
      taskNotAssigned ||
      timeOffNotAllowed ||
      (isTimesheetSubmitted && !['pending_approval', 'rejected'].includes(entries[0]?.statusId)) ||
      outsideProjectDates ||
      taskArchived;

    let tooltipMessage = null;
    if (error) {
      tooltipMessage = error;
    } else if (isTimesheetSubmitted) {
      tooltipMessage = 'Timesheet has been submitted.';
    } else if (timerEntry?.timerStartedAt) {
      tooltipMessage = 'Timer is currently running, pause to edit.';
    } else if (entries.length > 1) {
      tooltipMessage = 'Cells with multiple entries must be edited on the Day or List view.';
    } else if (lockedEntry) {
      tooltipMessage = timeLockedReasons[lockedEntry.lockStatusId];
    } else if (projectArchived) {
      tooltipMessage = 'This project is archived.';
    } else if (projectLocked) {
      tooltipMessage = 'This project does not allow adding new time entries.';
    } else if (taskLocked) {
      tooltipMessage = 'This task does not allow adding new time entries.';
    } else if (dateOutOfBoundaries) {
      tooltipMessage = `You cannot create a time entry with a date that is this far in the past.`;
    } else if (inactiveProjectMember) {
      tooltipMessage = `You must be an active project member to create a time entry.`;
    } else if (inactiveProjectRole) {
      tooltipMessage = `This role is inactive.`;
    } else if (missingRole) {
      tooltipMessage = `You must assign a role to the time entries in this row.`;
    } else if (notAssignedToRole) {
      tooltipMessage = `Members of this project can only track time to assigned roles.`;
    } else if (missingTask) {
      tooltipMessage = `All time entries in this project must be associated with a task.`;
    } else if (taskNotAssigned) {
      tooltipMessage =
        'In order to track time to this task, you or the selected project role must be assigned to the task.';
    } else if (timeOffNotAllowed) {
      tooltipMessage = 'You are not allowed to track time off.';
    } else if (outsideProjectDates) {
      tooltipMessage = `This day is outside of the project's start and end dates.`;
    } else if (taskArchived) {
      tooltipMessage = 'This task is archived.';
    }

    return { disabled, tooltipMessage, lockedEntry };
  }, [
    error,
    group,
    entries,
    date,
    isLoading,
    timerEntry,
    workspace.lockTimeAndExpenses,
    workspace.lockTimeAndExpensesAfterDays,
    workspace.lockTimeAndExpensesAfterWeekEnds,
    workspace.lockTimeAndExpensesAfterWeekEndsDays,
    workspace.lockTimeAndExpensesAfterMonthEnds,
    workspace.lockTimeAndExpensesAfterMonthEndsDays,
    workspace.timeAndExpensesWithinProjectDates,
    timeOffAllowed,
    timesheetContext,
    manageTimeAndExpenses,
  ]);

  const hasNotes = entries?.filter((e) => !!e.notes).length > 0 || notes;

  const requireNotes = group.typeId === 'project_time' && (group.project?.requireNotes || group.task?.requireNotes);

  const dayEntryRef = useRef();

  const handleAddAllocation = () => {
    setValue(allocatedMinutesDisplay);
    dayEntryRef.current.focus();
  };

  const showAddAllocations =
    features.allocations &&
    workspace.showAllocationsOnTimeCalendar &&
    !disabled &&
    !minutesDisplay &&
    allocatedMinutes > 0 &&
    !focused &&
    !error;

  return (
    <DayEntryWrapper onBlur={handleBlur}>
      {entries.length > 1 && <DayEntryMultiple />}
      {entries.length === 1 && entries[0]?.statusId === 'pending_approval' ? (
        <WeekApprovalPopover entry={focused ? null : entries[0]} placement="bottom">
          <>
            {hasNotes && <NotesIndicator type="far" icon="comment-lines"></NotesIndicator>}

            {showAddAllocations && (
              <AllocationIconContainer>
                <AllocationIcon icon="plus" onClick={handleAddAllocation} />
              </AllocationIconContainer>
            )}

            <DayEntry
              {...props}
              type="text"
              maxLength={5}
              value={value !== undefined ? value : minutesDisplay}
              disabled={disabled}
              error={error}
              ref={dayEntryRef}
              placeholder={allocatedMinutesDisplay}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              data-testid={`day-entry-${moment(date).format('ddd')}`}
            />

            {timerEntry?.timerStartedAt && (
              <Timer>
                <Duration minutes={minutes} timerStartedAt={timerEntry?.timerStartedAt} />
              </Timer>
            )}
          </>
        </WeekApprovalPopover>
      ) : (
        <Tooltip
          message={focused ? null : getToolTipMessage(entries, tooltipMessage, allocatedMinutes)}
          maxWidth="27rem"
          placement="bottom">
          <>
            {hasNotes && <NotesIndicator type="far" icon="comment-lines"></NotesIndicator>}

            {showAddAllocations && (
              <AllocationIconContainer>
                <AllocationIcon icon="plus" onClick={handleAddAllocation} />
              </AllocationIconContainer>
            )}

            <DayEntry
              {...props}
              type="text"
              maxLength={5}
              value={value !== undefined ? value : minutesDisplay}
              disabled={disabled}
              error={error}
              ref={dayEntryRef}
              placeholder={allocatedMinutesDisplay}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              data-testid={`day-entry-${moment(date).format('ddd')}`}
            />

            {timerEntry?.timerStartedAt && (
              <Timer>
                <Duration minutes={minutes} timerStartedAt={timerEntry?.timerStartedAt} />
              </Timer>
            )}
          </>
        </Tooltip>
      )}

      {!disabled && focused && (
        <NotesForm>
          <Arrow />
          <NotesTextArea
            placeholder={requireNotes ? 'Notes (required)' : 'Notes'}
            maxLength={5000}
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
          />
        </NotesForm>
      )}

      {isLoading && (
        <DayEntryLoader>
          <Icon icon="spinner" spin />
        </DayEntryLoader>
      )}
      {lockedEntry && (
        <TimeEntryLocked>
          <Icon icon="lock" color={colors.grey40} />
        </TimeEntryLocked>
      )}
    </DayEntryWrapper>
  );
}

function AllocatedTooltipMessage({ allocatedMinutes, type }) {
  if (!allocatedMinutes > 0) return null;

  const allocatedHours = formatDuration(moment.duration(allocatedMinutes, 'minutes'), {
    useDecimal: true,
  });
  const formattedHours = `${allocatedHours} ${pluralize('hour', allocatedHours)}`;

  const messages = {
    day: `You have ${formattedHours} allocated for this day.`,
    week: `You have ${formattedHours} allocated for this week.`,
    dayTotal: `You have ${formattedHours} allocated in total for this day.`,
  };

  return <>{messages[type] || ''}</>;
}

function getToolTipMessage(entries, message, allocatedMinutes) {
  const result =
    entries?.length === 0 ? (
      (message || allocatedMinutes > 0) && (
        <div>
          {allocatedMinutes > 0 && (
            <AllocatedDetails style={{ borderTop: 'none' }}>
              <AllocatedTooltipMessage allocatedMinutes={allocatedMinutes} type="day" />
            </AllocatedDetails>
          )}
          {message && (
            <ExtraMessage style={{ borderTop: allocatedMinutes > 0 ? undefined : 'none' }}>
              <div>{message}</div>
            </ExtraMessage>
          )}
        </div>
      )
    ) : (
      <>
        <DayTimeEntryDetails>
          {entries?.map((entry) => (
            <TimeEntryDetails className="timeEntryDetails" key={entry.id}>
              <TimeEntryBillable>
                <BillableIcon value={entry.isActuallyBillable}></BillableIcon>
              </TimeEntryBillable>
              <TimeEntryHours status={entry.status?.id}>
                <Duration
                  minutes={entry.minutes}
                  timerStartedAt={entry.timerStartedAt}
                  showSeconds={!!entry.timerStartedAt}
                  trim={!entry.timerStartedAt}
                />
              </TimeEntryHours>
              <TimeEntryNote>
                {entry.notes ? `"${entry.notes}"` : <NotesMissingMessage>No notes provided</NotesMissingMessage>}
              </TimeEntryNote>
            </TimeEntryDetails>
          ))}
        </DayTimeEntryDetails>

        {allocatedMinutes > 0 && (
          <AllocatedDetails>
            <AllocatedTooltipMessage allocatedMinutes={allocatedMinutes} type="day" />
          </AllocatedDetails>
        )}

        {message && (
          <ExtraMessage>
            <ExtraMessageSymbol>
              <Icon icon="info-circle" color={colors.grey25}></Icon>
            </ExtraMessageSymbol>
            <ExtraMessageText>{message}</ExtraMessageText>
          </ExtraMessage>
        )}
      </>
    );

  return result ?? null;
}

function undefinedToNull(value) {
  return _.isUndefined(value) ? null : value;
}

function groupValueCustomizer(valueA, valueB) {
  return undefinedToNull(valueA) === undefinedToNull(valueB);
}

function groupCompare(groupA, groupB) {
  return (
    _.isEqualWith(groupA.typeId, groupB.typeId, groupValueCustomizer) &&
    _.isEqualWith(groupA.timeOffType?.id, groupB.timeOffType?.id, groupValueCustomizer) &&
    _.isEqualWith(groupA.project?.id, groupB.project?.id, groupValueCustomizer) &&
    _.isEqualWith(groupA.role?.id, groupB.role?.id, groupValueCustomizer) &&
    _.isEqualWith(groupA.task?.id, groupB.task?.id, groupValueCustomizer)
  );
}

const TimesheetStatusBarGutter = styled.div`
  margin-bottom: -1px;
`;

const TimesheetStatusBar = styled.div`
  position: relative;
  grid-column: ${({ $start, length }) => `${$start} / span ${length}`};
  background: ${colors.white};
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-bottom: -1px;

  > div {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
    text-align: center;
    border-radius: 0.3125rem;
    background-color: ${({ submitted }) => (submitted ? colors.grey40 : colors.grey5)};
    color: ${({ submitted }) => (submitted ? colors.white : colors.black)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function TimesheetStatus({ week }) {
  const timesheetContext = useTimesheets();

  const periods = useMemo(() => {
    const days = week.map((day) => ({
      date: day,
      timesheet: timesheetContext.timesheets?.find((ts) => moment(day).isBetween(ts.start, ts.end, 'day', '[]')),
    }));

    const periods = _(days)
      .groupBy((day) => day.timesheet?.id)
      .map((group) => {
        const day = group[0];
        const date = day.date;
        const length = group.length;
        const submitted = !!day.timesheet;
        const submittedOn = day.timesheet?.submittedOn;

        return { date, length, submitted, submittedOn };
      })
      .value();

    return periods;
  }, [timesheetContext, week]);

  const dateTimeFormat = useDateTimeFormat();

  return (
    <>
      <TimesheetStatusBarGutter />

      {timesheetContext.isReady &&
        periods.map((period, index) => {
          const start = (periods[index - 1]?.length ?? 0) + 2;

          return (
            <TimesheetStatusBar
              key={period.date}
              submitted={period.submitted}
              $start={start}
              length={period.length}
              data-testid="timesheet_status_bar">
              <div>
                {period.submitted ? 'Submitted' : 'Not Submitted'}
                {period.submitted && (
                  <InlineTooltip
                    placement="top"
                    message={`Submitted on ${dateTimeFormat.format(period.submittedOn)}.`}
                  />
                )}
              </div>
            </TimesheetStatusBar>
          );
        })}

      <TimesheetStatusBarGutter />
    </>
  );
}

function WeekCalendar({ view, date, setDate, member, memberTargets, setMember, getHolidays }) {
  useDocumentTitle('Week Calendar');

  const api = useApi();
  const toast = useToast();
  const weekFiles = useWeekFiles();
  const { workspace, hasMessage } = useWorkspace();
  const { entries, updateEntry, updateEntries, removeEntries } = useTimeEntries();
  const features = useFeatures();
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [copyGroups, setCopyGroups] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [timeEntryVisible, setTimeEntryVisible] = useState(false);
  const [submittingEntries, setSubmittingEntries] = useState(false);
  const [showWeekFiles, setShowWeekFiles] = useState(false);
  const [allocations, setAllocations] = useState([]);
  const { notify } = useSubscription();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setGroups([]);
    setAllocations([]);

    const query = { date };
    if (member) {
      query.memberId = member.id;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getPreviousWeekGroups(query);

      setCopyGroups(data);
    } catch {
      setCopyGroups([]);
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getWeek(query);

      let allocations = [];
      if (features.allocations && workspace.showAllocationsOnTimeCalendar) {
        const { data } = await api.www.workspaces(workspace.id).timeEntries().getWeekAllocations(query);
        allocations = data;
      }

      updateEntries(data);
      setAllocations(allocations);
    } finally {
      setIsReady(true);
      setIsLoading(false);
    }
  }, [api, workspace, member, date, updateEntries, features.allocations]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memberId = useMemo(() => (member ? member.id : workspace.member.id), [member, workspace]);

  const holidays = useMemo(() => {
    const start = moment(date).startOf('isoWeek');
    const end = start.clone().add(6, 'days');
    return getHolidays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
  }, [getHolidays, date]);

  const week = useMemo(() => {
    const day = moment(date).startOf('isoWeek').subtract(1, 'days');
    return _.times(7, () => day.add(1, 'days').format('YYYY-MM-DD'));
  }, [date]);

  const viewEntries = useMemo(
    () => _.filter(entries, (entry) => entry.memberId === memberId && moment(entry.date).isSame(date, 'isoWeek')),
    [date, memberId, entries],
  );

  const timerEntry = useMemo(() => {
    return _.find(viewEntries, (entry) => !!entry.timerStartedAt);
  }, [viewEntries]);

  const unsubmittedEntryCount = useMemo(
    () =>
      _.filter(
        viewEntries,
        (entry) => !entry.timerStartedAt && (entry.status?.id === 'not_submitted' || entry.status?.id === 'rejected'),
      ).length,
    [viewEntries],
  );

  const groupedEntries = useMemo(() => {
    if (features.allocations && workspace.showAllocationsOnTimeCalendar && isLoading) return [];

    const entryGroups = _.reduce(
      [
        ...viewEntries.map((entry) => ({ ...entry, entity: 'time_entry' })),
        ...allocations.map((allocation) => ({ ...allocation, entity: 'allocation' })),
      ],
      (acc, entry) => {
        let group = _.find(acc, groupCompare.bind(this, entry));
        if (!group) {
          const { typeId, timeOffType, project, role, task, projectMember, isAssignedToRole, isTaskAssigned } = entry;

          group = {
            typeId,
            timeOffType,
            project,
            role,
            task,
            minutes: 0,
            allocatedMinutes: features.allocations && workspace.showAllocationsOnTimeCalendar ? 0 : null,
            timerStartedAt: null,
            entries: [],
            projectMember,
            isAssignedToRole,
            isTaskAssigned,
          };
          acc.push(group);
        }

        if (entry.minutes) {
          group.minutes += entry.minutes;
        }
        if (entry.allocatedMinutes) {
          group.allocatedMinutes += entry.allocatedMinutes;
        }
        if (entry.timerStartedAt) {
          group.timerStartedAt = entry.timerStartedAt;
        }
        group.entries.push(entry);

        return acc;
      },
      [],
    );
    _.each(groups, (group) => {
      const entryGroup = _.find(entryGroups, groupCompare.bind(this, group));
      if (!entryGroup) {
        entryGroups.push(group);
      }
    });
    return _.sortBy(entryGroups, ['project.client.name', 'project.name', 'role.name', 'task.name', 'timeOffType.name']);
  }, [viewEntries, groups, allocations, isLoading, features.allocations, workspace.showAllocationsOnTimeCalendar]);

  const hasEntries = useMemo(() => groupedEntries.length > 0, [groupedEntries]);

  const availableCopyRows = useMemo(() => {
    return _.filter(copyGroups, (copyGroup) => {
      // Return true if the `copyGroup` doesn't exist in the current `groupedEntries`
      return !_.find(groupedEntries, groupCompare.bind(this, copyGroup));
    });
  }, [groupedEntries, copyGroups]);

  const copyEntries = useMemo(() => {
    return !groupedEntries?.some((group) => group.entries?.some((entry) => entry.entity === 'time_entry'));
  }, [groupedEntries]);

  const handleSubmitEntries = async () => {
    setSubmittingEntries(true);

    await api.www.workspaces(workspace.id).timeEntries().submitWeek({ date, memberId: member?.id });

    await fetchData();

    setSubmittingEntries(false);

    notify(useSubscription.keys.refresh_time_approval_count);

    toast.success('Time entries have been submitted for approval.');
  };

  const handleAddRow = (data) => {
    const group = _.assign({ typeId: null, timeOffType: null, project: null, role: null, task: null }, data);
    if (group.typeId === 'project_time') {
      // If the member was able to select the project, it means the team assignment is active
      group.projectMember = { isActive: true };

      // If the member was able to select the role, it means that the member is assigned to the role
      if (group.project?.trackTimeToAssignedRoles) group.isAssignedToRole = true;

      // If the member was able to select the task, it means that the task is assigned to the member or role
      if (group.task) group.isTaskAssigned = true;
    }
    const matchedGroups = _.filter(groupedEntries, groupCompare.bind(this, group));
    if (matchedGroups.length) {
      toast.warning('The selected combination has already been added.');
    } else {
      setGroups((groups) => [...groups, group]);
    }
    setTimeEntryVisible(false);
  };

  const handleChange = (entry) => {
    updateEntry(entry);
  };

  const handleDelete = (date, group) => {
    const entriesToRemove = _(entries)
      .filter((entry) => moment(entry.date).isSame(date, 'day'))
      .filter(groupCompare.bind(this, group))
      .value();

    if (entriesToRemove.length) {
      removeEntries(entriesToRemove);
      setGroups((groups) => [
        ...groups,
        _.pick(group, ['typeId', 'timeOffType', 'project', 'role', 'task', 'projectMember']),
      ]);
    }
  };

  const handleConfirmDeleteGroup = (group) => {
    removeEntries(_.filter(viewEntries, groupCompare.bind(this, group)));

    setGroups((groups) => {
      const newGroups = _.reject(groups, groupCompare.bind(this, group));
      return _.isEqual(newGroups, groups) ? groups : newGroups;
    });

    setToDelete(null);
  };

  const handleDeleteGroup = (group) => {
    const totalMinutes = _.sumBy(group.entries, 'minutes') || 0;
    if (totalMinutes > 0) {
      setToDelete(group);
    } else {
      handleConfirmDeleteGroup(group);
    }
  };

  const handleCopyRows = async () => {
    setGroups((groups) => [
      ...groups,
      ...availableCopyRows.map((group) => _.assign({}, group, { minutes: 0, timerStartedAt: null, entries: [] })),
    ]);
  };

  const handleCopyTimeEntries = async () => {
    setIsLoading(true);
    setGroups([]);

    const query = { date };
    if (member) {
      query.memberId = member.id;
    }

    // Copy Previous Time Entries
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().copyPreviousTimeEntries(query);
      if (data.partialSuccess) toast.warning('One or more time entries could not be copied.');
    } catch {
      toast.error("An error has occurred copying last week's time entries.");
    }

    // Update view
    fetchData();
  };

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () =>
      timesheetContext.isTimesheetSubmitted({
        start: moment(date).startOf('isoWeek'),
        end: moment(date).endOf('isoWeek'),
      }),
    [timesheetContext, date],
  );

  const pageActions = isReady && hasEntries && (
    <>
      {weekFiles.isEnabled && (
        <AttachButton
          isOutline
          tooltip={weekFiles.files.length > 0 ? 'Manage Attachments' : 'Attach Files'}
          onClick={() => setShowWeekFiles(true)}
        />
      )}

      <Button
        isOutline
        disabled={isTimesheetSubmitted}
        style={{ position: 'relative' }}
        onClick={() => setTimeEntryVisible(true)}>
        Add Time Row
        {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
      </Button>

      {workspace.enableCopyLastWeekTimeEntries && copyEntries && (
        <SplitButton>
          <ActionButton
            disabled={isTimesheetSubmitted}
            style={{ position: 'relative' }}
            onClick={handleCopyRows}
            data-testid="copy_rows_split">
            Copy Last Week's Rows
            {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
          </ActionButton>

          <SplitButton.Menu position="top">
            {({ setIsOpen }) => (
              <>
                <SplitButton.Item
                  disabled={isTimesheetSubmitted}
                  onClick={() => setIsOpen(false) || (() => handleCopyTimeEntries())()}>
                  Copy Last Week's Time Entries
                  {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                </SplitButton.Item>
              </>
            )}
          </SplitButton.Menu>
        </SplitButton>
      )}

      {availableCopyRows.length > 0 && !copyEntries && (
        <Button
          isOutline
          disabled={isTimesheetSubmitted}
          style={{ position: 'relative' }}
          onClick={handleCopyRows}
          data-testid="copy_rows">
          Copy Last Week's Rows
          {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
        </Button>
      )}

      {timesheetContext.useTimesheets ? (
        <>
          <ResubmitRejectedTimeButton date={date} period="week" member={member} onSubmit={fetchData} />
          <SubmitTimesheetButton date={date} member={member} onSubmit={fetchData} />
          <UnsubmitTimesheetButton date={date} member={member} onUnsubmitTimesheet={fetchData} />
        </>
      ) : (
        <>
          {unsubmittedEntryCount > 0 && (
            <Button isLoading={submittingEntries} onClick={handleSubmitEntries}>
              Submit for Approval <ButtonBadge visible={!submittingEntries}>{unsubmittedEntryCount}</ButtonBadge>
            </Button>
          )}
        </>
      )}
    </>
  );

  return (
    <CalendarPage actions={pageActions} view={view} date={date} setDate={setDate} member={member} setMember={setMember}>
      {isReady ? (
        <>
          <Header hasEntries={hasEntries} hasMessage={hasMessage}>
            <Week>
              {timesheetContext.useTimesheets && <TimesheetStatus week={week} />}

              <div></div>
              {week.map((day) => {
                const dayDate = moment(day);
                const dayHolidays = _.filter(holidays, (holiday) => dayDate.isSame(holiday.date, 'day'));
                const isHoliday = dayHolidays.length > 0;
                const holidayMessage = _(dayHolidays)
                  .sortBy(['name'])
                  .map((holiday) => <div key={holiday.id}>{holiday.name}</div>)
                  .value();

                const dayEntries = _(viewEntries).filter({ date: day });
                const minutes = dayEntries.sumBy('minutes');
                const showTotal = !isLoading || hasEntries;

                const isTimesheetSubmitted = timesheetContext.isTimesheetSubmitted({ start: day, end: day });

                const hasRejectedEntries = dayEntries.some((e) => e.statusId === 'rejected');

                return (
                  <HeaderDay key={day} isToday={moment().isSame(day, 'day')} isHoliday={isHoliday}>
                    <Link to={`/app/${workspace.key}/time/day?date=${day}`} onClick={() => setDate(day)}>
                      <Tooltip
                        message={
                          isHoliday
                            ? holidayMessage
                            : isTimesheetSubmitted
                              ? 'Timesheet has been submitted.'
                              : undefined
                        }>
                        <WeekDayButton isHoliday={isHoliday}>
                          <WeekBoxLabel>{moment(day).format('ddd')}</WeekBoxLabel>
                          <WeekBoxValue transparent={!showTotal} isTimesheetSubmitted={isTimesheetSubmitted}>
                            {showTotal && <Duration minutes={minutes} />}
                          </WeekBoxValue>

                          {hasRejectedEntries && <RejectedEntriesBadge />}
                        </WeekDayButton>
                        <HeaderValue>{moment(day).format('M/D')}</HeaderValue>
                      </Tooltip>
                    </Link>
                  </HeaderDay>
                );
              })}
              <HeaderTotal>
                {hasEntries && (
                  <WeekDayButton>
                    <WeekBoxLabel>Total Hours</WeekBoxLabel>
                    <WeekBoxValue>
                      <Duration
                        useDecimal
                        minutes={_.sumBy(viewEntries, 'minutes')}
                        timerStartedAt={timerEntry?.timerStartedAt}
                      />
                    </WeekBoxValue>
                  </WeekDayButton>
                )}
              </HeaderTotal>
            </Week>
          </Header>
          <Week>
            {hasEntries && (
              <>
                {groupedEntries.map((group) => {
                  const locked = group.entries?.some((e) => e.isLocked);
                  const hasGroupedAllocations = _.filter(group.entries, { entity: 'allocation' }).length > 0;
                  const hasGroupedEntries = _.filter(group.entries, { entity: 'time_entry' }).length > 0;
                  const disabled = !hasGroupedEntries && hasGroupedAllocations;

                  return (
                    <React.Fragment
                      key={`${group.typeId}_${group.timeOffType?.id}_${group.project?.id}_${group.role?.id}_${group.task?.id}`}>
                      {group.typeId === 'project_time' ? (
                        <Description>
                          {group.project && group.project.client && (
                            <DescriptionTitle>
                              <strong>
                                <ClientLink client={group.project.client} />
                              </strong>{' '}
                              / <ProjectLink popoverPlacement="top-start" project={group.project} />
                            </DescriptionTitle>
                          )}
                          <DescriptionBody>
                            {group.role?.name && <>{group.role.name}</>}
                            {group.task?.name && (
                              <DescriptionTask task={group.task} project={group.project}>
                                {' '}
                                {group.task.name}
                              </DescriptionTask>
                            )}
                          </DescriptionBody>
                        </Description>
                      ) : (
                        <Description>
                          <DescriptionTitle>
                            <strong>Time Off</strong>
                            {group.timeOffType && <> / {group.timeOffType.name}</>}
                          </DescriptionTitle>
                        </Description>
                      )}
                      {week.map((day) => {
                        const entries = _.filter(group.entries, (entry) => {
                          return entry.date === day && entry.entity === 'time_entry';
                        });
                        const allocations = _.filter(group.entries, (entry) => {
                          return entry.date === day && entry.entity === 'allocation';
                        });

                        return (
                          <Day key={day}>
                            <DayEntryInput
                              key={entries[0]?.id}
                              date={day}
                              member={member}
                              group={group}
                              entries={entries}
                              allocatedMinutes={allocations[0]?.allocatedMinutes}
                              onChange={handleChange}
                              onDelete={handleDelete}
                            />
                          </Day>
                        );
                      })}
                      <DayTotal>
                        <Tooltip
                          message={
                            group.allocatedMinutes > 0 ? (
                              <AllocatedDetails style={{ borderTop: 'none' }}>
                                <AllocatedTooltipMessage allocatedMinutes={group.allocatedMinutes} type="week" />
                              </AllocatedDetails>
                            ) : null
                          }>
                          <Duration useDecimal minutes={group.minutes} timerStartedAt={group.timerStartedAt} />
                        </Tooltip>
                        <Button
                          tabIndex={-1}
                          isAnchor
                          disabled={locked || disabled}
                          tooltip={locked ? 'One or more time entries in this group are locked.' : undefined}
                          onClick={handleDeleteGroup.bind(this, group)}>
                          <Icon icon="times-circle" />
                          {locked && <InlineTooltip message="One or more time entries in this group are locked." />}
                        </Button>
                      </DayTotal>
                    </React.Fragment>
                  );
                })}
                <div>
                  <AddTimeEntryButton
                    disabled={isTimesheetSubmitted}
                    style={{ position: 'relative' }}
                    onClick={() => setTimeEntryVisible(true)}>
                    <Icon icon="plus" />
                    {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                  </AddTimeEntryButton>
                </div>
                {week.map((day) => {
                  const minutes = _(viewEntries).filter({ date: day }).sumBy('minutes');
                  const allocatedMinutes = _(allocations).filter({ date: day }).sumBy('allocatedMinutes');

                  return (
                    <FooterDayTotal key={day}>
                      <Tooltip
                        message={
                          allocatedMinutes > 0 ? (
                            <AllocatedTooltipMessage allocatedMinutes={allocatedMinutes} type="dayTotal" />
                          ) : null
                        }>
                        {timerEntry && timerEntry.date === day ? (
                          <Duration minutes={minutes} timerStartedAt={timerEntry.timerStartedAt} trim />
                        ) : (
                          <Duration minutes={minutes} trim />
                        )}
                      </Tooltip>
                    </FooterDayTotal>
                  );
                })}
                <FooterTotal>
                  <Duration
                    useDecimal
                    minutes={_.sumBy(viewEntries, 'minutes')}
                    timerStartedAt={timerEntry?.timerStartedAt}
                    trim
                  />
                </FooterTotal>
              </>
            )}
          </Week>
          {!isLoading && !hasEntries && (
            <NoResults>
              <NoResultsMessage>
                No time entries available for the week of {moment(week[0]).format('MMMM D')} -{' '}
                {moment(week[6]).format('MMMM D, YYYY')}
              </NoResultsMessage>
              <NoResultsButtons>
                <Button
                  disabled={isTimesheetSubmitted}
                  style={{ position: 'relative' }}
                  onClick={() => setTimeEntryVisible(true)}>
                  Add Time Row
                  {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                </Button>
                {availableCopyRows.length > 0 && (
                  <>
                    {workspace.enableCopyLastWeekTimeEntries ? (
                      <SplitButton>
                        <ActionButton
                          disabled={isTimesheetSubmitted}
                          style={{ position: 'relative' }}
                          onClick={handleCopyRows}>
                          Copy Last Week's Rows
                          {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                        </ActionButton>

                        <SplitButton.Menu data-testid="dropdown_button">
                          {({ setIsOpen }) => (
                            <>
                              <SplitButton.Item
                                disabled={isTimesheetSubmitted}
                                onClick={() => setIsOpen(false) || (() => handleCopyTimeEntries())()}>
                                Copy Last Week's Time Entries
                                {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                              </SplitButton.Item>
                            </>
                          )}
                        </SplitButton.Menu>
                      </SplitButton>
                    ) : (
                      <Button disabled={isTimesheetSubmitted} style={{ position: 'relative' }} onClick={handleCopyRows}>
                        Copy Last Week's Rows
                        {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
                      </Button>
                    )}
                  </>
                )}
                {timesheetContext.useTimesheets && (
                  <>
                    <SubmitTimesheetButton date={date} member={member} onSubmit={fetchData} />
                    <UnsubmitTimesheetButton date={date} member={member} onUnsubmitTimesheet={fetchData} />
                  </>
                )}
                {weekFiles.isEnabled && (
                  <AttachButton
                    tooltip={weekFiles.files.length > 0 ? 'Manage Attachments' : 'Attach Files'}
                    onClick={() => setShowWeekFiles(true)}
                  />
                )}
              </NoResultsButtons>
            </NoResults>
          )}
          {weekFiles.isEnabled && <WeekFilesList />}
          {hasEntries && (
            <CalendarStats
              entries={viewEntries}
              startDate={moment(date).startOf('isoWeek').format('YYYY-MM-DD')}
              endDate={moment(date).endOf('isoWeek').format('YYYY-MM-DD')}
              member={member}
              memberTargets={memberTargets}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
      {timeEntryVisible && (
        <WeekTimeEntry
          memberId={member?.id}
          member={member ?? workspace.member}
          onClose={() => setTimeEntryVisible(false)}
          onComplete={handleAddRow}
        />
      )}
      {toDelete && (
        <WeekTimeEntryDeleteConfirmation
          date={date}
          member={member}
          data={toDelete}
          onClose={() => setToDelete(null)}
          onDelete={handleConfirmDeleteGroup}
        />
      )}
      {weekFiles.isEnabled && showWeekFiles && (
        <WeekFilesDrawer
          date={moment(date).startOf('isoWeek').format('YYYY-MM-DD')}
          member={member}
          onClose={() => setShowWeekFiles(false)}
          onChanged={() => weekFiles.fetchData()}
        />
      )}
    </CalendarPage>
  );
}

export default WeekCalendar;
